import { IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { setSubSearch } from "../../../Redux/reducer/Filter";
import { useNavigate } from "react-router-dom";

const SearchCard = ({ name }: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    dispatch(setSubSearch(name))
    navigate("/subItems")
  }

  return (

    <>

      <div className="border rounded-md hover:shadow-md p-4 w-full flex cursor-pointer items-center"
        onClick={handleClick}
      >
        <div className="w-[10%]">
          <IoIosSearch fontSize={"1.25rem"} className=" text-gray-600" />
        </div>
        <div className="w-[90%]">
          <span className=" block sm:hidden flex-1 whitespace-nowrap overflow-hidden text-ellipsis text-xs sm:text-sm">
            {name.length > 39 ? `${name.slice(0, 39)}...` : name}
          </span>
          <span className=" hidden sm:block flex-1 whitespace-nowrap overflow-hidden text-ellipsis text-xs sm:text-sm">
            {name.length > 45 ? `${name.slice(0, 45)}...` : name}
          </span>

        </div>
      </div>

      {/* <div
        className="w-full sm:w-[400px] h-[60px] flex items-center pl-4 border border-gray-300 gap-4 rounded-lg overflow-hidden cursor-pointer hover:shadow-md transition-shadow duration-300"
        onClick={handleClick}
      >
        <IoIosSearch className="w-[15px] h-[15px] text-gray-600" />
        <span className="flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
          {name}
        </span>
      </div> */}

    </>
  );
};

export default SearchCard;
