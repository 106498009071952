import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";

export const PriceQuery: any = api.injectEndpoints({
	endpoints: (builder) => ({
		Price: builder.mutation<any, string>({
			query: (bodyData) => {
				return {
					url: `${lhwpath}/global/getPrice`,
					method: "POST",
					body: bodyData,
				};
			},
		}),

		searchData: builder.mutation<any, string>({
			query: (searchTerm) => {
				return {
					url: `${lhwpath}/global/searchAssists/${searchTerm}`,
					method: "GET",
				};
			},
		}),

		singleProduct: builder.mutation<any, string>({
			query: (searchTerm) => {
				return {
					url: `${lhwpath}/global/getPriceCalculationData/${searchTerm}`,
					method: "GET",
				};
			},
		}),

		getUnits: builder.query<any, void>({
			query: () => ({
				url: `${lhwpath}/global/units`,
				method: "GET",
			}),
		}),

		createProduct: builder.mutation<any, any>({
			query: (bodyData) => {
				return {
					url: `${lhwpath}/global/product/query/v2`,
					method: "POST",
					body: bodyData,
				};
			},
		}),

		getTopSearches: builder.query<any, any>({
			query: () => ({
			  url: `${lhwpath}/global/search/query`, 
			  method: "GET",
			}),
		  }),
		  
		  createSearchQuery: builder.mutation<any, { query: string }>({
			query: (body) => ({
			  url: `${lhwpath}/global/search/query`, 
			  method: "POST",
			  body: body,
			}),
		  }),
		  
	}),
});

export const {
	usePriceMutation,
	useSearchAllProductsMutation,
	useSearchDataMutation,
	useSingleProductMutation,
	useGetUnitsQuery,
	useCreateProductMutation,
	useGetTopSearchesQuery,
	useCreateSearchQueryMutation
} = PriceQuery;
