import { useNavigate } from "react-router-dom";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import item from "../../../assets/item.png";
import Shield from "../../../assets/Shield.png";
import Verification from "../../../assets/Verification.png";
import Unchecked from "../../../assets/Unchecked.png";

interface IHighLowPrice {
	lowFinalPrice: number;
	highFinalPrice: number;
}
interface AssignedCategoryItemCardProps {
	product: {
		id: string;
		productName: string;
		finalPrice: number;
		rewards: number;
		status: boolean;
		unit: string;
		images: string;
		companyName: string;
		highLowPrice: IHighLowPrice | null;
	};
}

const AssignedCategoryItemCard: React.FC<AssignedCategoryItemCardProps> = ({
	product,
}) => {
	const navigate = useNavigate();

	const handleWhatsappClick = () => {
		const phoneNumber = "919258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};

	const handleProductClick = (id: string | null, name: string | null) => {
		navigate(`/product/${id}/${name}`, { state: { id } });
	};

	return (
		<>
			<div className="hidden sm:flex gap-8 py-3 px-3 w-full rounded-md col-span-2 2xl:col-span-1 shadow-md hover:shadow-lg border transition-shadow duration-300 bg-white hover:bg-gray-50">
				<div className="flex gap-3 sm:gap-8 w-full">
					<div
						 onClick={() => handleProductClick(product?.id, product?.productName)}
						className="cursor-pointer relative w-44 h-44 md:w-48 md:h-48 rounded-md overflow-hidden hover:scale-105 transition-transform duration-300">
						<img
							src={product.images || item}
							alt="item"
							className="w-full h-full object-cover rounded-md"
						/>
						<div className="absolute top-0 left-0 z-20 py-2 px-1 rounded-br-full w-full flex items-center justify-center bg-white gap-2">
							<div className="hidden sm:flex text-green-700 text-xs font-bold">
								{product.companyName.length > 17
									? product.companyName.slice(0, 17) + "..."
									: product.companyName}
							</div>
							<div className="flex sm:hidden text-green-700 text-xs font-bold">
								{product.companyName.length > 12
									? product.companyName.slice(0, 12) + "..."
									: product.companyName}
							</div>
						</div>
						<div className="hidden sm:flex w-[50px] h-[50px] border-[3px] z-10 border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 flex-col items-center justify-center gap-1">
							<img
								src={Shield}
								alt="Shield Icon"
								className="w-3 h-3"
							/>
							<div className="text-[6.5px] text-wrap text-center font-medium text-[#047857]">
								CIPL <br /> Verified
							</div>
						</div>
						{product.status ? (
							<div className="flex sm:hidden items-center absolute bottom-1 right-2">
								<img src={Verification} className="h-7 w-7 z-20" alt="" />
								<div className="ml-[-3px] px-1 py-1 text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
									Active
								</div>
							</div>
						) : (
							<div className="flex sm:hidden items-center absolute bottom-1 right-2">
								<img src={Unchecked} className="h-7 w-7 z-20" alt="" />
								<div className="ml-[-3px] px-1 py-1 text-xs rounded-r-lg border-red-500 bg-red-100 text-red-700">
									InActive
								</div>
							</div>
						)}
					</div>

					<div className="flex w-full justify-between sm:py-3 relative">
						<div className="flex flex-col gap-2">
							<div
								className="font-medium text-sm sm:text-lg leading-5 cursor-pointer 2xl:max-w-xl hover:text-blue-500 transition-colors duration-300"
								onClick={() => handleProductClick(product?.id, product?.productName)}
								>
								{product.productName}
							</div>

							<div className="flex flex-col gap-2">
								<div className="font-medium text-base leading-5 flex items-center gap-2">
									{
										product?.highLowPrice?.lowFinalPrice !== undefined && product?.highLowPrice?.highFinalPrice !== undefined ?
											`₹${Number(product?.highLowPrice.lowFinalPrice).toFixed(2)} - ₹${Number(product?.highLowPrice.highFinalPrice).toFixed(2)}/${product?.unit}` : <span className="font-medium text-[10px] sm:text-[16px]">
												Price Not Available !
											</span>
									}
								</div>

								<div className="text-blue-400 text-xs sm:text-base leading-6 flex items-center gap-2">
									<span>(For Best Rate, Please Contact Us)</span>
									<img
										src={whatsapp}
										className="w-5 h-5 cursor-pointer hover:scale-110 transition-transform duration-300"
										onClick={handleWhatsappClick}
										alt="whatsapp"
									/>
								</div>
							</div>

							{product.rewards ? (
								<div className="flex flex-col items-start sm:flex-row sm:items-center">
									<span className="text-base pb-1 sm:pb-0">
										You will earn{" "}
									</span>
									<div className="flex items-center">
										<img className="mr-2 sm:mx-2" src={icon} alt="icon" />
										<span className="text-base sm:text-lg mr-2">
											{product.rewards} super coins
										</span>
									</div>
								</div>
							) : null}
						</div>

						{product.status ? (
							<div className="hidden sm:flex items-center absolute right-1 top-0">
								<img src={Verification} className="h-7 w-7 z-20" alt="" />
								<div className="ml-[-3px] px-1 py-1 text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
									Active
								</div>
							</div>
						) : (
							<div className="hidden sm:flex items-center absolute right-1 top-0">
								<img src={Unchecked} className="h-7 w-7 z-20" alt="" />
								<div className="ml-[-3px] px-1 py-1 text-xs rounded-r-lg border-red-500 bg-red-100 text-red-700">
									InActive
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* Mobile card */}
			<div  onClick={() => handleProductClick(product?.id, product?.productName)} className="relative flex sm:hidden mb-4 p-2 w-full justify-between overflow-hidden rounded-md bg-white gap-3 shadow hover:shadow-md border border-[#F1F1F1] cursor-pointer">
				{/* Image Container */}
				<div className="shrink-0 h-24 w-24 bg-white border rounded-md overflow-hidden flex items-center justify-center">
					<img
						src={
							Array.isArray(product?.images) && product?.images.length > 0
								? product.images[0]
								: typeof product?.images === "string"
									? product.images
									: ""
						}
						alt="Product Image"
						className="object-cover object-center w-full h-full rounded-md"
					/>
				</div>

				{/* Product Details */}
				<div className="w-full h-24  flex flex-col justify-between font-poppins">
					<div>
						<div className="text-[12px] font-medium leading-normal mb-1 text-wrap">
							{product?.productName?.length > 50
								? `${product.productName.slice(0, 50)}...`
								: product?.productName}
						</div>
						<p className="text-[8px] text-gray-600">Item-Code: 123</p>
						{product.status ? (
							<button className="text-[8px] text-white bg-green-500 px-3 py-0.5 rounded-full hover:bg-green-600">
								In Stock
							</button>
						) : (
							<button className="text-[8px] text-white bg-red-500 px-3 py-0.5 rounded-full hover:bg-red-600">
								Out of Stock
							</button>
						)}

					</div>

					{/* Stock Buttons */}

					<p className="text-[12px] text-[#1AC300] font-bold mt-auto">
						{
							product?.highLowPrice?.lowFinalPrice !== undefined && product?.highLowPrice?.highFinalPrice !== undefined ?
								`₹${Number(product?.highLowPrice.lowFinalPrice).toFixed(2)} - ₹${Number(product?.highLowPrice.highFinalPrice).toFixed(2)}/${product?.unit}` : <span className="font-medium text-[10px] sm:text-[16px]">
									Price Not Available !
								</span>
						}
					</p>


				</div>
			</div>

		</>
	);
};

export default AssignedCategoryItemCard;
