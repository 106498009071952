import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { lhwpath } from '../apiRoutes'
 
export const api = createApi({

    baseQuery: fetchBaseQuery({
        
        baseUrl: lhwpath,  
        prepareHeaders: async (headers) => {
            const token = localStorage.getItem('jwt');

            if (token) {
              // Add the Authorization header with Bearer token if it's available
              headers.set('Authorization', `Bearer ${token}`);
            }
      
            return headers
        },
        credentials: 'include',
    }),
    tagTypes: ['Contractor','Rewards' ,'cart', 'address', 'inventory', 'category','ContractorRequest','NavCount'],
    endpoints: () => ({}),
    reducerPath: 'api',   
})
