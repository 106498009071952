import { ChangeEvent, useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useGetClaimedRewardsMutation } from "../../../Redux/Api/Admin/Reward";
import { MdOutlineStarOutline } from "react-icons/md";
import { exportToExcel } from "../../../utils/exportToExcel";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useDebounce from "../../../common/hooks/useDebounce";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { toast } from "react-toastify";


const formatTimeWithAmPm = (time: string) => {
  const [hour, minute, second] = time.split(":").map(Number);
  const amPm = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minute.toString().padStart(2, "0")}:${second
    .toString()
    .padStart(2, "0")} ${amPm}`;
};
export const ClaimedReward = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState<number | "All">(10);
  const [search, setSearch] = useState("");
  const [adminName, setAdminName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [getClaimedRewards, { data, isLoading }] = useGetClaimedRewardsMutation();
  const debounceSearch = useDebounce(search, 300);
  const debounceAdminName = useDebounce(adminName, 300);

  useEffect(() => {
    getClaimedRewards({
      search: debounceSearch,
      adminName: debounceAdminName,
      page: currentPage,
      limit: limit === "All" ? data?.totalCount : limit,
      startDate,
      endDate,
    });
  }, [debounceSearch, debounceAdminName, currentPage, limit, startDate, endDate]);

  const exportData = () => {
    const filteredData = (data?.data || []).map((item: any, i: number) => ({
      "S.No": i + 1,
      "Name": item.contractorName,
      "Contact": item.phoneNumber,
      "Assign Package": item.packageName,
      "Approve BY": item.adminName,
      "Approve Date": item.updatedAt.split("T")[0],
      "Approve Time": formatTimeWithAmPm(item.updatedAt.split("T")[1].split(".")[0]),
      "Claim Approval": item.claimStatus ? "Claimed" : "Pending",
    }));
    exportToExcel(filteredData, "RewardData");
  };

  const totalItems = data?.totalCount || 0;
  const totalPages = limit === "All" ? 1 : Math.ceil(totalItems / Number(limit));
  const startItemIndex = (currentPage - 1) * (limit === "All" ? totalItems : limit) + 1;
  const endItemIndex = Math.min(currentPage * (limit === "All" ? totalItems : limit), totalItems);

  const options = ["10", "9", "8", "All"];

  const handleSelect = (option: string) => {
    const numericLimit = option === "All" ? "All" : parseInt(option);
    setLimit(numericLimit);
    setCurrentPage(1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!startDate) {
      toast.error(
        "Please select a start date before selecting an end date."
      );
      setEndDate("");
      return;
    }
    setEndDate(e.target.value);
  }; 
   
  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    if (!e.target.value) {
      setEndDate(""); 
    }
  };
  

  const resetFilters = () => {
    setSearch("");
    setAdminName("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <div className="w-full h-auto px-3 mt-3 pb-3">
        <div className="w-full bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
          <div className="w-full px-4 py-2 mt-3">
            <div className="flex flex-col lg:flex-row lg:justify-between gap-4 items-start">
              <div className="flex flex-col gap-3  flex-wrap sm:flex-row sm:gap-4 w-full sm:w-auto">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full sm:w-[200px] py-2 px-3"
                  placeholder="Search.."
                  style={{
                    boxShadow:
                      "0px 0px 7.6px 0px #00000040 inset",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full sm:w-[200px] py-2 px-3"
                  placeholder="admin name.."
                  style={{
                    boxShadow:
                      "0px 0px 7.6px 0px #00000040 inset",
                  }}
                  value={adminName}
                  onChange={(e) =>
                    setAdminName(e.target.value)
                  }
                />
                <input
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl block w-full sm:w-[200px] py-2 px-3"
                  style={{
                    boxShadow:
                      "0px 0px 7.6px 0px #00000040 inset",
                  }}
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <input
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl block w-full sm:w-[200px] py-2 px-3"
                  style={{
                    boxShadow:
                      "0px 0px 7.6px 0px #00000040 inset",
                  }}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 w-full sm:w-auto">
                <button
                  onClick={resetFilters}
                  className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white hover:bg-red-500 duration-150 w-full sm:w-auto">
                  Reset
                </button>
                <button
                  onClick={exportData}
                  className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white hover:bg-green-500 duration-150 w-full sm:w-auto">
                  Excel{" "}
                  <RiFileExcel2Fill className="w-[20px] h-[20px]" />
                </button>
              </div>
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
              <thead className="bg-white divide-y divide-gray-200">
                <tr className="border-t">
                  {["", "Name", "Contact", "Reward Package", "Approve By", "Approve Date & Time", "Status"].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-sm font-inter text-nowrap whitespace-nowrap text-[#1E293B]"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {isLoading ? (
                  <TableLoading colCount={6} />
                ) : data?.data?.length > 0 ? (
                  data?.data.map((reward: any) => (
                    <tr
                      key={reward._id}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap  text-sm text-[#1E293B]">
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap text-sm text-[#1E293B] flex items-center">
                        <img
                          src={reward.image}
                          alt={reward.image}
                          className="w-12 h-12 object-cover rounded-full mr-3"
                        />
                        {reward.contractorName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap  text-sm text-[#1E293B]">
                        {reward.phoneNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap text-sm text-[#1E293B] flex items-center">
                        <img
                          src={reward.packageImage}
                          alt={reward.packageName}
                          className="w-12 h-12 object-cover rounded-full mr-3"
                        />
                        {reward.packageName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap  text-sm text-[#1E293B]">
                        <span className="flex items-center">
                          {reward.adminName}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-nowrap  text-sm text-black">
                        <span className="flex gap-3 items-center">
                          <span>
                            {
                              reward.updatedAt.split(
                                "T"
                              )[0]
                            }
                          </span>
                          <span className="text-gray-600">
                            {
                              reward.updatedAt
                                .split("T")[1]
                                .split(".")[0]
                            }
                          </span>
                        </span>
                      </td>
                       <td className="px-6 py-4 whitespace-nowrap text-nowrap  text-sm text-[#1E293B]">
                        <div className="bg-[#FEF3C7] border  text-[#F59E0B] border-[#F59E0B] px-3 py-2  rounded-xl  text-sm flex items-center gap-1 w-fit">
                          Claimed{" "}
                          <MdOutlineStarOutline
                            fontSize={"1.40rem"}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableNoData
                    colCount={6}
                    text={"  No rewards available."}
                  />
                )}
              </tbody>
            </table>
          </div>

          <div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
            <div className="flex flex-row gap-3">
              <Dropdown
                options={options}
                onSelect={handleSelect}
              />
              <p className="text-[#A6A8B1]">Items per page</p>
              <p className="text-[#666666]">
                Displaying{" "}
                {limit === "All"
                  ? "All"
                  : `${startItemIndex} to ${endItemIndex}`}{" "}
                items
              </p>
            </div>
            <div className="flex flex-row gap-3">
              <p className="text-[#666666]">
                {currentPage} of{" "}
                {limit === "All" ? 1 : `${totalPages}`} pages
              </p>
              <div className="flex flex-row items-center gap-2 text-[#666666]">
                <span
                  onClick={prevPage}
                  className={`cursor-pointer ${currentPage === 1 &&
                    "text-gray-400 cursor-not-allowed"
                    }`}>
                  <FaChevronLeft size={15} />
                </span>
                <span
                  onClick={nextPage}
                  className={`cursor-pointer ${currentPage === totalPages &&
                    "text-gray-400 cursor-not-allowed"
                    }`}>
                  <FaChevronRight size={15} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
