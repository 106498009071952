import { ChangeEvent, useEffect, useRef, useState } from "react";
import logo from "../../assets/logo.svg";
import chawlaLogo from "../../assets/chawlaLogo.png";
import ChawlaIspat from "../../assets/CHAWLA ISPAT.svg";
import IronJunction from "../../assets/THE IRON JUNCTION.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { BiSolidCoinStack } from "react-icons/bi";
import {
  useContractorAvtarMutation,
  useLogoutApiMutation,
} from "../../Redux/Api/Contractor";
import { LoginResponse, SignupWait } from "../../Redux/reducer/LoginReducer";
import { CgProfile } from "react-icons/cg";
import { LiaCoinsSolid, LiaEditSolid } from "react-icons/lia";
import { TbLogout } from "react-icons/tb";
import { MdCancel, MdLogout } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Dropdown from "./Dropdown";
import { useCreateImageURLMutation } from "../../Redux/Api/Admin/Image";
import { toast } from "react-toastify";
import Profile from "../../assets/profile.jpg";
import { AnimatePresence, motion } from "framer-motion";
import DefaultImage from "../../assets/profile/DefualtProfile.png"
import useLogout from "../../hooks/useLogout";
import { FaBell } from "react-icons/fa";
import { LuMenu } from "react-icons/lu";
import MobileNavbar from "./MobileNavbar";
import MobileSideMenu from "./MobileSideMenu";
import { BsFillHandbagFill } from "react-icons/bs";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const [getImageURL, { data, isLoading: isUploading }] = useCreateImageURLMutation();
  const [mutateImage, { isLoading: isUpdating }] = useContractorAvtarMutation();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

  const { handleLogout } = useLogout()

  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const closeSideBar = (data: any) => {
    setIsMenuOpen(data);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menu = document.getElementById("mobile-menu");
      const hamburgerIcon = document.getElementById("hamburger-icon");

      if (
        isMenuOpen &&
        menu &&
        !menu.contains(event.target as Node) &&
        hamburgerIcon &&
        !hamburgerIcon.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    setDropdown(false);
  }, [location.pathname]);

  const closeMenuForSignUp = () => {
    dispatch(SignupWait(false));
    setIsMenuOpen(false);
  };

  const NavigateToProfile = () => {
    navigate("/profile");
    setIsMenuOpen(false);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      closeMenu();
      setShowPopup(true);
    }
  };

  const handleSubmit = async () => {
    if (uploadedFile) {
      const body = new FormData();
      body.append("image", uploadedFile);

      try {
        const response = await getImageURL(body).unwrap();
        mutateImage({ image: response[0] });
        toast.success("Profile image updated successfully!", {
          position: "top-center",
        });
        handleClosePopup();
      } catch (error) {
        toast.error(
          "Failed to update profile image. Please try again later.",
          {
            position: "top-center",
          }
        );
        console.error("Error uploading image:", error);
      }
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    setUploadedFile(null);
    setImagePreview(null);
  };

  const handleEditClick = () => {
    fileInputRef.current?.click();
  };
  return (
    <div className="flex flex-col bg-[#5097A4] sm:px-[20px] sm:py-[10px] sticky top-0 z-40 w-full">
      <div className=" hidden sm:flex lg:flex-row justify-between items-center ">
        <div className="flex items-center gap-3 w-[12%]  sm:w-auto ">
          <div className=" hidden sm:flex sticky top-0 justify-between items-center bg-[#5097A4]">
            <Link to="/" className="">
              <img
                src={logo}
                alt="logo"
                className="h-12 md:hidden "
              />
            </Link>
            <Link to="/">
              <img
                src={chawlaLogo}
                alt="logo"
                className="h-11 hidden md:block"
              />
            </Link>
            <div>
              <Link to="/" className="flex flex-col gap-[1px]">
                <img
                  src={ChawlaIspat}
                  alt="logo"
                  className="hidden md:block w-[120px]"
                />
                <div className="w-full h-[0.03rem] bg-black"></div>
                <img
                  src={IronJunction}
                  alt="logo"
                  className="hidden md:block w-[100px]"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex bg-white">
          <Dropdown />
        </div>

        <div className=" flex gap-2 items-center  w-[88%] sm:w-auto ">
          {/* <div className="mr-2 relative cursor-pointer" onClick={() => navigate("/cart")}>
            <BsFillHandbagFill className="text-white text-3xl" />
            <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
              12
            </span>

          </div> */}
          {userDetails && (
            <div className="hidden sm:flex gap-[10px] justify-center items-center relative ">
              {userDetails?.isVerified && (
                <div
                  className="flex border-[3px] bg-gray-200 h-[35px] rounded-[20px] justify-between items-center gap-2 p-1 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDropdown(!dropdown);
                  }}>
                  <BiSolidCoinStack className="text-[#FF9900]" />
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.totalReward}
                  </span>
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.name
                      .split(" ")[0]
                      .toUpperCase()}
                  </span>
                </div>
              )}
              {userDetails?.isVerified && (
                <div className="ml-[4px] hidden sm:block">
                  <Link to={"/profile"}>
                    {userDetails?.avatar &&
                      userDetails?.avatar.length > 0 ? (
                      <div className="h-[50px] w-[50px] rounded-full overflow-hidden border-2 border-white bg-white ">
                        <img
                          src={userDetails?.avatar || DefaultImage}
                          alt="profile"
                          className="rounded-full h-full w-full object-cover object-center "
                        />
                      </div>
                    ) : (
                      <div className="rounded-full h-[50px] w-[50px] bg-white text-blue-700 font-bold text-[40px] text-center flex items-center justify-center">
                        {userDetails.name[0].toUpperCase()}
                      </div>
                    )}
                  </Link>
                </div>
              )}
              {dropdown && (
                <div
                  id="dropdown"
                  className=" flex flex-col absolute bg-white border rounded-md top-[2.89rem] right-[1rem] overflow-hidden z-50">
                  <div
                    className="px-[12px] py-[8px] cursor-pointer hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() =>
                      navigate("/profile/rewards")
                    }>
                    <LiaCoinsSolid className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Get Rewards
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] cursor-pointer hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={handleLogout}>
                    <TbLogout className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Logout
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] cursor-pointer hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() => navigate("/profile")}>
                    <CgProfile className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Profile
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {userDetails?.name && userDetails?.isVerified ? null : (
            <Link
              to="/signup"
              onClick={() => dispatch(SignupWait(false))}
              className=" hidden sm:block">
              <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-2 md:px-[3rem] px-2 my-2 md:text-sm text-[12px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500">
                SignUp/Signin
              </button>
            </Link>
          )}
        </div>
      </div>

      <MobileNavbar toggleMenu={toggleMenu} />
      {/* mobileMenu */}
      <div className="lg:hidden bg-white">
        <Dropdown />
      </div>

      <MobileSideMenu isOpenMenu={isMenuOpen} setIsOpenMenu={setIsMenuOpen} />

      {/* Image Upload Popup */}
      {showPopup && (
        <AnimatePresence>
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <motion.div
              className=" bg-white rounded-lg text-center py-8 w-[250px] flex justify-center flex-col items-center relative "
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}>
              <span
                onClick={handleClosePopup}
                className="absolute right-2 top-2 bg-black z-50 rounded-full text-white cursor-pointer">
                <MdCancel />
              </span>
              <h2 className="text-lg font-semibold mb-4">
                Image Preview
              </h2>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Uploaded Preview"
                  className="w-32 h-32 border-4 border-[#5097A4] rounded-full mb-4 object-cover object-center"
                />
              )}
              <div className="flex justify-center gap-3">
                <button
                  onClick={handleSubmit}
                  className="bg-[#5097A4] hover:bg-[#5097A4] text-white text-[14px] font-bold py-1 px-3 rounded">
                  {isUploading || isUpdating
                    ? "Saving..."
                    : "Save"}
                </button>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default Header;
