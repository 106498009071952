// src/components/PrivacyPolicy.jsx
import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-50 min-h-screen py-10 px-4 md:px-16">
            <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 md:p-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Privacy Policy</h1>

                <p className="text-gray-600 mb-4">
                    Welcome to Chawla Ispat Private Ltd. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Information We Collect</h2>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>Personal details such as your name, email address, phone number, and address.</li>
                    <li>Usage data, including  browsing behavior on our website.</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">How We Use Your Information</h2>
                <p className="text-gray-600 mb-4">
                    The information we collect is used to:
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>Provide customer support.</li>
                    <li>Improve our website and user experience.</li>
                    <li>Send promotional materials and updates (only with your consent).</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Sharing Your Information</h2>
                <p className="text-gray-600 mb-4">
                    We do not share your personal information with third parties except:
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>To comply with legal obligations.</li>
                    <li>With trusted service providers who assist in our operations.</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Your Rights</h2>
                <p className="text-gray-600 mb-4">
                    You have the right to:
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                    <li>Access, update, or delete your personal data.</li>
                    <li>Opt out of receiving promotional communications.</li>
                    <li>Request information about data usage and storage.</li>
                </ul>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Data Security</h2>
                <p className="text-gray-600 mb-4">
                    We implement appropriate technical and organizational measures to secure your personal information against unauthorized access, disclosure, or loss.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Cookies</h2>
                <p className="text-gray-600 mb-4">
                    Our website uses cookies to enhance your browsing experience. By using our website, you consent to our use of cookies. You can manage your cookie preferences in your browser settings.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Changes to This Privacy Policy</h2>
                <p className="text-gray-600 mb-4">
                    We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date.
                </p>

                <h2 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Contact Us</h2>
                <p className="text-gray-600">
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                </p>
                <p className="text-gray-600 mt-2">
                    Email: <a href="mailto:info@chawlaispat.com" className="text-blue-500">business.simprabh@gmail.com </a>
                </p>
                <p className="text-gray-600">Phone: 080 4604 2405</p>
                <p className="text-gray-600">Address:  Rudrapur, Uttarakhand , India</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
