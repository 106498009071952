import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import MobileProfileImage from "./MobileProfileImage";
import { useChangeEmailAndNameMutation, useContractorChangePasswordMutation } from "../../../Redux/Api/Contractor";
import { toast } from "react-toastify";


interface IEyeVisibility {
  current: boolean;
  new: boolean;
  confirm: boolean;
}
interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const EditProfileDetails = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
  const [mutationData, { isLoading, error }] = useChangeEmailAndNameMutation();
  const [contractorChangePassword, { data, error: passwordError, isLoading: isPasswordLoading }] = useContractorChangePasswordMutation();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [passwordVisibility, setPasswordVisibility] = useState<IEyeVisibility>({
    current: false,
    new: false,
    confirm: false,
  });

  const [passwordData, setPasswordData] = useState<IChangePassword>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 640) {
        navigate("/profile");
      }
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [navigate]);

  const togglePasswordVisibility = (field: "current" | "new" | "confirm") => {
    setPasswordVisibility((prev: IEyeVisibility) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!username.trim() && !email.trim()) {
      toast.warning("Please provide a username or email to update.");
      return;
    }

    if (email.trim() && !validateEmail(email)) {
      toast.warning("Invalid email format.");
      return;
    }

    try {
      const body: { name?: string; email?: string } = {};
      if (username.trim()) body.name = username;
      if (email.trim()) body.email = email;

      await mutationData(body).unwrap();
      toast.success("Profile updated successfully.");
      setUsername("");
      setEmail("");
    } catch (err) {
      toast.error(error?.data?.message || "Failed to update profile.");
    }
  };



  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setPasswordData((prev: IChangePassword) => ({
      ...prev,
      [name]: value,
    }))
  }


  const handlePasswordSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!passwordData.oldPassword || !passwordData.newPassword || !passwordData.confirmPassword) {
      toast.warning("Please provide all password fields.");
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.warning("New password and confirm password do not match.");
      return;
    }

    try {
      await contractorChangePassword({
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      }).unwrap();
      toast.success(data?.data.message || "Password updated successfully.");
      setPasswordData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (err) {
      console.log("error", passwordError);
      toast.error(passwordError?.message || "Failed to update password.");
    }
  }


  return (
    <div
      className="flex flex-col items-center w-full  bg-gradient-to-r from-gray-50 to-gray-100 font-poppins"
    >
      <div className="w-full max-w-xl mx-auto   p-6  ">
        {/* Header */}
        <div className="flex items-center justify-center relative mb-6">
          <Link
            to="/profile"
            className="text-turkishBlue-default absolute left-4 flex items-center hover:text-turkishBlue-dark focus:outline-none focus:ring-2 focus:ring-turkishBlue-default"
            aria-label="Go back">
            <FaArrowLeft size={24} />
          </Link>
          <h1 className="text-xl font-semibold">Edit Profile</h1>
        </div>

        <MobileProfileImage />

        {/* About Info Section */}
        <div className="mb-6">
          <h2 className="text-gray-700 text-lg mb-2 font-bold">
            About Info
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Username Field */}
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-600 mb-1">
                Username
              </label>
              <input
                type="text"
                id="username"
                placeholder={userDetails?.name}
                className="w-full p-2 border font-medium border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-turkishBlue-default  text-slate-900 placeholder:text-slate-700 pl-3"
                value={username}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
              />
            </div>

            {/* Email Field */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border font-medium border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-turkishBlue-default text-slate-900 placeholder:text-slate-700 pl-3"
                placeholder={userDetails?.email}
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </div>
            <button disabled={!(username || email) || isLoading} className="px-6 py-2 w-full text-white bg-turkishBlue-default rounded-lg shadow hover:bg-turkishBlue-dark focus:outline-none focus:ring-2 focus:ring-turkishBlue-default">
              {
                isLoading ? "Updating..." : "Save Changes"
              }
            </button>
          </form>
        </div>

        {/* Password Section */}
        <div className="mb-6">
          <h2 className="text-gray-700 text-lg mb-2 font-bold">
            Change Password
          </h2>
          <form onSubmit={handlePasswordSubmit} className="space-y-4">
            {/* Current Password Field */}
            <div className="relative">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium text-gray-600 mb-1">
                Current Password
              </label>
              <input
                type={
                  passwordVisibility.current
                    ? "text"
                    : "password"
                }
                id="current-password"
                name="oldPassword"
                value={passwordData.oldPassword}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-turkishBlue-default"
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                onClick={() =>
                  togglePasswordVisibility("current")
                }
                className="absolute inset-y-0 top-6 right-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Toggle current password visibility">
                {!passwordVisibility.current ? (
                  <FaEyeSlash />
                ) : (
                  <FaEye />
                )}
              </button>
            </div>

            {/* New Password Field */}
            <div className="relative">
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-gray-600 mb-1">
                New Password
              </label>
              <input
                type={
                  passwordVisibility.new ? "text" : "password"
                }
                id="new-password"
                name="newPassword"
                value={passwordData.newPassword}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-turkishBlue-default"
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("new")}
                className="absolute top-6  inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Toggle new password visibility">
                {!passwordVisibility.new ? (
                  <FaEyeSlash />
                ) : (
                  <FaEye />
                )}
              </button>
            </div>

            {/* Confirm New Password Field */}
            <div className="relative">
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-gray-600 mb-1">
                Confirm New Password
              </label>
              <input
                type={
                  passwordVisibility.confirm
                    ? "text"
                    : "password"
                }
                id="confirm-password"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-turkishBlue-default"
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                onClick={() =>
                  togglePasswordVisibility("confirm")
                }
                className="absolute inset-y-0 top-6 right-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Toggle confirm password visibility">
                {!passwordVisibility.confirm ? (
                  <FaEyeSlash />
                ) : (
                  <FaEye />
                )}
              </button>
            </div>
            <button disabled={isPasswordLoading} className="px-6 py-2 w-full text-white bg-red-500 rounded-lg shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400">
              {
                isPasswordLoading ? "Updating..." : "Change Password"
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfileDetails;
