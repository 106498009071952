import { configureStore } from "@reduxjs/toolkit";
import { api } from "./Api/rtkAPI";
import PopupReducer from "./reducer/PopupReducer";
import Filter from "./reducer/Filter";
import LoginReducer from "./reducer/LoginReducer";
import RequestReducer from "./reducer/RequestReducer";
import adminReducer from "./reducer/adminReducer";
import toStoreValues from "./reducer/toStoreValues";
import LanguaugeReducer from "./reducer/LanguaugeReducer";
import assignedRewards from "./reducer/assignedRewardReducer"
export const server = process.env.VITE_SERVER;

export interface RootState {
  [api.reducerPath]: ReturnType<typeof api.reducer>;
   selection: ReturnType<typeof Filter>; 
  popup: ReturnType<typeof PopupReducer>; 
  Login: ReturnType<typeof LoginReducer>;
  request: ReturnType<typeof RequestReducer>;
  admin: ReturnType<typeof adminReducer>;
  values: ReturnType<typeof toStoreValues>;
  language: ReturnType<typeof LanguaugeReducer>;
  assignedRewards: ReturnType<typeof assignedRewards>
}

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
      selection: Filter,
      popup:PopupReducer,
      Login: LoginReducer,
      request: RequestReducer,
      admin: adminReducer,
      values: toStoreValues,
      language: LanguaugeReducer,
      assignedRewards: assignedRewards
    },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
