import { useEffect, useState } from "react";
import { useGetAdminSingleContractorMessageMutation } from "../../../Redux/Api/Order";
import {useParams } from "react-router-dom";


const ContractorMessage = () => {
  const { id } = useParams<{ id: string }>();

  const [mutation, { data: messageData, isLoading }] =
    useGetAdminSingleContractorMessageMutation();

  useEffect(() => {
    mutation(id);
  }, [mutation, id]);

  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <section className="h-[90vh]">
      {messageData?.data?.length > 0 && (
        <div>
          {messageData.data.map((item: any, index: number) => (
            <div className="my-3 m-auto w-full" key={item.id}>
              <div className="mb-2 rounded-2xl">
                <div
                  onClick={() => handleToggle(index)}
                  className="w-full flex justify-between text-black transition-all duration-500 ease-in-out transform items-center cursor-pointer py-2 px-3 text-lg font-semibold rounded-2xl border border-gray-200 bg-white hover:bg-[#334155] hover:text-white"
                >
                  <div className="h-full w-full flex items-center justify-between">
                    <div className="flex items-center h-full">
                      <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white border">
                        <span className="text-blue-500 text-2xl font-bold">{item?.name?.charAt(0).toUpperCase()}</span>
                      </div>
                      <span className="text-sm font-medium ml-2">{item?.name}</span>
                    </div>

                    <div className="text-gray-500 text-xs font-normal leading-4 py-1 mr-14">
                      <span className="font-semibold">Date: </span>
                      <span>{new Date(item?.date).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <svg
                    className={`w-4 h-4 transition-transform ease-in-out transform ${openIndex === index ? 'rotate-180' : 'rotate-0'}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>

                <div
                  className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto items-center ${openIndex === index ? 'scale-100 opacity-100 max-h-screen' : 'scale-95 opacity-0 max-h-0'}`}
                  style={{ transformOrigin: 'top' }}
                >
                  <div className="p-4 bg-gray-100 w-[95%] rounded-b-xl h-full">
                    <div className="flex items-start gap-2.5 w-full">
                      <div className="flex flex-col gap-1 w-full">
                        <div className="flex flex-col w-full leading-1.5 p-4 border-gray-200 bg-white rounded-e-xl rounded-es-xl dark:bg-gray-700">
                          <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
                            <span className="text-sm font-semibold text-gray-900 dark:text-white">
                              <span className="font-semibold">Name: </span>
                              <span>{item.name}</span>
                            </span>
                          </div>
                          <div className="text-sm">
                            Message:
                            <div className="px-3.5 py-2 bg-gray-100 rounded justify-start items-center gap-3 inline-flex w-fit max-w-full">
                              <h5 className="text-gray-900 text-sm font-normal leading-snug break-words">
                                {item?.message}
                              </h5>
                            </div>
                          </div>

                          <div className="justify-end items-start mt-1 mb-2.5 flex flex-col">
                            <h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
                              <span className="font-semibold">Date: </span>
                              <span>{new Date(item?.date).toLocaleDateString()}</span>
                            </h6>
                            <h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
                              <span className="font-semibold">Time: </span>
                              <span>{new Date(item?.date).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {isLoading && <span className="p-3">Loading..</span>}
          {!isLoading && messageData?.data.length === 0 && <span className="p-3">No data available.</span>}
        </div>
      )}
    </section>
  );
};

export default ContractorMessage;
