import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";
import { SaveFCMPayload, SaveFCMResponse, SendNotificationPayload, SendNotificationResponse } from "./types";

export const notificationQuery = api.injectEndpoints({
	endpoints: (builder) => ({
		saveFcmToken: builder.mutation<SaveFCMResponse, SaveFCMPayload>({
			query: (payload: SaveFCMPayload) => {
				return {
					url: `${lhwpath}/global/notifications/save-fcm-token`,
					method: "POST",
					credentials: "include",
					body: payload,
				};
			},
		}),
		sendNotification: builder.mutation<SendNotificationResponse, SendNotificationPayload>({
			query: (payload: SendNotificationPayload) => {
				return {
					url: `${lhwpath}/global/notifications/send-notifications`,
					method: "POST",
					credentials: "include",
					body: payload,
				};
			},
		}),
		// removeExpiredTokens: builder.mutation<RemoveExpiredTokensResponse, void>({
		// 	query: () => ({
		// 	  url: `${lhwpath}/global/notifications/remove-expired-tokens`,
		// 	  method: "POST",
		// 	  credentials: "include",
		// 	}),
		//   }),
		  allFCMTokens: builder.query<any, void>({
			query: () => ({
			  url: `${lhwpath}/global/notifications/fcm-tokens/users`,
			  method: "GET",
			  credentials: "include",
			}),
		  })
		  
	}),
});

export const { useSaveFcmTokenMutation, useSendNotificationMutation ,useAllFCMTokensQuery} = notificationQuery;
