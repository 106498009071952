import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";

interface Props {
    user: string | null | undefined,
    setIsMenu: (val: boolean) => void
}

const AuthButton = ({ user, setIsMenu }: Props) => {
    const navigate = useNavigate()

    const { handleLogout } = useLogout()
    return (
        <div className="p-4">
            {user ? (
                <button
                    onClick={() => {
                        handleLogout()
                        setIsMenu(false)
                    }}
                    className="w-full py-2 text-white bg-red-500 hover:bg-red-600 border-[5px] border-white shadow-md rounded-md"
                >
                    Logout
                </button>
            ) : (

                <button
                    onClick={() => {
                        navigate("/signup")
                        setIsMenu(false)
                    }}
                    className="w-full py-2 text-white bg-green-500 hover:bg-green-600 border-[5px] border-white shadow-md rounded-md"
                >
                    Login
                </button>
            )}
        </div>
    );
};

export default AuthButton;
