import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { RiImageAddLine } from "react-icons/ri";
import { useCreateRewardMutation } from "../../../../Redux/Api/Admin/Reward";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

type FormValues = {
  name: string;
  rewardA: string;
  rewardB: string;
  rewardC: string;
  status: boolean;
  image: File | null;
};

export const Coupons = ({ onClose }: any) => {
  const [createReward, { isLoading }] = useCreateRewardMutation();
  const [couponData, setCouponData] = useState<FormValues>({
    name: "",
    rewardA: "",
    rewardB: "",
    rewardC: "",
    status: true,
    image: null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = name.startsWith("reward") && value !== "" ? Math.max(0, Number(value)).toString() : value;

    setCouponData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", couponData.name);
      formData.append("rewardA", couponData.rewardA);
      formData.append("rewardB", couponData.rewardB);
      formData.append("rewardC", couponData.rewardC);
      formData.append("status", couponData.status.toString());
      if (couponData.image) {
        formData.append("image", couponData.image);
      }

      const response = await createReward(formData).unwrap();
      if (response.success) {
        toast.success("Reward created successfully!");
        onClose();
      }
    } catch (error) {
      toast.error("Error creating reward.");
    }
  };

  return (
    <motion.div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="w-[547px] flex flex-col gap-[15px] border rounded-[8px] bg-white shadow-sm max-h-[90%] px-[16px] overflow-y-auto"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between px-[10px] py-[16px] text-[#334155] text-2xl">
          <span className="text-2xl font-bold">Add Reward Points</span>
          <RxCross2
            className="w-[30px] h-[30px] cursor-pointer"
            onClick={onClose}
          />
        </div>

        <div className="gap-[6px] px-[10px] flex flex-col">
          <div className="flex flex-col gap-[26px]">
            <div className="w-full bg-white py-[6px] border-[3px] border-[#334155] border-dashed rounded-md flex flex-col justify-center items-center transition ">
              <div className="flex flex-col gap-[10px] items-center justify-center text-center py-2">
                <RiImageAddLine fontSize={"3rem"} className="text-[#334155]" />
                <div className="font-medium text-[16px] text-black">Upload Your Files</div>
                <div
                  className="text-[#334155] font-medium text-[16px] underline cursor-pointer hover:text-blue-500 transition"
                  onClick={triggerFileInput}
                >
                  Browse
                </div>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              {couponData.image && (
                <div className="mt-2 mb-2 text-center">
                  <p className="text-gray-600 font-medium text-[14px] break-words">
                    Selected File: {couponData.image.name}
                  </p>
                  <p className="text-gray-500 text-[12px]">
                    Size: {(couponData.image.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="mt-2">
            <input
              name="name"
              type="text"
              value={couponData.name}
              onChange={handleChange}
              className="w-full h-12 px-4 border-2 border-[#334155] rounded-md outline-none  transition"
              placeholder="Enter Coupon Name..."
            />
          </div>

          {["A", "B", "C"].map((grade) => (
            <div className="relative w-full mb-4 mt-3" key={grade}>
              <label className="absolute -top-2 left-4 px-1 bg-white text-[#334155] text-xs">
                Grade "{grade}"
              </label>
              <input
                name={`reward${grade}`}
                type="number"
                value={(couponData as any)[`reward${grade}`]}
                onChange={handleChange}
                className="w-full h-12 px-4 border-2 border-[#334155] rounded-md outline-none  transition"
              />
            </div>
          ))}
        </div>

        <div className="pb-3 px-[10px] mt-[-13px] flex justify-center">
          <button
            className={`bg-[#334155] hover:bg-[#283443] text-white py-2 px-5 rounded-lg flex items-center justify-center shadow-md transition duration-200 focus:outline-none focus:ring-2  ${isLoading ? "cursor-not-allowed opacity-75" : ""
              }`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};


// import React, { useState } from "react";
// import { RxCross2 } from "react-icons/rx";
// import { useCreateRewardMutation } from "../../../../Redux/Api/Admin/Reward";
// import addImage from "../../../../assets/Add.png";
// import { toast } from "react-toastify";
// import { RiImageAddLine } from "react-icons/ri";

// type FormValues = {
//   name: string;
//   rewardA: string;
//   rewardB: string;
//   rewardC: string;
//   status: boolean;
//   image: File | null;
// };

// export const Coupons = ({ onClose }: any) => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [createReward] = useCreateRewardMutation();
//   const [couponData, setCouponData] = useState<FormValues>({
//     name: "",
//     rewardA: "",
//     rewardB: "",
//     rewardC: "",
//     status: true,
//     image: null,
//   });

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     const newValue = name.startsWith("reward") && value !== "" ? Math.max(0, Number(value)).toString() : value;

//     setCouponData((prevState) => ({
//       ...prevState,
//       [name]: newValue,
//     }));
//   };

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     setCouponData((prevState) => ({
//       ...prevState,
//       image: file,
//     }));
//   };

//   const triggerFileInput = () => {
//     const fileInput = document.getElementById("image") as HTMLInputElement;
//     fileInput?.click();
//   };

//   const handleSubmit = async () => {
//     try {
//       const formData = new FormData();
//       formData.append("name", couponData.name);
//       formData.append("rewardA", couponData.rewardA);
//       formData.append("rewardB", couponData.rewardB);
//       formData.append("rewardC", couponData.rewardC);
//       formData.append("status", couponData.status.toString());
//       if (couponData.image) {
//         formData.append("image", couponData.image);
//       }

//       const response = await createReward(formData).unwrap();
//       if (response.success) {
//         toast.success("Reward created successfully!", { position: "top-center" });
//       }
//       onClose();
//     } catch (error) {
//       toast.error("Error creating reward:", { position: "top-center" });
//     }
//   };

//   return (
//     <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
//       <div className="w-[547px] flex flex-col gap-[15px] border rounded-[8px] bg-white shadow-sm max-h-[90%] px-[16px] overflow-y-auto">
//         <div className="flex justify-between px-[10px] py-[16px] text-[#334155] text-2xl mt-2">
//           <span className="text-2xl font-bold">Add Reward Points</span>
//           <RxCross2
//             className="w-[30px] h-[30px] cursor-pointer"
//             onClick={onClose}
//           />
//         </div>
//         <div className="gap-[6px] px-[10px] flex flex-col">
//           <div className="flex flex-col gap-[26px]">
//             <div className="w-full bg-white py-[6px] border-[3px] border-[#334155] border-dashed rounded-[12px] flex flex-col justify-center items-center">
//               <div className="flex flex-col gap-[10px] items-center justify-center text-center py-2">
//                 <div>
//                   <RiImageAddLine fontSize={"3rem"} />
//                 </div>
//                 <div className="font-[500] text-[16px] leading-[7px] text-black">
//                   Upload Your Files
//                 </div>
//                 <div
//                   className="text-[#334155] font-[400] text-[16px] leading-[24px] underline cursor-pointer"
//                   onClick={triggerFileInput}
//                 >
//                   Browse
//                 </div>
//                 <input
//                   type="file"
//                   id="image"
//                   accept="image/*"
//                   onChange={handleFileChange}
//                   className="hidden"
//                 />
//               </div>
//               {couponData.image && (
//                 <div className="mt-2 mb-2 text-center">
//                   <p className="text-gray-600 font-[500] text-[14px] leading-[24px] break-words text-wrap">
//                     Selected File: {couponData.image.name}
//                   </p>
//                   <p className="text-gray-500 text-[12px]">
//                     Size: {(couponData.image.size / 1024).toFixed(2)} KB
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="mt-2">
//             <input
//               name="name"
//               type="text"
//               value={couponData.name}
//               onChange={handleChange}
//               className="w-full h-12 px-4 border-2 border-[#334155] rounded-xl outline-none focus:border-gray-400"
//               placeholder="Enter Coupon name.."
//             />
//           </div>
//           <div className="relative w-full mb-4 mt-3">
//             <label className="absolute -top-2 left-4 px-1 bg-white text-[#334155] text-xs">
//               Grade "A"
//             </label>
//             <input
//               name="rewardA"
//               type="number"
//               value={couponData.rewardA}
//               onChange={handleChange}
//               className="w-full h-12 px-4 border-2 border-[#334155] rounded-xl outline-none focus:border-gray-400"
//             />
//           </div>
//           <div className="relative w-full mb-4">
//             <label className="absolute -top-2 left-4 px-1 bg-white text-[#334155] text-xs">
//               Grade "B"
//             </label>
//             <input
//               name="rewardB"
//               type="number"
//               value={couponData.rewardB}
//               onChange={handleChange}
//               className="w-full h-12 px-4 border-2 border-[#334155] rounded-xl outline-none focus:border-gray-400"
//             />
//           </div>
//           <div className="relative w-full mb-4">
//             <label className="absolute -top-2 left-4 px-1 bg-white text-[#334155] text-xs">
//               Grade "C"
//             </label>
//             <input
//               name="rewardC"
//               type="number"
//               value={couponData.rewardC}
//               onChange={handleChange}
//               className="w-full h-12 px-4 border-2 border-[#334155] rounded-xl outline-none focus:border-gray-400"
//             />
//           </div>
//         </div>
//         <div className="pb-3 mt-[-13px] px-[10px]">
//           <button
//             className="bg-blue-500 text-white px-6 py-[8px] border rounded-xl flex justify-center items-center cursor-pointer ml-auto"
//             onClick={handleSubmit}
//           >
//             Save
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
