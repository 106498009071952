import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function Pagination({ total, current, onPageChange }: any) {
    const pages = Array.from({ length: total }, (_, index) => index + 1);

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= total) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center items-center gap-4 sm:w-[22.9rem] m-auto h-[3rem]">
            {/* Previous Button */}
            <button
                className="w-fit h-[1rem] flex justify-center items-center font-bold text-sm text-[#64748B] hover:text-black"
                disabled={current === 1}
                onClick={() => handlePageChange(current - 1)}
            >
                <FaAngleLeft className="h-5 w-5 mr-1 text-black" />
                <span className="hidden sm:block">Previous</span>
            </button>

            {/* Page Numbers */}
            {pages?.map((pageNumber) => {
                if (
                    pageNumber === 1 ||
                    pageNumber === total ||
                    pageNumber === current ||
                    pageNumber === current - 1 ||
                    pageNumber === current + 1
                ) {
                    return (
                        <button
                            key={pageNumber}
                            className={`px-2 py-1 rounded-md ${
                                pageNumber === current
                                    ? "bg-[#5097A4] text-white"
                                    : "bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-700"
                            }`}
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    );
                } else if (pageNumber === 2 || pageNumber === total - 1) {
                    return <span key={pageNumber} className="px-2 py-1">...</span>;
                }
                return null;
            })}

            {/* Next Button */}
            <button
                className="w-fit h-[1rem] flex justify-center items-center font-bold text-sm text-[#64748B] hover:text-black"
                disabled={current === total}
                onClick={() => handlePageChange(current + 1)}
            >
                <span className="hidden sm:block">Next</span>
                <FaAngleRight className="h-5 w-5 ml-1" />
            </button>
        </div>
    );
}

export default Pagination;
