import { useEffect, useState } from "react";
import Imagesection from "./Components/Imagesection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopSelling from "../../common/TopSelling";
import { useSingleProductMutation } from "../../Redux/Api/priceAPI";

import { lhwpath } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import Shield from "../../assets/Shield.png";
import ProductDescription from "./Components/ProductDescription";
import Verification from "../../assets/Verification.png";
import Unchecked from "../../assets/Unchecked.png";
import SingleProductExplore from "../Components/SingleProductExplore";

// import defaultImage from "../../assets/defaultImage.svg";
import defaultImage from "../../assets/defaultImage.svg";
import { FaArrowLeft } from "react-icons/fa";
import QuotationForm from "./Components/QuotationForm";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import RingLoader from "react-spinners/RingLoader";

import { url } from "inspector";
import ProductInfoTop from "./mobileComponents/ProductInfoTop";
import ProductImageSlider from "./mobileComponents/ProductImageSlider";
import DetailsSection from "./mobileComponents/DetailsSection";
import MobileProductDescription from "./mobileComponents/MobileProductDescription";
import TopSellingMobile from "./mobileComponents/TopSellingMobile";
const SingleProduct = () => {
  // const [index, setIndex] = useState<number>(2);
  const [index, setIndex] = useState<number[]>([])
  const location = useLocation();
  // const productId = location?.state?.id;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [singleProductMutation, { data: singleProduct, isLoading }] = useSingleProductMutation();

  useEffect(() => {
    if (id) {
      singleProductMutation(id);
    }
  }, [id]);

  // const handleWhatsappClick = () => {
  //   const phoneNumber = "919258294692";
  //   const url = `https://wa.me/${phoneNumber}`;
  //   window.open(url, "_blank");
  // };

  const handleWhatsappClick = () => {
    if (!singleProduct) return;
   
    const phoneNumber = "919258294692";
    const productName = singleProduct.productName || "Product Name Not Available";
    const lowPrice = singleProduct.highLowPrice?.lowFinalPrice || "Price Not Available";
    const highPrice = singleProduct.highLowPrice?.highFinalPrice || "Price Not Available";
    const unit = singleProduct.unit || "unit";
    const productImage = singleProduct.productImage?.[0] || defaultImage;
    const currentPageURL = window.location.href;

    const message = encodeURIComponent(
      `Check out this product on Chawla Ispat: 
    - Name: ${productName}
    - Price Range: ₹${Number(lowPrice).toFixed(2)} - ₹${Number(highPrice).toFixed(2)} / ${unit}
    - View Image: ${productImage}
  
    View Product: ${currentPageURL}
  
    Click to chat with us for more details!`
    );

    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, "_blank");
  };





  useEffect(() => {
    document.title = "SingleProduct Page - Chawla Ispat";
  }, []);

  const title = `${singleProduct?.productName || ""
    } Product Overview | Building Material Supplier | Chawla Ispat`;
  const description =
    "Explore the details of this single product on Chawla Ispat.";
  const canonicalUrl = lhwpath;

  usePageMeta(title, description, canonicalUrl);

  const handleBackNavigation = () => {
    navigate("/items", { state: { fromSingleProduct: true } });
  };

  const openModal = (): void => {
    setIsOpenModal((prev) => !prev);
  };

  const closeModal = (): void => {
    setIsOpenModal((prev) => !prev);
  };

  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const handleClick = () => {
    if (userDetails) {
      openModal();
    } else {
      navigate("/signup");
    }
  };

  const isShareButton = singleProduct?.highLowPrice?.lowFinalPrice !== undefined && singleProduct?.highLowPrice?.highFinalPrice !== undefined
  return (
    <>
      <QuotationForm
        isOpen={isOpenModal}
        onClose={closeModal}
        productId={id}
        number={userDetails?.phoneNumber}
        unitId={singleProduct?.unitId}
      />

      {/* Desktop screen */}
      <div className="hidden sm:block">
        {/* <div className=""> */}
        <div className="">

          {isLoading ? (
            <div className="flex justify-center h-[50vh] items-center ">
              <RingLoader color="#5097A4" size={150} />
            </div>
          ) : (
            <>
              <div className="w-full 2xl:container mx-auto relative h-auto overflow-hidden">
                <button
                  className=" hidden  bg-[#5097A4] absolute left-3 top-3 py-2 px-3 rounded-full h-10 w-10 z-40 lg:flex justify-center items-center text-white  "
                  onClick={handleBackNavigation}>
                  <FaArrowLeft />
                </button>
                <div className="flex flex-col lg:flex-row w-full z-30 min-h-[500px]  ">
                  <div className="w-full  lg:w-[40%] relative bg-white flex flex-col  lg:flex-row lg:items-center justify-center">
                    <div className="absolute border-[15px]  h-[400px] w-[400px]  xl:h-[500px] xl:w-[500px] border-[#FF9900] hidden  lg:block    lg:top-[-90px] lg:left-[-90px] rounded-full"></div>
                    <div className="absolute border-[10px] h-[400px] w-[400px] xl:h-[500px] xl:w-[500px] border-[#FF9900]  hidden   lg:block   lg:top-[-140px] lg:left-[-140px] rounded-full"></div>
                    <button
                      className=" flex mt-2 ml-5  bg-[#5097A4]  py-2 px-3 rounded-full h-10 w-10 z-40 lg:hidden justify-center items-center text-white  "
                      onClick={handleBackNavigation}>
                      <FaArrowLeft />
                    </button>
                    <div className="w-full h-[350px] lg:w-[360px] lg:h-[360px] p-5 rounded-2xl lg:p-0   relative bg-white lg:rounded-[37px] top-0 left-0 xl:top-6 xl: xl:left-7 z-30 lg:shadow-[rgba(14,30,37,0.12)_0px_2px_4px,rgba(14,30,37,0.32)_0px_2px_16px] ">
                      <img
                        className="w-full h-full rounded-xl  lg:rounded-[37px] overflow-hidden object-cover"
                        src={
                          (singleProduct &&
                            singleProduct
                              ?.productImage[0]) ||
                          defaultImage
                        }
                        alt=""
                      />

                      {singleProduct &&
                        singleProduct?.productImage[0] && (
                          <div className="w-[70px] h-[70px] border-[4px]  z-50 border-[#24FF00] absolute top-[-20px] left-[-40px] lg:top-12 lg:left-[-40px] rounded-full bg-white lg:bg-green-100 hidden lg:flex flex-col items-center justify-center gap-1">
                            <img
                              src={Shield}
                              alt="Shield Icon"
                              className="w-4 h-4"
                            />
                            <div className="text-[10px] text-wrap text-center font-medium text-[#047857]">
                              Lohawalla Assured
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="w-full lg:w-[60%] px-5   xl:pr-10 mt-10 lg:mt-20 ">
                    <div className="w-full flex justify-end">
                      {singleProduct?.status ? (
                        <div className="flex items-center mb-1">
                          <img
                            src={Verification}
                            className="h-7 w-7 z-20"
                            alt=""
                          />{" "}
                          <div className=" ml-[-2px] px-1 py-[2px] text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
                            {" "}
                            Available
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center mb-1">
                          <img
                            src={Unchecked}
                            className="h-7 w-7 z-20"
                            alt=""
                          />{" "}
                          <div className=" ml-[-2px] px-1 py-[2px] text-xs rounded-r-lg border-[#931515] bg-red-100 text-red-700">
                            {" "}
                            Unvailable
                          </div>
                        </div>
                      )}
                    </div>
                    <h1 className="text-3xl font-bold text-gray-800 mt-1">
                      {singleProduct?.productName}
                    </h1>
                    <p className="text-gray-600 mt-4 text-justify">
                      I{" "}
                      {
                        singleProduct?.companyProductDescription
                      }
                    </p>
                    <span className="text-sm font-medium my-1">Item Code - <span className="text-gray-600">{singleProduct?.itemCode}</span></span>
                    <div className=" flex flex-col sm:flex-row sm:justify-end w-full mt-3 xl:mt-2 gap-2">
                      <button
                        onClick={handleClick}
                        className="border-[2px] w-full sm:w-auto border-[#FEBD69] p-3 my-2 sm:my-0 sm:py-1 sm:px-2 rounded-md font-medium text-lg cursor-pointer hover:text-white hover:bg-[#FEBD69]">
                        Get Latest Price
                      </button>
                    </div>
                    <div className=" w-full sm:min-w-[150px] sm:w-fit flex flex-col border-[2px] text-[#31B42E]  border-[#3FD13D] my-3 sm:my-0 p-3 sm:py-1 sm:px-2 rounded-md bg-[#33FF0033]  hover:text-black hover:bg-[#3FD13D]">
                      <span className="text-lg sm:text-[16px] font-bold text-center">
                        {/* {singleProduct?.finalPrice !== undefined && singleProduct?.finalPrice !== null
                          ? `₹${singleProduct?.finalPrice?.toFixed(2)} / ${singleProduct?.priceStructureUnit}`
                          : "Login to view the price"} */}

                        {
                          singleProduct?.highLowPrice?.lowFinalPrice !== undefined && singleProduct?.highLowPrice?.highFinalPrice !== undefined ?
                            `₹${Number(singleProduct?.highLowPrice.lowFinalPrice).toFixed(2)} - ₹${Number(singleProduct?.highLowPrice.highFinalPrice).toFixed(2)}/${singleProduct?.unit}` : <span className="font-medium text-[10px] sm:text-[16px]">
                              Price Not Available !
                            </span>
                        }
                      </span>
                    </div>
                    <Imagesection
                      singleProduct={singleProduct}
                    />
                  </div>
                </div>
              </div>
              <ProductDescription
                // index={index}
                singleProduct={singleProduct}
              // setIndex={setIndex}
              />
            </>
          )}

          <SingleProductExplore />
        </div>
        <div className="w-full 2xl:container mx-auto ">
          <div className="flex flex-col gap-10 mt-12 mb-12 ">
            <div className="w-11/12 m-auto font-[600] sm:text-[35px] text-[25px] leading-[32px]">
              Best selling Items
            </div>

            <div>
              <TopSelling />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Screen */}
      <div className="w-full sm:hidden font-poppins mb-6 ">
        <ProductInfoTop productName={singleProduct?.productName} isLoading={isLoading}  unit={singleProduct?.unit} handleBack={handleBackNavigation} />
        <ProductImageSlider isButton={isShareButton} productImages={singleProduct?.productImage} isLoading={isLoading} handleShare={handleWhatsappClick} />
        <DetailsSection
          isLoading={isLoading}
          highLowPrice={singleProduct?.highLowPrice}
          description={singleProduct?.companyProductDescription}
          unit={singleProduct?.unit}
          name={singleProduct?.productName}
          itemCode={singleProduct?.itemCode}
          companyName={singleProduct?.companyName}
          companyId={singleProduct?.companyId}
          handleClick={handleClick}
        />
        <MobileProductDescription product={singleProduct} isLoading={isLoading} />
        <TopSellingMobile />
      </div>
      <div className="hidden sticky bottom-0 shadow  justify-between items-center border-t z-30 bg-white sm:hidden transition-all duration-300 ease-in-out gap-2 px-2 py-2">
        {isLoading ? (
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col w-full text-start">
              <div className="w-24 h-4 bg-gray-200 rounded-md mb-2 animate-pulse"></div>
              <div className="w-36 h-6 bg-gray-300 rounded-md animate-pulse"></div>
            </div>
            <div className="flex flex-col justify-center w-fit text-center">
              <div className="w-24 h-10 bg-gray-300 rounded-full animate-pulse"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center w-full text-start">
              <p className="text-[15px] font-medium text-gray-600">Price Range</p>
              <span className="text-[19px] font-bold text-[#1CD100]">
                ₹{parseFloat(singleProduct?.highLowPrice?.lowFinalPrice).toFixed(2)} - ₹{parseFloat(singleProduct?.highLowPrice?.highFinalPrice).toFixed(2)}/{singleProduct?.unit || "unit"}
              </span>
            </div>
            <div className="flex flex-col justify-center w-fit text-center">
              <button className="px-4 py-2 bg-[#FFA41C] font-medium rounded-full text-[16px] transition duration-200">
                Purchase
              </button>
            </div>
          </>
        )}
      </div>

    </>
  );
};

export default SingleProduct;
