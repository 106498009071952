import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { RootState } from "../../Redux/store";
import { Category } from "./types";
import defaultImg from "../../assets/assets/dummyProduct.png"
import { toast } from "react-toastify";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";


const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div
            className="absolute top-1/2 -translate-y-1/2 left-[-50px] z-10 cursor-pointer"
            onClick={onClick}
        >
            <div className="w-12 h-12 flex items-center justify-center bg-gradient-to-br from-[#E2E8F0] to-[#FFFFFF] rounded-full ">
                <MdKeyboardArrowLeft className="w-6 h-6" />
            </div>
        </div>
    );
};

const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div
            className="absolute top-1/2 -translate-y-1/2 right-[-50px] z-10 cursor-pointer"
            onClick={onClick}
        >
            <div className="w-12 h-12 flex items-center justify-center bg-gradient-to-br from-[#E2E8F0] to-[#FFFFFF] rounded-full ">
                <MdKeyboardArrowRight className="w-6 h-6" />
            </div>
        </div>
    );
};

const HomeAllCategories = () => {
    const categories = useSelector((state: RootState) => state.values.allCategories);
    const navigate = useNavigate();
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const handleItemClick = (_id: string | null, name: string | null) => {
        if (_id)
            navigate(`/items/${_id}/category/${name}`, { state: { id: _id } });
        else toast.warning("Something went wrong! Please try again.");
    };

    return (
        <div className="w-full lg:w-[90%] max-w-[1540px] mx-auto font-inter ">
            <div className="px-16">
                <div className="flex items-center justify-between px-2">
                    <div className="text-3xl font-bold">All Categories</div>
                    <Link to="/company" className="text-lg font-bold text-blue-500 underline">View more</Link>
                </div>
                <Slider {...settings} className="p-2 mt-4">
                    {categories.length > 0 ? (
                        categories?.map((category: Category, index: number) => (
                            <div key={index} className="px-2">
                                <div className="border-[5px] border-[#E5EAF16E] rounded-3xl ring-[5px] ring-white mt-2 mx-1 bg-white z-30 overflow-hidden">
                                    <div className="h-[191px] w-full p-2">
                                        <img
                                            src={category?.imageCarouselDetails?.[0]?.image || defaultImg}
                                            alt="" className="w-full h-full object-contain" />
                                    </div>
                                    <div className="p-6">
                                        <h3 className="text-lg font-bold truncate">{category?.name}</h3>
                                        <div className="flex items-center justify-between">
                                            <span className="mt-2 truncate text-sm  text-gray-400">Item : {category?.itemCount}</span>
                                            <button
                                                onClick={() =>
                                                    handleItemClick(category?.id, category?.name)
                                                }
                                                className="text-sm font-medium text-[#007185]">View more</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        Array.from({ length: 3 }).map((_, index) => (
                            <div key={index} className="px-2">
                                <div className="border-[5px] border-[#E5EAF16E] rounded-3xl ring-[5px] ring-white mt-2 mx-1 bg-white z-30 overflow-hidden">
                                    <div className="h-[191px] w-full bg-gray-200 animate-pulse"></div>
                                    <div className="p-6">
                                        <div className="h-6 bg-gray-200 animate-pulse mb-2"></div>
                                        <div className="h-4 bg-gray-200 animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </Slider>
            </div>
        </div>
    );
};

export default HomeAllCategories;
