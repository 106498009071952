import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import { useDeleteUserMutation } from '../../../Redux/Api/Contractor';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { BiErrorCircle } from 'react-icons/bi';
import useLogout from '../../../hooks/useLogout';

const DeleteAccountButton = () => {
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const [deleteUser, { isLoading, error }] = useDeleteUserMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const {handleLogout} = useLogout();
    
    const handleDelete = async () => {
        if (!password) {
            toast.error('Password is required!');
            return;
        }
        try {
            if (userDetails?._id) {
                await deleteUser({ _id: userDetails?._id, password }).unwrap();
                toast.success('Account deleted successfully!');
                handleLogout(); 
                setIsModalOpen(false);
            }
        } catch (err) {
            toast.error(error?.data?.message || 'Failed to delete account');
        }
    };

    return (
        <div className="flex my-4 justify-center sm:justify-start sm:px-10">
            <button
                onClick={() => setIsModalOpen(true)}
                className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600 transition duration-300"
            >
                Delete Account
            </button>
            {isModalOpen && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
                >
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <div className="flex justify-center text-red-600 text-4xl mb-4">
                            <BiErrorCircle />
                        </div>
                        <h2 className="text-xl font-bold mb-4 text-center">Confirm Deletion</h2>
                        <p className="mb-4 text-center text-red-600">Warning: Deleting your account will result in the loss of all your data.</p>
                        <p className="mb-4 text-center">Please enter your password to confirm account deletion:</p>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border p-2 mb-4 w-full rounded-md"
                            placeholder='Enter Your Password'
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="bg-gray-300 text-black py-2 px-4 rounded-full mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600 transition duration-300"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default DeleteAccountButton;