import { ProductQuery } from "../profileTyps";
import { formatDateTime } from "../../../../utils/formatDateTime";
import whatshapPng from "../../../../assets/profile/quotationTrackerImages/Whatsapp.png";
import checkedPng from "../../../../assets/profile/quotationTrackerImages/Checked.png";
import waitPng from "../../../../assets/profile/quotationTrackerImages/wait.png";
type DataProps = {
    item: ProductQuery;
    getStatusImages: (value: string) => string;
    handleModalOpen: (price: number | null, validity: string | null) => void;
};

const QuotationTrackerData = ({ item, getStatusImages, handleModalOpen, }: DataProps) => {
    return (
        <div className=" font-poppins bg-gray-50 sm:bg-white sm:font-inter w-full h-auto rounded-md  shadow-sm sm;border mb-5 p-2 sm:border  sm:p-4 flex flex-col gap-3 sm:py-5 mt-2">
            <div className="flex flex-row w-full items-start  sm:justify-between">
                <h2 className=" hidden sm:block text-sm sm:text-2xl font-bold mb-2 sm:mb-0">
                    Query Details
                </h2>
                <div className=" border sm:border-none p-1 px-3 sm:p-0 rounded-full bg-white  text-[10px] sm:text-xs flex justify-between w-full sm:w-fit   sm:justify-center sm:flex-col items-center gap-1">
                    <span className="text-[#7B7B7B]">
                        {" "}
                        <span className=" font-bold text-[#141414]">
                            Sales Person Name :
                        </span>{" "}
                        {item?.salesPersonId?.name
                            ? item?.salesPersonId?.name
                            : "Not issue yet"}
                    </span>
                    <span className="text-[#7B7B7B] flex gap-1 ">
                        <img
                            src={whatshapPng}
                            className="h-3 w-3 sm:h-4 sm:w-4 object-contain"
                            alt=""
                        />
                        {item?.salesPersonId?.phoneNumber
                            ? item?.salesPersonId?.phoneNumber
                            : "Not issue yet"}
                    </span>
                </div>
                <button
                    disabled={item?.status !== "completed"}
                    onClick={() =>
                        handleModalOpen(
                            item?.priceData?.price,
                            item?.priceData?.validTime
                        )
                    }
                    className={`hidden sm:block text-xs font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out ${item?.status === "completed"
                        ? "bg-gradient-to-r from-green-500 to-green-700 text-white cursor-pointer"
                        : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-200 cursor-not-allowed"
                        }`}>
                    Price & Validity
                </button>
            </div>

        
            <div className="h-24 w-full gap-2 flex sm:hidden ">
                <img src="https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?cs=srgb&dl=pexels-madebymath-90946.jpg&fm=jpg" alt="" className="w-24 h-24 rounded-sm border relative" />
                <div className=" space-y-[2px] relative w-full">
                    <h1 className="text-[12px] font-bold">{item?.productName}</h1>
                    <p className="text-[10px] text-gray-600">
                        <b>Date</b>: {formatDateTime(item?.date)}
                    </p>
                    <p className="text-[10px] text-gray-600">
                        <b>Quantitys</b> : {item?.quantity} {item?.unit?.name}
                    </p>
                    <button
                        disabled={item?.status !== "completed"}
                        onClick={() =>
                            handleModalOpen(
                                item?.priceData?.price,
                                item?.priceData?.validTime
                            )
                        }
                        className={`text-[10px] ml-auto sm:text-sm font-medium mt-auto  px-3 py-1 rounded-md absolute right-1 bottom-1  mx-auto focus:ring-2 sm:hidden transition-colors duration-300 ease-in-out ${item?.status === "completed"
                            ? "bg-gradient-to-r from-green-500 to-green-700 text-white cursor-pointer"
                            : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-200 cursor-not-allowed"
                            }`}>
                        Price & Validity
                    </button>
                </div>
            </div>
            <ol className="flex items-center w-[80%] mx-auto justify-between sm:hidden">
                <li
                    className={`flex items-center w-full relative   after:content-[''] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block ${item.status === "rejected" ||
                        item.status === "completed"
                        ? "after:border-[#16A085]"
                        : "after:border-[#AEAEAE]"
                        }`}>
                    <span className="flex items-center justify-center  bg-blue-100 rounded-full h-6 w-6 sm:h-8 sm:w-8  shrink-0">
                        <img
                            src={
                                item.status === "pending"
                                    ? getStatusImages("pending")
                                    : checkedPng
                            }
                            alt=""
                            className="h-full w-full "
                        />
                    </span>
                    <p className="absolute top-8 sm:top-10 left-[-30px] sm:left-[-40px] flex flex-col gap-1 sm:gap-2 items-center">
                        <span className="font-medium text-[10px] sm:text-xs">Pending</span>
                        <span className="text-[8px] sm:text-xs text-[#868383]">
                            {item?.date && formatDateTime(item.date)}
                        </span>
                    </p>
                </li>
                <li className={`flex relative items-center`}>
                    <span className="flex items-center justify-center  bg-gray-100 rounded-full h-6 w-6 sm:h-8 sm:w-8  shrink-0">
                        <img
                            src={
                                item.status === "rejected"
                                    ? getStatusImages("rejected")
                                    : item.status === "completed"
                                        ? checkedPng
                                        : waitPng
                            }
                            alt=""
                            className="h-full w-full"
                        />
                    </span>
                    <p className="absolute top-8 sm:top-10 left-[-60px] sm:left-[-80px]  flex flex-col gap-1 sm:gap-2 items-center w-[150px] sm:w-[200px]">
                        <span className="font-medium text-[10px] sm:text-xs">
                            {item.status === "rejected"
                                ? "Rejected"
                                : item.status === "completed"
                                    ? "Completed"
                                    : "Pending"}
                        </span>
                        <span className="text-[8px] sm:text-xs text-[#868383]">
                            {item?.completedAt &&
                                formatDateTime(item.completedAt)}
                        </span>
                    </p>
                </li>
            </ol>

            <div className="text-[#605959] font-medium text-sm hidden sm:block">
                Date : {formatDateTime(item?.date)}
            </div>
            <div className="text-[#605959] font-medium text-sm hidden sm:block">
                <span className="font-bold">Quantity</span> : {item?.quantity}{" "}
                {item?.unit?.name}
            </div>
            <div className="w-full p-4 relative sm:p-4 sm:border border-gray-300 rounded-md sm:h-[110px]">
                <ol className="hidden sm:flex items-center w-[80%] mx-auto justify-between">
                    <li
                        className={`flex items-center w-full relative   after:content-[''] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block ${item.status === "rejected" ||
                            item.status === "completed"
                            ? "after:border-[#16A085]"
                            : "after:border-[#AEAEAE]"
                            }`}>
                        <span className="flex items-center justify-center  bg-blue-100 rounded-full h-8 w-8  shrink-0">
                            <img
                                src={
                                    item.status === "pending"
                                        ? getStatusImages("pending")
                                        : checkedPng
                                }
                                alt=""
                                className="h-full w-full "
                            />
                        </span>
                        <p className="absolute top-10 left-[-40px] flex flex-col gap-2 items-center">
                            <span className="font-medium text-xs">Pending</span>
                            <span className="text-xs text-[#868383]">
                                {item?.date && formatDateTime(item.date)}
                            </span>
                        </p>
                    </li>
                    <li className={`flex relative items-center`}>
                        <span className="flex items-center justify-center  bg-gray-100 rounded-full h-8 w-8  shrink-0">
                            <img
                                src={
                                    item.status === "rejected"
                                        ? getStatusImages("rejected")
                                        : item.status === "completed"
                                            ? checkedPng
                                            : waitPng
                                }
                                alt=""
                                className="h-full w-full"
                            />
                        </span>
                        <p className="absolute top-10 left-[-80px]  flex flex-col gap-2 items-center w-[200px]">
                            <span className="font-medium text-xs">
                                {item.status === "rejected"
                                    ? "Rejected"
                                    : item.status === "completed"
                                        ? "Completed"
                                        : "Pending"}
                            </span>
                            <span className="text-xs text-[#868383]">
                                {item?.completedAt &&
                                    formatDateTime(item.completedAt)}
                            </span>
                        </p>
                    </li>
                </ol>
                {/* <ol className="relative block sm:hidden text-gray-500 border-s border-[#16A085]  ">
                    <li className="mb-10 ms-6">
                        <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
                            <img
                                src={
                                    item.status === "pending"
                                        ? getStatusImages("pending")
                                        : checkedPng
                                }
                                alt=""
                                className="h-full w-full"
                            />
                        </span>
                        <h3 className="font-medium leading-tight text-xs ">
                            Pending
                        </h3>
                        <p className="text-[10px]">
                            ({item?.date && formatDateTime(item.date)})
                        </p>
                    </li>
                    <li className="mb-10 ms-6">
                        <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                            <img
                                src={
                                    item.status === "rejected"
                                        ? getStatusImages("rejected")
                                        : item.status === "completed"
                                            ? checkedPng
                                            : waitPng
                                }
                                alt=""
                                className="h-full w-full"
                            />
                        </span>
                        <h3 className="font-medium leading-tight text-xs pt-2">
                            {item.status === "rejected"
                                ? "Rejected"
                                : item.status === "completed"
                                    ? "Completed"
                                    : "Pending"}
                        </h3>
                        <p className="text-[10px]">
                            {item?.completedAt &&
                                formatDateTime(item.completedAt)}
                        </p>
                    </li>
                </ol>
                <div className="flex justify-center w-full">
                    <button
                        disabled={item?.status !== "completed"}
                        onClick={() =>
                            handleModalOpen(
                                item?.priceData?.price,
                                item?.priceData?.validTime
                            )
                        }
                        className={`text-[10px] sm:text-sm font-medium px-3 py-2 rounded-md mt-6 mx-auto bottom-2 right-1 focus:ring-2 sm:hidden transition-colors duration-300 ease-in-out ${item?.status === "completed"
                            ? "bg-gradient-to-r from-green-500 to-green-700 text-white cursor-pointer"
                            : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-200 cursor-not-allowed"
                            }`}>
                        Price & Validity
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default QuotationTrackerData;

// import { ProductQuery } from "../profileTyps";
// import { formatDateTime } from "../../../../utils/formatDateTime";
// import whatshapPng from "../../../../assets/profile/quotationTrackerImages/Whatsapp.png";
// import checkedPng from "../../../../assets/profile/quotationTrackerImages/Checked.png";
// import waitPng from "../../../../assets/profile/quotationTrackerImages/wait.png";
// type DataProps = {
//     item: ProductQuery;
//     getStatusImages: (value: string) => string;
//     handleModalOpen: (price: number | null, validity: string | null) => void;
// };

// const QuotationTrackerData = ({ item, getStatusImages, handleModalOpen, }: DataProps) => {
//     return (
//         <div className="w-full h-auto rounded-md  shadow-sm border mb-5  p-4 flex flex-col gap-3 py-5 mt-2">
//             <div className="flex flex-col sm:flex-row w-full items-center sm:items-start  sm:justify-between">
//                 <h2 className="3 text-xl sm:text-2xl font-bold mb-2 sm:mb-0">
//                     Query Details
//                 </h2>
//                 <div className="  text-[10px] sm:text-xs flex flex-col items-center gap-1">
//                     <span className="text-[#7B7B7B]">
//                         {" "}
//                         <span className="font-inter font-bold text-[#141414]">
//                             Sales Person Name :
//                         </span>{" "}
//                         {item?.salesPersonId?.name
//                             ? item?.salesPersonId?.name
//                             : "Not issue yet"}
//                     </span>
//                     <span className="text-[#7B7B7B] flex gap-1">
//                         <img
//                             src={whatshapPng}
//                             className="h-4 w-4 object-contain"
//                             alt=""
//                         />
//                         {item?.salesPersonId?.phoneNumber
//                             ? item?.salesPersonId?.phoneNumber
//                             : "Not issue yet"}
//                     </span>
//                 </div>
//                 <button
//                     disabled={item?.status !== "completed"}
//                     onClick={() =>
//                         handleModalOpen(
//                             item?.priceData?.price,
//                             item?.priceData?.validTime
//                         )
//                     }
//                     className={`hidden sm:block text-xs font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out ${item?.status === "completed"
//                         ? "bg-gradient-to-r from-green-500 to-green-700 text-white cursor-pointer"
//                         : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-200 cursor-not-allowed"
//                         }`}>
//                     Price & Validity
//                 </button>
//             </div>
//             <div className="text-[#605959] font-medium text-xs sm:text-sm">
//                 Date : {formatDateTime(item?.date)}
//             </div>
//             <div className="text-[#605959] font-medium text-xs sm:text-sm">
//                 <span className="font-bold">Quantity</span> : {item?.quantity}{" "}
//                 {item?.unit?.name}
//             </div>
//             <div className="w-full p-4 relative sm:p-4 sm:border border-gray-300 rounded-md sm:h-[110px]">
//                 <ol className="hidden sm:flex items-center w-[80%] mx-auto justify-between">
//                     <li
//                         className={`flex items-center w-full relative   after:content-[''] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block ${item.status === "rejected" ||
//                             item.status === "completed"
//                             ? "after:border-[#16A085]"
//                             : "after:border-[#AEAEAE]"
//                             }`}>
//                         <span className="flex items-center justify-center  bg-blue-100 rounded-full h-8 w-8  shrink-0">
//                             <img
//                                 src={
//                                     item.status === "pending"
//                                         ? getStatusImages("pending")
//                                         : checkedPng
//                                 }
//                                 alt=""
//                                 className="h-full w-full "
//                             />
//                         </span>
//                         <p className="absolute top-10 left-[-40px] flex flex-col gap-2 items-center">
//                             <span className="font-medium text-xs">Pending</span>
//                             <span className="text-xs text-[#868383]">
//                                 {item?.date && formatDateTime(item.date)}
//                             </span>
//                         </p>
//                     </li>
//                     <li className={`flex relative items-center`}>
//                         <span className="flex items-center justify-center  bg-gray-100 rounded-full h-8 w-8  shrink-0">
//                             <img
//                                 src={
//                                     item.status === "rejected"
//                                         ? getStatusImages("rejected")
//                                         : item.status === "completed"
//                                             ? checkedPng
//                                             : waitPng
//                                 }
//                                 alt=""
//                                 className="h-full w-full"
//                             />
//                         </span>
//                         <p className="absolute top-10 left-[-80px]  flex flex-col gap-2 items-center w-[200px]">
//                             <span className="font-medium text-xs">
//                                 {item.status === "rejected"
//                                     ? "Rejected"
//                                     : item.status === "completed"
//                                         ? "Completed"
//                                         : "Pending"}
//                             </span>
//                             <span className="text-xs text-[#868383]">
//                                 {item?.completedAt &&
//                                     formatDateTime(item.completedAt)}
//                             </span>
//                         </p>
//                     </li>
//                 </ol>
//                 <ol className="relative block sm:hidden text-gray-500 border-s border-[#16A085]  ">
//                     <li className="mb-10 ms-6">
//                         <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
//                             <img
//                                 src={
//                                     item.status === "pending"
//                                         ? getStatusImages("pending")
//                                         : checkedPng
//                                 }
//                                 alt=""
//                                 className="h-full w-full"
//                             />
//                         </span>
//                         <h3 className="font-medium leading-tight text-xs ">
//                             Pending
//                         </h3>
//                         <p className="text-[10px]">
//                         ({item?.date && formatDateTime(item.date)})
//                         </p>
//                     </li>
//                     <li className="mb-10 ms-6">
//                         <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
//                             <img
//                                 src={
//                                     item.status === "rejected"
//                                         ? getStatusImages("rejected")
//                                         : item.status === "completed"
//                                             ? checkedPng
//                                             : waitPng
//                                 }
//                                 alt=""
//                                 className="h-full w-full"
//                             />
//                         </span>
//                         <h3 className="font-medium leading-tight text-xs pt-2">
//                             {item.status === "rejected"
//                                 ? "Rejected"
//                                 : item.status === "completed"
//                                     ? "Completed"
//                                     : "Pending"}
//                         </h3>
//                         <p className="text-[10px]">
//                             {item?.completedAt &&
//                                 formatDateTime(item.completedAt)}
//                         </p>
//                     </li>
//                 </ol>
//                 <div className="flex justify-center w-full">
//                     <button
//                         disabled={item?.status !== "completed"}
//                         onClick={() =>
//                             handleModalOpen(
//                                 item?.priceData?.price,
//                                 item?.priceData?.validTime
//                             )
//                         }
//                         className={`text-[10px] sm:text-sm font-medium px-3 py-2 rounded-md mt-6 mx-auto bottom-2 right-1 focus:ring-2 sm:hidden transition-colors duration-300 ease-in-out ${item?.status === "completed"
//                             ? "bg-gradient-to-r from-green-500 to-green-700 text-white cursor-pointer"
//                             : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-200 cursor-not-allowed"
//                             }`}>
//                         Price & Validity
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default QuotationTrackerData;
