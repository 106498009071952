import { useEffect, useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDeleteContractorMutation, usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Contractor } from "./types";
import { RootState } from "../../../Redux/store";
import useDebounce from "../../../common/hooks/useDebounce";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { exportToExcel } from "../../../utils/exportToExcel";
import { toast } from "react-toastify";
import ConfirmModel from "../../../utils/ConfirmModel";

interface DeleteUser {
	_id: string;
	remark: string;
}

export const Contractors = () => {
	const [limit, setLimit] = useState<string | number>("20");
	const [page, setPage] = useState<number>(1);
	const [name, setName] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [grade, setGrade] = useState<string>("");
	const [totalPages, setTotalPages] = useState<number>(1);
	const [fetchContractors, { data, isLoading }] = usePublishedContractorMutation();
	const [deleteContractorMutation, { isSuccess, isError }] = useDeleteContractorMutation();
	const [selectedId, setSelectedId] = useState<string | null>(null)
	const [isDeleted, setIsDeleted] = useState<boolean>(false)
	const debouncedName = useDebounce(name, 500);
	const debouncedCity = useDebounce(city, 500);

	const options = ["9", "8", "7", "All"];

	const handleSelect = (option: string) => { setLimit(option === "All" ? "All" : parseInt(option)) };
	const navigate = useNavigate();
	const Contractor = useSelector(
		(state: RootState) => state.admin.Contractor
	);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const params: {
					limit?: string | number;
					page: number;
					name?: string;
					city?: string;
					grade?: string;
				} = { page, name: debouncedName, city: debouncedCity, grade };
				if (limit !== "All" && limit !== "all") {
					params.limit = limit;
				}
				const response = await fetchContractors(params).unwrap();
				setTotalPages(response.totalPages);
			} catch (error) {
				console.error("Error fetching contractors:", error);
			}
		};

		fetchData();
	}, [page, limit, debouncedName, debouncedCity, grade, fetchContractors]);

	useEffect(() => {
		setTotalPages(data?.totalPages);
	}, [data]);


	const contractor = Contractor?.data?.approveContractor;

	const prevPage = () => { if (page > 1) { setPage(page - 1) } };

	const nextPage = () => { if (page < totalPages) { setPage(page + 1) } };

	const startItem = (page - 1) * (typeof limit === "number" ? limit : parseInt(limit)) + 1;
	const endItem = Math.min(page * (typeof limit === "number" ? limit : parseInt(limit)), contractor || 0);


	const handleExportToExcel = () => {
		const filteredData = (data?.contractors || []).map(
			(contractor: any, index: any) => {
				const dateObject = new Date(contractor.dateOfCreation);

				return {
					SerialNo: index + 1,
					Name: contractor.name,
					Contact: contractor.phoneNumber,
					EmailID: contractor.email,
					City: contractor.city,
					GST: contractor.gst,
					Grade: contractor.contractorGrade,
					Date: dateObject.toLocaleDateString(),
					Time: dateObject.toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					}),
				};
			}
		);
		exportToExcel(filteredData, "Contractors")
	};



	const resetAll = () => {
		setPage(1);
		setLimit("20");
		setName("");
		setCity("");
		setGrade("");
	};




	const handleDelete = (id: string): void => {
		if (id !== null) {
			setSelectedId(id);
			setIsDeleted(true)
		}
	}

	const handleClose = () => {
		setIsDeleted(false)
		setSelectedId(null);
	}

	const handleConfirmDelete = async (isConfirmed: boolean) => {
		try {
			if (isConfirmed && selectedId) {
				await deleteContractorMutation({ id: selectedId }).unwrap();
				toast.success("Contractor deleted successfully!");
				setIsDeleted(false);
				setSelectedId(null);
			}
		} catch (error) {
			toast.error("Failed to delete contractor. Please try again.");
		}
	};


	const tableHeaders = [
		{ label: "", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B]" },
		{ label: "Name", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B]" },
		{ label: "Contact", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B]" },
		{ label: "Contractor Type", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B] whitespace-nowrap" },
		{ label: "City", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B]" },
		{ label: "Grade", className: "px-6 py-3 text-left text-sm font-inter text-[#1E293B]" },
	];


	return (
		<div className="w-full px-3 mt-3 pb-3">
			<div className="bg-white rounded-t-xl mb-4 pt-2">
				{/* Filters */}
				<div className="flex justify-between py-2 px-4">
					<div className="flex items-center gap-2">
						<input
							type="text"
							onChange={(e) => setName(e.target.value)}
							value={name}
							className={`admin-input`}
							placeholder="Search.."

						/>
						<input
							type="text"
							onChange={(e) => setCity(e.target.value)}
							value={city}
							className={`admin-input`}
							placeholder="City.."

						/>
						<select
							onChange={(e) => setGrade(e.target.value)}
							value={grade}
							className={`admin-input`}
						>
							<option value="">Select Grade</option>
							<option value="A">A</option>
							<option value="B">B</option>
							<option value="C">C</option>
						</select>
					</div>
					<div className="flex gap-2">
						<button
							onClick={resetAll}
							className="reset-btn">
							Reset
						</button>
						<button
							onClick={handleExportToExcel}
							className="excel-btn">
							Excel{" "}
							<RiFileExcel2Fill className="w-[20px] h-[20px]" />
						</button>
					</div>
				</div>
				{/* Table Content */}
				<div className="w-full overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
						<thead className="bg-white divide-y divide-gray-200">
							<tr className="border-t">
								{tableHeaders.map((header, index) => (
									<th key={index} className={header.className}>
										{header.label}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white ">
							{isLoading ? (
								<TableLoading colCount={7} />
							) : data?.contractors?.length > 0 ? (
								data.contractors.map((i: Contractor) => (
									<tr key={i._id}>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
										</td>
										<td
											className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#0078FB] underline cursor-pointer"
											onClick={() =>
												navigate(
													`/admin/contractors/profile/${i._id}`
												)
											}>
											{i.name || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.phoneNumber}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.contractorType || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.city || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.contractorGrade || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											<button
												onClick={() => handleDelete(i._id)}
												className=" text-sm bg-red-100 border-red-600 text-red-500   py-1 px-2 rounded-xl "
											>
												Delete
											</button>
										</td>
									</tr>
								))
							) : (
								<TableNoData colCount={7} text="Data Not Available" />
							)}
						</tbody>
					</table>
				</div>
				{/* Pagination */}
				<div className="flex flex-row justify-between p-3 m-1 border-t-2 ">
					<div>
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleSelect}
							/>
							<p className="font-semibold">
								Showing{" "}
								{limit === "All"
									? "All"
									: `${startItem || 0} - ${endItem || ""
									}`}{" "}
								Contractors
							</p>
							<p className="text-[#A6A8B1]">
								Contractors
							</p>
						</div>
					</div>

					<div>
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleSelect}
							/>
							<p className="text-[#A6A8B1]">
								{page}of {totalPages} pages
							</p>
							<div className="flex flex-row items-center gap-2 text-[#666666]">
								<span
									onClick={prevPage}
									className={`cursor-pointer ${page === 1 ? "opacity-50" : ""
										}`}>
									<FaChevronLeft size={15} />
								</span>
								<span
									onClick={nextPage}
									className={`cursor-pointer ${page === totalPages
										? "opacity-50"
										: ""
										}`}>
									<FaChevronRight size={15} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConfirmModel
				isOpen={isDeleted}
				onClose={handleClose}
				onConfirm={handleConfirmDelete}
				message="Are you sure you want to delete this Contractor ?"
			/>
		</div>
	);
};
