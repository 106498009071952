import { BiCoinStack } from "react-icons/bi"
import { IOrderData } from "../profileTyps"
type DataProps = {
    item: IOrderData
    toggleExpanded: (index: any) => void,
    handleAwardBonus: (index: any) => void,
    isExpanded: boolean,
    index: number,
    awardedBonus: number | null

}

const CoinHistoryData = ({ item, toggleExpanded, isExpanded, handleAwardBonus, index, awardedBonus }: DataProps) => {
    return (
        <div >
            {item.customerName ? (
                <div>
                    <div
                        className="relative w-full cursor-pointer justify-between text-[#7D7D7D] h-auto rounded-md bg-white p-4 font-medium space-y-1 box-border shadow-md border-[0.2px]"

                        onClick={() =>
                            toggleExpanded(index)
                        }>
                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center pb-1">
                            <div>
                                <div className="text-lg font-semibold text-gray-800 cursor-pointer">
                                    Order Number:{" "}
                                    <div
                                        className="text-blue-600"
                                        style={{
                                            display:
                                                "inline",
                                        }}>
                                        {item.orderNumber}
                                    </div>
                                </div>
                                <p className="text-[#888888]">
                                    Customer Name:{" "}
                                    {item?.customerName}
                                </p>
                            </div>
                            <div className="flex items-center space-x-3 ">
                                <div className="text-lg font-semibold">
                                    {item?.type ===
                                        "add" ? (
                                        <span className="text-[#00CB4A]">
                                            +
                                            {
                                                item?.totalCoins
                                            }
                                        </span>
                                    ) : (
                                        <span className="text-[#FF0000]">
                                            {
                                                item?.totalCoins
                                            }
                                        </span>
                                    )}
                                </div>
                                <BiCoinStack className="h-10 w-10 text-[#FF9900]" />
                            </div>
                        </div>

                        <div className="mt-1 flex flex-col sm:flex-row sm:justify-between text-sm text-gray-500">
                            <div>
                                <span className="font-semibold">
                                    Date:{" "}
                                </span>
                                {
                                    item?.createdAt.split(
                                        " "
                                    )[0]
                                }{" "}
                                {
                                    item?.createdAt.split(
                                        " "
                                    )[1]
                                }
                            </div>
                            <div>
                                <span className="font-semibold text-orange-500">
                                    {item?.currentCoins}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto items-center mb-3 ${isExpanded
                            ? "scale-100 opacity-100 max-h-screen"
                            : "scale-95 opacity-0 max-h-0"
                            }`}
                        style={{ transformOrigin: "top" }}>
                        <div className="p-2 bg-[#F1F1F1] w-[95%] rounded-b-xl h-full shadow-lg">
                            <div className="flex items-start gap-2.5 w-full">
                                <div className="flex flex-col gap-1 w-full">
                                    <div className="mt-2 space-y-4">
                                        {item?.itemData?.map(
                                            (
                                                subItem: any,
                                                subIndex: number
                                            ) => (
                                                <div
                                                    key={
                                                        subItem.combinedName
                                                    }
                                                    className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between items-center p-4 bg-white rounded-lg transition-all"
                                                    style={{
                                                        boxShadow:
                                                            "1px 2px 4px 0px #00000040 inset",
                                                    }}>
                                                    <div className="text-md font-semibold text-gray-700">
                                                        {subIndex +
                                                            1}
                                                        .{" "}
                                                        {
                                                            subItem.combinedName
                                                        }
                                                    </div>
                                                    <div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
                                                        <BiCoinStack className="h-7 w-7 text-[#FF9900]" />
                                                        <span>
                                                            {
                                                                subItem.coins
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={`w-full justify-between text-[#7D7D7D] relative h-auto rounded-md bg-white p-4 font-medium space-y-1 box-border mb-3 shadow-md border-[0.2px] ${awardedBonus === item.coins
                        ? "bg-green-50 "
                        : ""
                        }`}
                
                    onMouseEnter={() =>
                        handleAwardBonus(index)
                    }>
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-700">
                                Bonus Coins{" "}
                                {item?.type === "add"
                                    ? "Awarded"
                                    : "Deducted"}
                            </h3>
                        </div>
                        <div className="flex items-center space-x-3">
                            <div className="text-lg font-semibold">
                                {item?.type === "add" ? (
                                    <span className="text-green-600">
                                        +{item?.coins}
                                    </span>
                                ) : (
                                    <span className="text-red-500">
                                        -{item?.coins}
                                    </span>
                                )}
                            </div>
                            <BiCoinStack className="h-10 w-10 text-[#FF9900]" />
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col sm:flex-row sm:justify-between text-sm text-gray-500">
                        <div>
                            <span className="font-semibold">
                                Date:{" "}
                            </span>
                            {item?.createdAt.split(" ")[0]}{" "}
                            {item?.createdAt.split(" ")[1]}
                        </div>
                        <div>
                            <span className="font-semibold text-orange-500">
                                {item?.currentCoins}
                            </span>
                        </div>
                    </div>
                    {awardedBonus === index && (
                        <div
                            className={`absolute inset-0 bg-opacity-75 flex items-center justify-center rounded-lg text-center font-semibold ${item.type === "add"
                                ? "bg-green-200"
                                : "bg-red-200"
                                }`}>
                            {item.type === "add" ? (
                                <span className="text-green-600 font-bold px-2">
                                    {" "}
                                    Bonus Awarded!
                                </span>
                            ) : (
                                <span className="text-red-500 font-bold px-2">
                                    {" "}
                                    Bonus Removed!
                                </span>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CoinHistoryData