import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { RiCloseLine, RiImageEditLine } from "react-icons/ri";
import { useRef, useState } from "react";
import { useChangeBagroundImageMutation, useContractorAvtarMutation } from "../../../Redux/Api/Contractor";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import { toast } from "react-toastify";
import DefaultImage from "../../../assets/profile/DefualtProfile.png";
import  coverImage from "../../../assets/profile/smCoverImage.jpg";
const MobileProfileImage = () => {
    const [updateBackgroundImage, { isLoading: isCoverLoading }] = useChangeBagroundImageMutation();
    const [mutateImage, { isLoading: isProfileUpdating }] = useContractorAvtarMutation();
    const [getImageURL, { isLoading: isUrlLoading }] = useCreateImageURLMutation();

    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const [profilePreview, setProfilePreview] = useState<string>("");
    const [coverPreview, setCoverPreview] = useState<string>("");
    const [profileFile, setProfileFile] = useState<File | undefined>(undefined);
    const [coverFile, setCoverFile] = useState<File | undefined>(undefined);

    const profileRef = useRef<HTMLInputElement>(null);
    const coverRef = useRef<HTMLInputElement>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, type: "profile" | "cover") => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                if (type === "profile") {
                    setProfilePreview(reader.result as string);
                    setProfileFile(file);
                    if (profileRef.current) profileRef.current.value = "";
                } else if (type === "cover") {
                    if (coverRef.current) coverRef.current.value = "";
                    setCoverPreview(reader.result as string);
                    setCoverFile(file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = (type: "profile" | "cover") => {
        if (type === "profile") {
            setProfilePreview("");
            setProfileFile(undefined);
        } else if (type === "cover") {
            setCoverPreview("");
            setCoverFile(undefined);
        }
    };

    const handleImageUpdate = async (type: "profile" | "cover") => {
        try {
            const preview = type === "profile" ? profilePreview : coverPreview;
            const file = type === "profile" ? profileFile : coverFile;
            const mutate = type === "profile" ? mutateImage : updateBackgroundImage;

            if (preview && file) {
                const body = new FormData();
                body.append("image", file);
                const response = await getImageURL(body).unwrap();
                if (response) {
                    await mutate({ image: response[0] }).unwrap();
                    toast.success(`${type === "profile" ? "Profile" : "Cover"} image updated successfully!`);
                    handleRemoveImage(type);
                }
            }
        } catch (err) {
            const errorMessage = "Failed to update image. Please try again later.";
            toast.error(errorMessage);
        }
    };

    return (
        <>
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-6">
                <div className="relative w-32 h-32">
                    <img
                        src={profilePreview || userDetails?.avatar || DefaultImage}
                        className="w-32 h-32 rounded-full object-cover"
                    />
                    <button
                        onClick={() => {
                            if (!profilePreview) {
                                profileRef.current && profileRef.current.click();
                            } else {
                                handleRemoveImage("profile");
                            }
                        }}
                        className={`absolute bottom-2 right-2 flex items-center justify-center w-8 h-8 text-white rounded-full shadow-md focus:outline-none ${profilePreview
                            ? "bg-red-600 hover:bg-red-500 "
                            : "bg-gray-800 hover:bg-gray-700"
                            }`}
                        aria-label="Edit profile picture"
                    >
                        {profilePreview ? <RiCloseLine size={18} /> : <RiImageEditLine size={18} />}
                    </button>

                    <input
                        id="profile-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        ref={profileRef}
                        onChange={(event) => handleImageChange(event, "profile")}
                    />
                </div>
                {
                    profilePreview ? <button
                        disabled={isProfileUpdating || isUrlLoading}
                        onClick={() => handleImageUpdate("profile")}
                        className="flex items-center gap-1 text-sm  mt-2 font-medium  bg-white px-4 py-2 border border-gray-300 rounded-full shadow-sm hover:bg-gray-100 focus:outline-none text-blue-700 ">
                        {isProfileUpdating || isUrlLoading ? "Updating..." : "Update"}
                    </button> : <p className="text-gray-600 text-sm mt-2 px-4 py-2">
                        Change Profile Picture
                    </p>
                }

            </div>

            {/* Cover Image Section */}
            <div className="mb-6">
                <label
                    htmlFor="cover-image"
                    className="text-gray-700 text-lg mb-2 block font-bold">
                    Cover Image
                </label>
                <div className="w-full h-[140px] relative mb-2">
                    <img
                        src={coverPreview || userDetails?.backgroundImage || coverImage}
                        alt="User Cover"
                        className="w-full h-full object-cover object-center rounded-lg"
                    />
                </div>
                <div className="flex gap-2 items-center">
                    <button
                        disabled={isCoverLoading || isUrlLoading}
                        onClick={() => {
                            if (!coverPreview) {
                                coverRef.current && coverRef.current.click();
                            } else {
                                handleImageUpdate("cover");
                            }
                        }}
                        className={`flex items-center gap-1 text-sm font-medium  bg-white px-4 py-2 border border-gray-300 rounded-full shadow-sm hover:bg-gray-100 focus:outline-none ${coverPreview ? ' text-blue-700' : ' text-gray-700'
                            }`}
                        aria-label="Edit cover image"
                    >
                        <RiImageEditLine size={18} />
                        {!coverPreview ? 'Edit' : isCoverLoading || isUrlLoading ? 'Updating...' : 'Update'}
                    </button>

                    {
                        coverPreview && <button
                            onClick={() => handleRemoveImage("cover")}
                            className="flex items-center gap-1 text-sm font-medium text-red-700 bg-white px-4 py-2 border border-gray-300 rounded-full shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-red-400"
                            aria-label="Edit cover image">
                            <RiCloseLine size={18} />
                            Remove

                        </button>
                    }
                </div>
                <input
                    id="cover-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={coverRef}
                    onChange={(event) => handleImageChange(event, "cover")}
                />
            </div>
        </>
    )
}

export default MobileProfileImage
