import { useNavigate } from "react-router-dom";
import { Products } from "./types";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import sheild from "../../../assets/Shield Done.png";
import defaultImage from "../../../assets/defaultImage.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";

const ItemCard: React.FC<{ data: Products }> = ({ data }) => {
	const id = data?.id;
	const navigate = useNavigate();
	const userDetails = useSelector(
		(state: RootState) => state.Login.ContractorData
	);

	const handleWhatsappClick = (e: React.MouseEvent<HTMLImageElement>) => {
		e.stopPropagation();
		const phoneNumber = "919258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};
	const handleProductClick = (id: string | null, name: string | null) => {
		navigate(`/product/${id}/${name}`, { state: { id } });
	};

	const backgroundStyle = {
		background:
			"linear-gradient(319.16deg, rgba(68, 167, 185, 0.1) -456.66%, #FFFFFF 96.11%)",
		boxShadow: "0px 1px 10.1px 0px #00000047",
	};

	return (
		<>
			<div onClick={() => handleProductClick(data?.id, data?.productName)} className="hidden sm:flex gap-[1rem] border-[0.1px] sm:gap-[2rem] p-2 sm:px-4 sm:py-[15px] w-full mb-3 relative group sm:hover:bg-[#d0f0f5]  duration-300   cursor-pointer  rounded-md  shadow-md sm:hover:shadow-lg">
				<div

					className="cursor-pointer relative w-[170px] h-[170px] md:w-[197px] md:h-[197px] overflow-hidden rounded-md  p-1 ring-1 "
					style={backgroundStyle}>
					<img
						src={
							Array.isArray(data?.images)
								? data?.images?.length > 0
									? data?.images[0]
									: typeof data?.images === "string"
										? data?.images
										: defaultImage
								: data?.images
									? data?.images
									: defaultImage
						}
						alt="item"
						className="w-[170px] h-full  md:w-[197px] object-cover rounded-md  border-2"
					/>
					{data?.companyImage[0] ? (
						<div className="flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] border-[3px]  z-10 ring-1 ring-[#5097A4] bg-white absolute bottom-2 right-2 rounded-full   flex-col items-center justify-center gap-1 sm:group-hover:opacity-0 sm:group-hover:translate-y-2 transition-all duration-300 ease-in-out">
							<img
								src={data?.companyImage[0]}
								alt=""
								className="w-full h-full object-contain rounded-full"
							/>
						</div>
					) : (
						<div className=" flex w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] z-10 border-[3px] border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-green-100   flex-col items-center justify-center gap-1 sm:group-hover:opacity-0 sm:group-hover:translate-y-2 transition-all duration-300 ease-in-out">
							<img
								src={sheild}
								alt="Shield Icon"
								className="w-3 h-3"
							/>
							<div className="text-[6.5px] sm:text-[8px] text-wrap text-center font-medium text-[#047857]">
								CIPL <br /> Verified
							</div>
						</div>
					)}
					<div className="absolute top-0 w-full left-0 z-20 py-2 px-1  sm:rounded-br-[100px] flex items-center justify-center bg-white gap-[10px] sm:group-hover:opacity-0 sm:group-hover:-translate-y-2 transition-all duration-300 ease-in-out">
						{data?.companyImage[0] ? (
							<>
								<div className="hidden sm:flex whitespace-nowrap text-wrap overflow-hidden text-center text-green-700 text-xs font-[700]">
									{(() => {
										const companyName =
											data?.companyName || "";
										return companyName.length > 17
											? companyName.slice(0, 17) + "..."
											: companyName;
									})()}
								</div>
								<div className="flex sm:hidden whitespace-nowrap text-wrap overflow-hidden  text-green-700 text-xs font-[700]">
									{(() => {
										const companyName =
											data?.companyName || "";
										return companyName.length > 10
											? companyName.slice(0, 10) + "..."
											: companyName;
									})()}
								</div>
							</>
						) : (
							<>
								<img
									src={sheild}
									alt="sheild"
									className="w-[13px] h-[15px] object-cover hidden sm:block"
								/>
								<div className="flex whitespace-nowrap overflow-hidden text-green-700 text-xs sm:text-[14px] font-[400]">
									CIPL Verified
								</div>
							</>
						)}
					</div>
				</div>

				<div className="flex w-full justify-between py-1">
					<div className="flex flex-col gap-[5px] sm:gap-[10px]  ">
						<h3
							className=" hidden sm:block  text-slate-900 font-semibold text-sm sm:text-base cursor-pointer"
						>
							{data?.productName}
						</h3>
						<h3
							className="block sm:hidden  text-slate-900 font-semibold text-sm cursor-pointer"
						>
							{data?.productName?.length > 23
								? `${data.productName.slice(0, 23)}...`
								: data?.productName}
						</h3>

						<div className="flex flex-col  gap-[5px]">
							<div className="font-[500] text-[14px] leading-[17px] flex  items-center gap-[10px] ">
								{
									data?.highLowPrice?.lowFinalPrice !== undefined && data?.highLowPrice?.highFinalPrice !== undefined ?
										`₹${Number(data?.highLowPrice.lowFinalPrice).toFixed(2)} - ₹${Number(data?.highLowPrice.highFinalPrice).toFixed(2)}/${data?.unit}` : <span className="font-medium text-[10px] sm:text-[16px]">
											Price Not Available !
										</span>
								}

							</div>
							<span className="text-[10px] sm:text-xs font-medium">Item Code - <span className="text-gray-600">{data?.itemCode}</span></span>
							<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] sm:mt-1 text-blue-400 flex items-center gap-2">
								<span>
									{" "}
									(For Best Rate , Please Contact Us)
								</span>
								<img
									src={whatsapp}
									className="w-[22px] h-[22px] cursor-pointer"
									onClick={handleWhatsappClick}
									alt="whatsapp"
								/>
							</div>
						</div>

						{data?.rewards ? (
							<div className="flex flex-wrap items-center  justify-start">
								<span className="text-xs sm:text-sm  lg:text-lg w-full sm:w-auto">
									Use Referral code to earn
								</span>
								<div className="flex items-center mt-1 sm:mt-0 sm:ml-1">
									<img
										className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6"
										src={icon}
										alt="Coin icon"
									/>
									<span className="text-sm sm:text-lg md:text-xl  font-semibold mx-1 sm:mx-2">
										{data?.rewards}
									</span>
									<span className="text-xs sm:text-sm md:text-base lg:text-lg">
										super coins
									</span>
								</div>
							</div>
						) : null}
						<div className="xl:absolute top-2 right-3">
							{data.status ? (
								<button className=" cursor-default bg-green-500 text-white text-nowrap text-[10px] sm:text-xs px-2 py-1 rounded-md sm:rounded-3xl">
									Available In Stock
								</button>
							) : (
								<button className="cursor-default bg-red-500 text-white text-nowrap text-[10px] sm:text-xs px-2 py-1 rounded-md sm:rounded-3xl">
									Awaiting Restock
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Mobile card */}
			<div onClick={() => handleProductClick(data?.id, data?.productName)} className="relative flex sm:hidden mb-4 p-2 w-full justify-between overflow-hidden rounded-md bg-white gap-3 shadow hover:shadow-md border border-[#F1F1F1] cursor-pointer">
				{/* Image Container */}
				<div className="shrink-0 h-24 w-24 bg-white border rounded-md overflow-hidden flex items-center justify-center">
					<img
						src={
							Array.isArray(data?.images) && data?.images.length > 0
								? data.images[0]
								: typeof data?.images === "string"
									? data.images
									: defaultImage
						}
						alt="Product Image"
						className="object-cover object-center w-full h-full rounded-md"
					/>
				</div>

				{/* Product Details */}
				<div className="w-full h-24  flex flex-col justify-between font-poppins">
					<div>
						<div className="text-[12px] font-medium leading-normal mb-1">
							{data?.productName?.length > 50
								? `${data.productName.slice(0, 50)}...`
								: data?.productName}
						</div>
						<p className="text-[8px] text-gray-600">Item-Code: 123</p>
						{data.status ? (
							<button className="text-[8px] text-white bg-green-500 px-3 py-0.5 rounded-full hover:bg-green-600">
								In Stock
							</button>
						) : (
							<button className="text-[8px] text-white bg-red-500 px-3 py-0.5 rounded-full hover:bg-red-600">
								Out of Stock
							</button>
						)}

					</div>

					{/* Stock Buttons */}

					<p className="text-[12px] text-[#1AC300] font-bold mt-auto">
						{
							data?.highLowPrice?.lowFinalPrice !== undefined && data?.highLowPrice?.highFinalPrice !== undefined ?
								`₹${Number(data?.highLowPrice.lowFinalPrice).toFixed(2)} - ₹${Number(data?.highLowPrice.highFinalPrice).toFixed(2)}/${data?.unit}` : <span className="font-medium text-[10px] sm:text-[16px]">
									Price Not Available !
								</span>
						}
					</p>


				</div>
			</div>




		</>
	);
};

export default ItemCard;
