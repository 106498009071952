import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { FaTimes, FaUser, FaPaperPlane, FaCross } from "react-icons/fa";
import { useAllFCMTokensQuery, useSendNotificationMutation } from "../../../Redux/Api/Notifications";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { toast } from "react-toastify";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SendMessageModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [search, setSearch] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [messageData, setMessageData] = useState<{ title: string; message: string }>({ title: "", message: "" });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { data } = useAllFCMTokensQuery();
  const [mutate, { isLoading }] = useSendNotificationMutation();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      reset();
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useClickOutside(dropdownRef, () => setDropdownOpen(false));
  const handleSelectUser = (id: string) => {
    console.log("Selected User ID:", id);
    setSelectedUser(id);
    setDropdownOpen(false);
  };

  const reset = () => {
    onClose();
    setMessageData({ title: "", message: "" });
    setSelectedUser(null);
    setDropdownOpen(false);
  };

  // Send notification
  const handleSend = () => {
    if (!messageData.title.trim() || !messageData.message.trim()) {
      toast.error("Please enter valid data");
      return;
    }

    if (selectedUser) {
      // Send to selected user
      mutate({ userId: selectedUser, title: messageData.title, body: messageData.message }).then(() => reset());
    } else {
      // Send to all users
      mutate({ title: messageData.title, body: messageData.message }).then(() => reset());
    }
  };

  if (!isOpen) return null;

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
  };

  let selectedUserObj = data?.data?.find((user: any) => user._id === selectedUser) || null
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative modal-content"
      >
        {/* Close Button */}
        <button onClick={reset} className="absolute top-3 right-3 text-gray-600 hover:text-gray-900">
          <FaTimes size={20} />
        </button>

        <h2 className="text-xl font-semibold text-gray-700 mb-4 flex items-center gap-2">
          <FaUser size={20} /> Send Message
        </h2>

        {/* Custom Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <div
            className={`w-full px-4 py-3  border rounded-lg cursor-pointer  focus:ring focus:ring-blue-200   flex items-center gap-2 relative ${!selectedUserObj ? "text-gray-400" : ""}`}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <FaUser size={18} className="text-gray-400" />
            {selectedUserObj ? selectedUserObj.name : "Select a User"}

            {selectedUserObj && (
              <div
                className="text-gray-500 absolute right-2 cursor-pointer text-lg hover:text-red-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedUser(null);
                  selectedUserObj = null;
                }}
              >
                ✖
              </div>
            )}
          </div>


          {dropdownOpen && (
            <motion.ul
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 5 }}
              className="absolute left-0 mt-2 w-full bg-white border rounded-lg shadow-md overflow-auto max-h-48"
            >
              <input
                type="search"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full p-2 border-b outline-none"
              />

              <ul>
                {data?.data?.filter((user: any) => user.name.toLowerCase().includes(search.toLowerCase())).length === 0 ? (
                  <li className="p-2 text-gray-500">No users found</li>
                ) : (
                  data?.data
                    ?.filter((user: any) => user.name.toLowerCase().includes(search.toLowerCase()))
                    .map((user: any) => (
                      <li
                        key={user._id}
                        onClick={() => handleSelectUser(user._id)}
                        className={`p-2 cursor-pointer ${selectedUser === user._id ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
                          }`}
                      >
                        {user.name}
                      </li>
                    ))
                )}
              </ul>

            </motion.ul>
          )}
        </div>

        {/* Message Input */}
        <input
          name="title"
          placeholder="Type your Title..."
          value={messageData.title}
          onChange={handleChangeInput}
          className="w-full p-3 border rounded-lg resize-none focus:ring focus:ring-blue-200 mt-3"
        />
        <textarea
          name="message"
          placeholder="Type your body..."
          value={messageData.message}
          onChange={handleChangeInput}
          className="w-full p-3 border rounded-lg resize-none focus:ring focus:ring-blue-200 mt-3"
        />

        {/* Send Buttons */}
        <div className="flex items-center gap-3">
          {/* Send to Selected User */}
          <button
            onClick={handleSend}
            className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition mt-3 flex items-center justify-center gap-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!selectedUser || isLoading}
          >
            <FaPaperPlane size={18} /> Send
          </button>

          {/* Send to All Users - Only show when no user is selected */}
          <button
            onClick={handleSend}
            className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition mt-3 flex items-center justify-center gap-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={Boolean(selectedUser) || isLoading}
          >
            <FaPaperPlane size={18} /> Send All
          </button>

        </div>
      </motion.div>
    </div>
  );
};

export default SendMessageModal;
