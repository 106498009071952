import tmtImage from "../../../assets/assets/tmtImage.png";
import flatBar from "../../../assets/assets/FlatBar.png";
import tmtBarMobile from "../../../assets/assets/tmtBarMobile.png";
import flatBarImage from "../../../assets/assets/flatBarImage.png";
import groupImage from "../../../assets/assets/groupImage.png";
import { IComapny } from "./types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CompanySlider from "./CompanySilder";



const ProductsSection = () => {
    const navigate = useNavigate()
    const handleItemClick = (_id: string | null, name: string) => {
        const type = "category"
        if (_id) navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, { state: { id: _id } });
        else toast.warning("Something went wrong! Please try again.");
    };

    return (
        <>
            <div className="w-full bg-white mt-3 px-4 pt-3 p-2">
                <span className="text-[15px] font-bold leading-[18.15px] font-poppins">
                    We are Dealing in 3000+ Products.
                </span>
                <div className="flex justify-between gap-2 my-3 ">
                    <div
                        onClick={() => handleItemClick("66b4b0595dd4deb57006be01", "MS TMT BAR")}
                        className="w-1/2 pt-1 text-[#CFCFCF] bg-white h-[198px] shadow-sm border-[0.5px] relative rounded-[4px]
                px-2">
                        <img
                            src={tmtBarMobile}
                            alt="tmtBarMobile"
                            className="absolute w-[103px] h-[120px] bottom-0 right-[2px]"
                        />
                        <img
                            src={tmtImage}
                            alt=""
                            className="w-[145px] h-[105px] object-contain"
                        />
                    </div>
                    <div className="w-1/2 bg-white h-[198px] flex flex-col gap-2 ">
                        <div
                            onClick={() => handleItemClick("6488483fdd9a00806a2a04c5", "FlAT")}
                            className="h-1/2 shadow-sm border-[0.5px] rounded-[4px] relative">
                            <img
                                src={flatBar}
                                alt=""
                                className="w-[50px] h-[62px] object-contain absolute bottom-2 right-2"
                            />
                            <img src={flatBarImage} alt="flatBarImage" className=" h-[88px] w-[113px] object-contain absolute left-1 top-1" />
                        </div>
                        <div
                            onClick={() => handleItemClick("647c8821f40a36c8c1ac25f2", "CEMENT")}
                            className="h-1/2 shadow-sm border-[0.5px] rounded-[4px] ">
                            <img src={groupImage} alt="groupImage" className="w-full h-[69.34px] object-contain p-1" />
                            <div className=" w-full block font-poppins font-bold text-[10px] leading-[12.1px] text-center text-[#8A8A8A] mx-auto">OPC, PPC Available here</div>
                        </div>
                    </div>
                </div>

                <span className="text-[13px] font-bold leading-[15.73px] font-poppins">
                    We are working with 1000+ Customers
                </span>
            </div>
            <CompanySlider />
        </>
    );
};

export default ProductsSection;
