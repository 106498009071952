import { BiSolidCoinStack } from "react-icons/bi";
import { RiGift2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";

export const PointsHeader = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

  const styles = {
    boxShadowStyle: {
      boxShadow: "0px 0.5px 7px 0px #00000040",
    },
  };

  return (
    <div
      className=" pb-1 pt-6 sm:py-6 md:px-[30px] px-[20px] md:p-8 sm:bg-gradient-to-r from-[#A6D3DB] to-[#ddeff3] border-white border-b-[4px] sm:shadow-md">
      <div className="flex flex-col md:flex-row  justify-between items-start md:items-center gap-4 md:gap-8 ">
        <div className="flex flex-col gap-2 ">
          <div className="font-bold text-2xl leading-8 text-gray-800">
            {userDetails?.name || "Harsh Pal"}
          </div>
          <span className="font-medium text-[15px] sm:text-lg leading-6 text-gray-600">
            Grade: {userDetails?.contractorGrade?.toUpperCase()}
          </span>
        </div>
        <div className="flex justify-center flex-row gap-x-5 gap-y-2 sm:gap-8 items-center     w-full    sm:w-fit">
          {/* Available Points */}
          <div className="flex flex-col-reverse sm:flex-col gap-2 py-2 items-center  ">
            <div className="font-medium text-[9px] md:text-lg leading-6 text-gray-700">
              Available Points
            </div>
            <div className="  flex items-center gap-2 py-1 px-4 bg-gradient-to-r from-[#A6D3DBB2] to-[#5097A4B2] sm:from-white sm:to-white shadow-md sm:shadow-none border-[3px] border-white sm:border sm:border-gray-300 rounded-full min-w-[80px] sm:min-w-[130px] justify-center">
              <BiSolidCoinStack className="text-yellow-500 text-sm sm:text-lg" />
              <span className="font-semibold text-sm/6 md:text-xl text-white sm:text-gray-800">
                {userDetails?.totalReward || "0"}
              </span>
            </div>
          </div>

          {/* Redeem Points */}
          <div className="flex flex-col-reverse sm:flex-col gap-2 py-2 items-center ">
            <div className="font-medium text-[9px] md:text-lg leading-6 text-gray-700">
              Redeem Points
            </div>
            <div className="flex items-center gap-2 py-1 px-4 bg-gradient-to-r from-[#A6D3DBB2] to-[#5097A4B2] sm:from-white sm:to-white shadow-md sm:shadow-none border-[3px] border-white sm:border sm:border-gray-300 rounded-full min-w-[80px] sm:min-w-[130px] justify-center">
              <RiGift2Fill className="text-yellow-500  text-sm sm:text-lg" />
              <span className="font-semibold text-[13px] md:text-xl text-white sm:text-gray-800">
                {userDetails?.totalReward || "0"}
              </span>
            </div>
          </div>

          {/* This Month's Points */}
          <div className="flex flex-col-reverse sm:flex-col gap-2 py-2 items-center ">
            <div className="font-medium text-[9px] md:text-lg leading-6 text-gray-700">
              This Month's Points
            </div>
            <div className="flex items-center gap-2 py-1 px-4  bg-gradient-to-r from-[#A6D3DBB2] to-[#5097A4B2] sm:from-white sm:to-white shadow-md sm:shadow-none border-[3px] border-white sm:border sm:border-gray-300 rounded-full min-w-[80px] sm:min-w-[130px] justify-center">
              <BiSolidCoinStack className="text-yellow-500 text-sm sm:text-lg" />
              <span className="font-semibold text-[13px] md:text-xl text-white sm:text-gray-800">
                0
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
