import { useChangeEmailAndNameMutation } from "../../../../Redux/Api/Contractor";
import CustomModal from "../../../../utils/CustomModal";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";


interface UserInfoProps {
  isEmail: boolean;
  setIsEmail: (isEmail: boolean) => void;
  isFullName: boolean;
  setIsFullName: (isFullName: boolean) => void;
}

const ChangeUserInfo = ({ isEmail, setIsEmail, isFullName, setIsFullName }: UserInfoProps) => {
  const [changeData, setChangeData] = useState({
    email: "",
    name: "",
  });

  const [mutationData, { isLoading, error: errorData }] = useChangeEmailAndNameMutation();

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const handleEmailChange = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (changeData.email === "") {
      toast.warning("Email is required");
      return
    }
    if (!validateEmail(changeData.email)) {
      toast.warning("Invalid email format.");
      return;
    }

    try {
      await mutationData({ email: changeData.email }).unwrap();
      toast.success("Email updated successfully.");
      setIsEmail(false);
      setChangeData({ ...changeData, email: "" });
    } catch (error) {
      toast.error(errorData?.data?.message || "Failed to update email.");
    }
  };


  const handleNameChange = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!changeData.name.trim()) {
      toast.warning("FullName is required");
      return;
    }

    try {
      await mutationData({ name: changeData.name }).unwrap();
      toast.success("FullName updated successfully.");
      setIsFullName(false);
      setChangeData({ ...changeData, name: "" });
    } catch (error) {
      toast.error(errorData?.data?.message || "Failed to update name.");
    }
  };



  return (
    <div>
      {/* Change Email Popup */}
      <CustomModal isOpen={isEmail} title="Change Email" onClose={() => setIsEmail(false)} width="md:max-w-[280px]">
        <form onSubmit={handleEmailChange} className="flex flex-col gap-4 items-center">
          <label className="text-xs font-semibold">Email Address <span className="text-red-500">*</span></label>
          <input
            type="text"
            value={changeData.email}
            onChange={(e) => setChangeData({ ...changeData, email: e.target.value })}
            className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-2 outline-none transition text-xs 2xl:text-sm"
            placeholder="Enter New Email..."
          />
          <button
            type="submit"
            className="px-4 py-2 text-white bg-[#5097A4] rounded-lg hover:bg-[#47848e] transition text-xs 2xl:text-sm "
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Change Email"}
          </button>
        </form>
      </CustomModal>

      {/* Change Full Name Popup */}
      <CustomModal isOpen={isFullName} title="Change Full Name" onClose={() => setIsFullName(false)} width="md:max-w-[280px]">
        <form onSubmit={handleNameChange} className="flex flex-col gap-4 items-center">
          <label className="text-xs 2xl:text-sm font-semibold">Full Name <span className="text-red-500">*</span></label>
          <input
            type="text"
            value={changeData.name}
            onChange={(e) => setChangeData({ ...changeData, name: e.target.value })}
            className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-2 outline-none transition text-xs 2xl:text-sm"
            placeholder="Enter New Name..."
          />
          <button
            type="submit"
            className="px-4 py-2 text-white bg-[#5097A4] rounded-lg hover:bg-[#47848e] transition text-xs 2xl:text-sm"
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Change Name"}
          </button>
        </form>
      </CustomModal>
    </div>
  );
};

export default ChangeUserInfo;
