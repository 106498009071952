import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdArrowForwardIos } from "react-icons/md";
import { useEffect, useRef } from "react";

interface iCategoryData {
    imageCarouselDetails: any;
    companyCount: number;
    id: string;
    image: string;
    itemCount: number;
    name: string;
}

const TopCategoriesScroll = () => {
    const navigate = useNavigate()
    const allCategories = useSelector((state: RootState) => state.values.allCategories);
    const isLoading = !allCategories || allCategories.length === 0;
    const skeletonCards = Array(11).fill(null);

    const handleItemClick = (_id: string | null, name: string) => {
        const type = "category"
        if (_id) navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, { state: { id: _id } });
        else toast.warning("Something went wrong! Please try again.");
    };

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = 0;
        }
    }, []);

    return (
        <div
            ref={containerRef}
            className="w-full h-[84px] pt-1 bg-white overflow-x-auto flex items-center gap-[2px] no-scrollbar px-2" >
            {isLoading
                ? skeletonCards.map((_, index) => (
                    <div key={index} className="h-[53px] ml-1 min-w-[70px] flex flex-col items-center animate-pulse">
                        <div className="w-full h-[41px] bg-gray-300 rounded-md"></div>
                        <div className="w-[40px] h-[8px] bg-gray-300 mt-[2px] rounded"></div>
                    </div>
                ))
                : allCategories.slice(0, 16).map((category: iCategoryData) => (
                    <div key={category.id} className="h-[74px]  min-w-[81px]  overflow-hidden flex flex-col gap-1 items-center cursor-pointer"
                        onClick={() => handleItemClick(category?.id, category?.name)}
                    >
                        <img
                            src={
                                category.imageCarouselDetails?.length > 0 &&
                                    category.imageCarouselDetails[0]?.image
                                    ? category.imageCarouselDetails[0].image
                                    : ""
                            }
                            alt={category.name}
                            className="w-full h-[50.75px] pt-[1px] object-contain"
                        />
                        <span className="text-[9px] font-medium text-black text-nowrap">
                            {category.name.length > 11 ? `${category.name.substring(0, 11)}...` : category.name}
                        </span>

                    </div>
                ))}
            <Link to={"/categories"} className=" h-[53px] w-[53px] min-w-[53px] rounded-full border border-[#2D99A5] flex flex-col items-center justify-center gap-0.5">
                <MdArrowForwardIos className="text-white bg-[#B1D4D8] text-sm rounded-full p-[2px]" />
                <span className="font-poppins text-[7px] text-[#2E2E2E] font-medium">SEE MORE...</span>
            </Link>

        </div>
    );
};

export default TopCategoriesScroll;
