import rewardCardShape from "../../../assets/profile/rewards/rewardCardShape.png";
import { GiTwoCoins } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { RootState } from '../../../Redux/store';
import { useSelector } from 'react-redux';

type CardProps = {
    _id: string;
    name: string;
    image: string;
    rewardValue: number;
    claimPendingStatus: boolean;
    openPopup: (_id: string) => void;
};

const ProfileRewardCard = ({ _id, name, image, claimPendingStatus, rewardValue, openPopup }: CardProps) => {
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

    const showToastWarning = (message: string) => {
        toast.warn(message, {
            className: "bg-white text-[#5097A4]",
            autoClose: 2000,
        });
    };

    const boxShadowStyle = {
        boxShadow: "0px 0.5px 7px 0px #00000040",
    };

    return (
        <div className=" w-full mx-auto  max-w-[190px]  sm:max-w-[260px] h-[230px] sm:h-[370px] border-[3px] border-white rounded-xl bg-[#A6D3DB] transform transition-transform duration-300 sm:hover:-translate-y-3"
            style={boxShadowStyle}
        >
            <div className="h-[123px] sm:h-1/2 relative flex justify-center items-end w-full overflow-hidden">
                <img
                    src={image}
                    alt="reward"
                    className="mt-auto z-[-1] object-contain w-full h-full pb-[1px"
                />
                <div
                    className="h-[45px] sm:h-[70px] w-full absolute bottom-0 z-20"
                    style={{
                        background:
                            "linear-gradient(180deg, rgba(166, 211, 219, 0) 0%, #A6D3DB 95%)",
                    }}
                ></div>
            </div>

            <div className="sm:h-1/2 relative overflow-hidden">
                <div
                    className=" h-[19px] sm:h-[32px] flex justify-center items-center text-white absolute right-0 min-w-[130px] sm:min-w-[220px] pl-2 uppercase text-[10px] sm:text-[18px] font-medium"
                    style={{
                        backgroundImage: `url(${rewardCardShape})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {name}
                </div>
                <div className="text-[26px] sm:text-[30px] font-semibold pt-6 sm:pt-14 flex justify-center items-center mx-auto text-white">
                    {rewardValue}
                    <GiTwoCoins className="text-yellow-500" />
                </div>
                <div className="flex justify-center w-full mt-1 sm:mt-5 px-4">
                    {claimPendingStatus ? (
                        <button
                            onClick={() =>
                                showToastWarning(`Your claim for ${name} is pending. Kindly wait!`)
                            }
                            className="px-3 py-1 text-[11px] sm:text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-red-600 duration-300 w-full"
                        >
                            Claim Requested
                        </button>
                    ) : userDetails ? (
                        <button
                            onClick={() => openPopup(_id)}
                            className="px-3 py-1 text-[11px] sm:text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-yellow-500 duration-300 w-full"
                        >
                            Claim
                        </button>
                    ) : (
                        <button
                            className="px-3 py-1 text-[11px] sm:text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-[#5097A4] duration-300 w-full"
                        >
                            Claim Now
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileRewardCard;
