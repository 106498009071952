import { useNavigate } from "react-router-dom";
import { MobileCardProps } from "../screens/Components/types";

const ProductCard = ({ data }: MobileCardProps) => {
  const navigate = useNavigate();
  const totalPrice = data?.finalPrice;
  const unit = data?.unit;

  const handleWhatsappClick = () => {
    const phoneNumber = "919258294692";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  const { lowFinalPrice, highFinalPrice } = data?.highLowPrice || {};
  const priceRange =
    lowFinalPrice !== undefined && highFinalPrice !== undefined
      ? `₹${Number(lowFinalPrice).toFixed(2)} - ₹${Number(highFinalPrice).toFixed(2)}/${unit}/`
      : null;

  const handleProductClick = (id: string | null, name: string | null) => {
    navigate(`/product/${id}/${name}`, { state: { id } });
  };
  return (
    <>
      <div onClick={() => { handleProductClick(data?.id, data?.productName) }} className="hidden sm:flex w-[14rem] rounded-lg overflow-hidden transform transition-transform hover:scale-105 flex-col border shadow-md cursor-pointer">
        <div className="w-full  h-40 p-2 overflow-hidden  rounded-lg">
          <img
            src={data?.images}
            alt={data?.productName}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="p-2 space-y-2 ">
          <span className="font-bold text-xs tracking-[0.1px] text-slate-800 truncate block  ">
            {data?.productName?.length > 30 ? `${data?.productName.slice(0, 30)}...` : data?.productName}
          </span>
          <div className="text-xs text-green-500 text-center mt-1 font-bold">
            {priceRange}
          </div>
          <div className="text-xs text-gray-500 text-center mt-1 font-bold">
            Item-Code : {data?.itemCode}
          </div>
            <button className="mt-2 w-full text-white text-xs font-semibold bg-[#0F172B] rounded-md py-1.5 transition-all duration-300"
            onClick={(e) => {
              e.stopPropagation();
              handleWhatsappClick();
            }}
            >
            Purchase Now
            </button>
        </div>
      </div>
      {/* <div className=" hidden sm:flex w-[16rem] h-[348px] bg-white border border-[#ECF0F0] md:border-[#ECF0F0] rounded-lg hover:shadow-[#5097A4] hover:shadow-lg p-[12px]  flex-col">
        <div className="flex-grow flex flex-col">
          <div className="flex w-[232px] h-[144px] justify-center group overflow-hidden mx-auto">
            <div
              onClick={() =>
                navigate(`/product/${data?.productName}`, {
                  state: { id: data?.id },
                })
              }>
              <img
                src={data?.images}
                alt={data?.productName}
                className="relative md:h-[14rem] h-[11.1rem] w-[17rem] py-1 group-hover:scale-110 transition-transform duration-300"
              />
            </div>
          </div>

          <div className="flex-grow flex flex-col justify-between">
            <div className="ml-2">
              <div
                className="font-inter text-[#111827] text-[18px] leading-[28px] my-1 overflow-hidden font-bold cursor-pointer"
                onClick={() =>
                  navigate(`/product/${data?.productName}`, {
                    state: { id: data?.id },
                  })
                }>
                {data && data?.productName
                  ? data?.productName
                    ?.split(" ")
                    .slice(
                      0,
                      window.innerWidth >= 768 ? 8 : 5
                    )
                    .join(" ")
                  : ""}
              </div>

              <p className="font-bold font-inter text-gray-500 md:text-xs text-[12px] md:leading-6 leading-4 flex gap-[7px]">
                {priceRange}
                <span className="text-blue-400">
                  {totalPrice
                    ? "(Negotiable)"
                    : "(Prices are Negotiable)"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className=" block  sm:hidden w-full mx-4">
        <div className="relative flex w-full items-center overflow-hidden rounded-lg bg-white p-2 shadow hover:shadow-md border cursor-pointer">
          <div
            onClick={() =>
              navigate(`/product/${data?.productName}`, {
                state: { id: data?.id },
              })
            }
            className="shrink-0 h-28 w-28 rounded-lg bg-gray-100 border overflow-hidden  flex items-center justify-center">
            <img
              src={data?.images}
              alt=""
              className="object-cover object-center w-full h-full"
            />
          </div>
          <div className="ml-3 w-full space-y-2">
            <p
              onClick={() =>
                navigate(`/product/${data?.productName}`, {
                  state: { id: data?.id },
                })
              }
              className="font-medium text-gray-800">
              {data?.productName?.length > 15
                ? `${data?.productName
                  .substring(0, 15)
                  .trim()}...`
                : data?.productName}
            </p>

            <p className="text-sm text-gray-600">
              {totalPrice
                ? `₹${totalPrice.toFixed(2)}/${unit}`
                : ""}{" "}
              <span className="text-blue-400">
                {totalPrice
                  ? "(Negotiable)"
                  : "(Prices are Negotiable)"}
              </span>
            </p>
            <button
              onClick={handleWhatsappClick}
              className="flex items-center justify-center px-2 py-1  font-medium text-cente transition-all duration-300 group text-white border bg-green-400 gap-1 group text-xs  ml-auto hover:bg-green-400 rounded-md hover:text-white ">
              Contact Us
              <RiWhatsappFill
                fontSize={"0.80rem"}
                className="text-white "
              />
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ProductCard;
