
interface Props {
    text?: string
}
const ProfileNoData = ({ text }: Props) => {
    return (
        <div className="flex items-center justify-center h-[200px] sm:h-[400px] col-span-2">
            <div
                className="p-4 mb-4 text-xs sm:text-sm text-gray-500 rounded-xl  bg-gray-50 border border-gray-400 font-normal w-fit mt-10 mx-auto"
                role="alert">
                <span className="font-semibold mr-2">
                    {text || "No Data available !"}
                </span>
            </div>
        </div>
    )
}

export default ProfileNoData