import React, { useEffect, useState } from "react";
import { motion, useAnimation, AnimationControls } from "framer-motion";
import { useInView } from "react-intersection-observer";
import turnoverTmt from "../../assets/turnoverTmt.png";
import turnoverHike from "../../assets/turnoverHike.png";
import turnoverOrder from "../../assets/turnoverOrder.png";
import companyDemo from "../../assets/assets/company_demo.avif";
import bestChoice from "../../assets/bestChoice.png";
import { useGetTurnOverDataQuery } from "../../Redux/Api/Order";

const TurnOver: React.FC = () => {
  const { data } = useGetTurnOverDataQuery();

  const formatIndianNumber = (num: number): string => {
    if (num >= 10000000) {
      return `${(num / 10000000).toFixed(2)} Cr`;
    } else if (num >= 100000) {
      return `${(num / 100000).toFixed(2)} Lakh`;
    } else {
      return `${num.toFixed(2)}`;
    }
  };

  const [yearTurnover, setYearTurnover] = useState<number>(0);
  const [monthTurnover, setMonthTurnover] = useState<number>(0);
  const [weekTurnover, setWeekTurnover] = useState<number>(0);

  const controls: AnimationControls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animateValue = (
    start: number,
    end: number,
    duration: number,
    setValue: React.Dispatch<React.SetStateAction<number>>
  ) => {
    let startTime: number | null = null;

    const step = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const currentValue = Math.floor(progress * (end - start) + start);
      setValue(currentValue);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    if (data && inView) {
      const yearTurnoverAmount =
        data.orderDetails[0]?.YearTurnOver[0]?.totalAmount || 0;
      const monthTurnoverAmount =
        data.orderDetails[0]?.monthTurnOver[0]?.totalAmount || 0;
      const weekTurnoverAmount =
        data.orderDetails[0]?.weekTurnOver[0]?.totalAmount || 0;

      animateValue(0, yearTurnoverAmount, 2000, setYearTurnover);
      animateValue(0, monthTurnoverAmount, 2000, setMonthTurnover);
      animateValue(0, weekTurnoverAmount, 2000, setWeekTurnover);
    }
  }, [data, inView]);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        transition: { duration: 1 },
      });
    } else {
      controls.start({
        opacity: 0,
      });
    }
  }, [inView, controls]);

  // console.log(
  //   data?.orderDetails[0]?.lastDayMajorproduct[0]?.image,
  //   "image data"
  // );

  return (
    <section ref={ref}>
      <motion.div

        className=" p-5 rounded-[20px] lg:my-5 font-inter h-[34rem] sm:h-[38.0625rem]  bg-gradient-to-b from-[#cdd8e2] to-[#fdfdfe]"

        initial={{ opacity: 0 }}
        animate={controls}
      >
        <div className="">
          {/* first div */}

          <div className="flex border-b-2 lg:w-[85%] lg:m-auto md:border-none items-center  w-full  justify-between">
            <div className=" flex flex-col md:flex-row md:justify-between  md:space-y-0 py-6 w-full space-y-3">

              <div className="flex gap-3  items-center">
                <span className="lg:w-24 lg:h-24 h-12 w-12">
                  <img src={turnoverHike} alt="turnOverHike" />
                </span>
                <span className="flex flex-col text-center ">
                  <span className="font-bold text-xl md:text-2xl ">
                    {formatIndianNumber(yearTurnover)}
                  </span>
                  <span className="text-sm">Year</span>
                  <span className="text-sm">Turnover</span>
                </span>
              </div>
              <div className="flex gap-3 items-center">
                <span className="lg:w-24 lg:h-24 h-12 w-12">
                  <img src={turnoverHike} alt="turnOverHike" />
                </span>
                <span className="flex flex-col text-center">
                  <span className="font-bold text-xl md:text-2xl">
                    {formatIndianNumber(monthTurnover)}
                  </span>
                  <span className="text-sm">Month</span>
                  <span className="text-sm">Turnover</span>
                </span>
              </div>
              <div className="flex gap-3 items-center">
                <span className="lg:w-24 lg:h-24 h-12 w-12">
                  <img src={turnoverHike} alt="turnOverHike" />
                </span>
                <span className="flex flex-col text-center">
                  <span className="font-bold inline text-xl md:text-2xl">
                    {formatIndianNumber(weekTurnover)}
                  </span>
                  <span className="text-sm">Weekly</span>
                  <span className="text-sm">Turnover</span>
                </span>
              </div>
            </div>
            <div className="md:hidden">
              <motion.span
                className="h-[8rem] w-[10rem] block" 
                animate={{
                  rotate: [0, 360],
                }}
                transition={{
                  repeat: Infinity,
                  repeatDelay: 1,
                  duration: 5,
                  ease: "linear",
                }}
              >
                <img
                  src={bestChoice}
                  alt="bestChoice"
                  className="h-full w-full object-contain"
                />
              </motion.span>
            </div>
          </div>

          {/* <div className="absolute sm:top-[9rem] top-10 lg:top-[19rem] right-4 lg:-right-[30px] sm:right-[2.25rem]">
            <span className="h-[30px] w-[30px]">
              <img
                src={bestChoice}
                className="h-[11.25rem] w-[11.25rem] sm:h-[13.25rem] sm:w-[13.25rem] lg:h-[20rem] lg:w-[20rem]"
                alt="bestChoice"
              />
            </span>
          </div> */}

          {/* second div */}

          <div className="flex border-b-2 lg:w-[80%] lg:m-auto items-center justify-between md:border-none">

            <div className="flex gap-3 py-3">
              <span>
                <img
                  src={turnoverOrder}
                  className="w-[4rem]"
                  alt="turnOverOrder"
                />
              </span>
              <span className="flex flex-col">
                <span className="font-bold text-xl lg:text-2xl">
                  {data?.orderDetails[0]?.lastThirtyMinuteOrderPunchIn[0]
                    ?.count ?? 31}
                  + Orders
                </span>
                <span className="text-sm">Last 30 minutes punched Orders</span>
              </span>
            </div>
            <div className="hidden md:block">
              <motion.span
                className="h-[8.25rem] w-[8.25rem] md:h-[13.25rem] md:w-[13.25rem] xl:h-[16.25rem] xl:w-[16.25rem] block"
                initial={{ x: "-100vw", rotate: 0 }} 
                animate={{
                  x: ["-100vw", "0", "10vw", "0"],
                  rotate: [0, 10, -10, 0],
                }}
                transition={{
                  duration: 3, 
                  ease: [0.6, 0.05, -0.01, 0.9],
                  repeat: Infinity,
                  repeatDelay: 3, 
                }}
              >
                <img
                  src={bestChoice}
                  alt="bestChoice"
                  className="w-full h-full"
                />
              </motion.span>
            </div>
          </div>

          <div className="flex border-b-2 md:border-none lg:w-[80%] lg:m-auto py-3 items-center justify-between">
            <div className="md:block hidden">
              <img
                src={turnoverTmt}
                className="h-[15.25rem] w-[20.25rem] sm:h-[13.25rem] sm:w-[18.25rem] lg:h-[12.25rem] lg:w-full"
                alt="tmt"
              />
            </div>
            <div className="flex gap-3 items-center  py-2">
              <motion.span
                className="h-16 w-16 overflow-hidden"
                animate={{ rotateY: 360 }}
                transition={{
                  repeat: Infinity,
                  duration: 2,
                  ease: "linear",
                }}
              >
                <img
                  src={
                    data?.orderDetails[0]?.lastDayMajorproduct[0]?.image
                      ? data?.orderDetails[0]?.lastDayMajorproduct[0]?.image[0]
                      : companyDemo
                  }
                  alt="Company Name"
                  className="hexagon object-cover w-full h-full"
                />
              </motion.span>

              <motion.span
                className="flex flex-col"
                animate={{ opacity: [1, 0, 1] }}
                transition={{
                  repeat: Infinity,
                  duration: 1,
                  ease: "easeInOut",
                }}
              >
                <span className="font-bold  text-sm lg:text-2xl">
                  {data?.orderDetails[0]?.lastDayMajorproduct[0]?.productName}
                </span>
                <span className="w-[60%] text-sm text-center">
                  Majorly brought item of today
                </span>
              </motion.span>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default TurnOver;