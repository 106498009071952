import { RxCrossCircled } from "react-icons/rx";
import mobileMenuShape from "../../assets/assets/mobileMenuShape.png";
import DefaultImage from "../../assets/profile/DefualtProfile.png";
import {
    BiSolidBasket,
    BiSolidHome,
    BiSolidMedal,
} from "react-icons/bi";
import { RiUserSharedFill } from "react-icons/ri";
import { IoMdInformationCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import MobileCategoryFilters from "./MobileCategoryFilters";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import AuthButton from "../../common/AuthButtons";
import { useNavigate } from "react-router-dom";

type MobileSideMenuProps = {
    isOpenMenu: boolean;
    setIsOpenMenu: (isOpen: boolean) => void;
};

const MobileSideMenu = ({ isOpenMenu, setIsOpenMenu }: MobileSideMenuProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

    useEffect(() => {
        if (isOpenMenu) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [isOpenMenu]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640 && isOpenMenu) {
                setIsOpenMenu(false);
                setIsDropdownOpen(false)
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isOpenMenu, setIsOpenMenu]);

    const navigatePath = (path: string): void => {
        if (path) {
            navigate(path);
            setIsOpenMenu(false);
            setIsDropdownOpen(false)
        }
    };

    const links = [
        { name: "Home", path: "/", icon: <BiSolidHome className="w-5 h-5 text-gray-900" /> },
        { name: "Items", path: "/items", icon: <BiSolidBasket className="w-5 h-5 text-gray-900" /> },
        { name: "About Us", path: "/aboutus", icon: <IoMdInformationCircle className="w-5 h-5 text-gray-900" /> },
        { name: "Contact Us", path: "/contactus", icon: <RiUserSharedFill className="w-5 h-5 text-gray-900" /> },
    ];

    // if (userDetails?.name) {
    //     links.push({
    //         name: "Assigned Rewards",
    //         path: "/assignrewards",
    //         icon: <BiSolidMedal className="w-5 h-5 text-gray-900" />,
    //     });
    // }


    const handleClose = (): void => {
        setIsOpenMenu(false);
        setIsDropdownOpen(false)
    }
    const handleProfileNavigate = (): void => {
        navigate("/profile")
        handleClose()
    }

    return (
        <>
            {/* Overlay */}
            {isOpenMenu && (
                <div
                    className="fixed inset-0 bg-black/50 z-40"
                    onClick={() => handleClose()}
                ></div>
            )}

            {/* Side Menu */}
            <aside
                className={`fixed top-0 left-0 z-50 w-[285px] h-screen font-poppins bg-white  text-black flex flex-col transition-transform duration-300 ${isOpenMenu ? "translate-x-0" : "-translate-x-full"
                    }`}
            >
                {/* Close Button */}
                <button
                    className="absolute top-3 right-3 z-10 text-white"
                    onClick={() => handleClose()}
                >
                    <RxCrossCircled fontSize={"1.40rem"} />
                </button>

                {/* Shape Image */}
                <img
                    src={mobileMenuShape}
                    alt="shape"
                    className="w-full absolute top-0 right-0 z-1 h-[173px] min-h-[173px]"
                />

                {/* Profile Section */}
                <div onClick={() => handleProfileNavigate()} className="h-[173px] w-full relative flex justify-center items-end min-h-[173px]">
                    <img
                        src={DefaultImage}
                        alt="profile-pic"
                        className="w-[113px] h-[113px] rounded-full object-cover object-center border-[5px] bg-white border-white shadow-sm absolute left-4 right-4 bottom-1 mx-auto"
                    />
                    {/* <img
                        src={userDetails?.avatar || DefaultImage}
                        alt="profile-pic"
                        className="w-[113px] h-[113px] rounded-full object-cover object-center border-[5px] bg-white border-white shadow-sm absolute left-4 right-4 bottom-1 mx-auto"
                    /> */}
                </div>

                {/* User Name */}
                <div
                    className="w-full text-center h-[40px]">
                    <h2 onClick={() => handleProfileNavigate()} className="font-[600] leading-[22px] text-[16px]">
                        {userDetails?.name || "Guest"}
                    </h2>
                </div>

                {/* Menu Content */}
                <div
                    className="flex flex-col justify-start overflow-y-auto"
                    style={{ height: "calc(100vh - 213px)" }}
                >
                    {/* Navigation Links */}
                    <div className="px-3 pb-4 overflow-y-auto">
                        <ul className="space-y-2 font-medium">
                            {/* Dropdown for Categories */}

                            <MobileCategoryFilters isTrue={isDropdownOpen} setIsTrue={setIsDropdownOpen} setIsMenu={setIsOpenMenu} isMenu={isOpenMenu} />
                            {/* Navigation Items */}
                            {links.map((link) => (
                                <li key={link.path}>
                                    <button
                                        onClick={() => navigatePath(link.path)}
                                        className="flex items-center p-2 text-gray-900 rounded-lg font-medium"
                                    >
                                        {link.icon}
                                        <span className="flex-1 ms-3 whitespace-nowrap">{link.name}</span>
                                    </button>
                                </li>
                            ))}

                        </ul>
                    </div>

                    {/* Logout Login Button */}
                    <AuthButton user={userDetails?.name} setIsMenu={setIsOpenMenu} />
                </div>
            </aside>
        </>
    );
};

export default MobileSideMenu;
