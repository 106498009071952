import React, { useEffect, useState } from "react";
import { IoIosLock } from "react-icons/io";
import { useLoginMutation } from "../../Redux/Api/Signup";
import { useDispatch } from "react-redux";
import { LoginResponse, userRole } from "../../Redux/reducer/LoginReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profile from "../../assets/ProfileIcon.svg";
import { IoMdEyeOff } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import { useSaveFcmTokenMutation } from "../../Redux/Api/Notifications";
import { requestFCMToken } from "../../utils/firebaseUtils";

export const NewLogin = ({ handleClick, signUp }: any) => {
  const navigate = useNavigate();
  const [
    mutation,
    { data: signinResponse, isSuccess, error: loginErrorResponse },
  ] = useLoginMutation();
  const [loginData, setLoginData] = useState({
    emailAndPhoneNumber: "",
    password: "",
  });
  const [fcmToken, setFcmToken] = useState<string | null>()
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(true);
  
  const [saveFcmToken] = useSaveFcmTokenMutation();
  //  Fetch FcmToekn  

  useEffect(() => {
      const fetchToken = async () => {
          try {
              const token: any | null = await requestFCMToken();
              setFcmToken(token);
              // console.log("FCM Token:", token);
          } catch (error) {
              console.error("Error fetching FCM Token:", error);
          }
      };
      fetchToken();
  }, [])
 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const onSignIn = () => {
    mutation(loginData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {

    if (isSuccess && signinResponse) {
      const token = signinResponse?.token;

      if (token) {
        localStorage.setItem("jwt", token);
      } else {
        console.log("No token found in the response body.");
      }
      dispatch(LoginResponse(signinResponse?.contractorData));
      const role = signinResponse?.contractorData?.role;
      const isVerified = signinResponse?.contractorData?.isVerified;
      const isDeleted = signinResponse?.contractorData?.isDeleted;
      // ✅ Save FCM Token after successful login
      if (fcmToken && signinResponse?.contractorData?._id) {
        const userId = signinResponse?.contractorData?._id
        saveFcmToken({ userId, token: fcmToken })
    }
      if (role === "contractor" && isDeleted) {
        navigate("/deleted");
      }
      // else if (role === "contractor" && !isVerified) {
      //   navigate("/unauthorized");
      // }
      else if (role === "admin" && isVerified) {
        navigate("/admin");
      } else {
        navigate("/");
      }
    }

  }, [isSuccess, signinResponse, dispatch, navigate]);

  useEffect(() => {
    if (loginErrorResponse) {
      toast.error(loginErrorResponse?.data?.message, {
        className: "bg-white text-[#5097A4]",
        autoClose: 1500,
      });
    }
  }, [loginErrorResponse]);


  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(false);
    }, 100);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.tagName === 'INPUT' && event.key === "Enter") {
      onSignIn();
    }
  };

  // Reset login form when signUp is false
  useEffect(() => {
    if (!signUp) {
      setLoginData({
        emailAndPhoneNumber: "",
        password: "",
      });
    }
  }, [signUp])

  const location = useLocation()
  useEffect(() => {
    if (!location.pathname.startsWith("/forgot-password/verify-otp")) {
      localStorage.removeItem("otpTimerStart2");
    }
  }, [location.pathname]);
  return (
    <div className="  sm:absolute rounded-[20px] mt-[30%] w-[80%] sm:w-[50%] lg:w-[30%] m-auto sm:mt-0  top-[14rem]  animate-signin  left-[7rem] animate-signin">
      <div className="bg-gray-100 w-full  justify-center sm:hidden flex gap-9 border rounded-t-[20px] pt-[1rem] font-[600] text-[24px] leading-[29px] text-[#5097A4]">
        <span
          className={`cursor-pointer pb-2 ${signUp ? "border-b-2 border-[#5097A4]" : "opacity-40"
            }`}
          onClick={() => handleClick()}
        >
          Sign In
        </span>
        <span
          className={`cursor-pointer pb-2 ${!signUp ? "border-b-2 border-[#5097A4]" : "opacity-40"
            }`}
          onClick={() => handleClick()}
        >
          Sign Up
        </span>
      </div>

      <div
        className={`flex flex-col px-[25px] py-[26px] border rounded-b-[20px] sm:rounded-[10px] sm:rounded-b-[10px] bg-white w-full transition-all duration-700 ease-in-out ${isExpanded ? "max-h-[500px] gap-[150px] " : "max-h-[400px] gap-[25px]"
          } overflow-hidden`}
      >
        <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
          <img src={profile} className="w-[20px] h-[20px]" alt="profile" />
          <input
            type="text"
            name="emailAndPhoneNumber"
            placeholder="Email or Phone Number"
            className="w-full h-full border-none outline-none text-[#A3AAB5]"
            value={loginData.emailAndPhoneNumber.toLowerCase()}
            onChange={handleChange}
          />
        </div>

        <div className="w-full border h-[40px] flex p-2 rounded-[4px] items-center gap-[10px]">
          <IoIosLock className="w-[20px] h-[20px] text-[#5097A4]" />
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            className="w-full h-full border-none outline-none text-[#A3AAB5]"
            value={loginData.password}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />

          <div onClick={togglePasswordVisibility} className="cursor-pointer">
            {showPassword ? (
              <FaEye className="w-[20px] h-[20px] text-[#5097A4]" />
            ) : (
              <IoMdEyeOff className="w-[20px] h-[20px] text-[#5097A4]" />
            )}
          </div>
        </div>
        <div className="flex items-center justify-end">

          <Link to="/forgot-password/request-otp" className="text-sm">
            <span className="font-medium text-blue-600 hover:text-blue-500">
              Forgot your password?
            </span>
          </Link>
        </div>

        <div className="flex flex-col gap-[1rem]">
          <div
            className="font-[600] text-[24px] leading-[29px] border flex justify-center items-center text-white bg-[#5097A4] py-[6px] rounded-[8px] cursor-pointer"
            onClick={onSignIn}

          >
            Sign In
          </div>

          <div
            className="flex justify-center items-center text-red-500 cursor-pointer"
            onClick={() => handleClick()}
          >
            If you do not have an account
          </div>
        </div>
      </div>
    </div>
  );
};
