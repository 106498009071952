import { LuMenu } from "react-icons/lu";
import logo from "../../assets/assets/chawlaMobileLogo.png";
import { FaRegUser } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import Profile from "../../assets/profile/DefualtProfile.png";
interface mobileProps {
    toggleMenu: () => void
}
const MobileNavbar = ({ toggleMenu }: mobileProps) => {
    const navigate = useNavigate()
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    return (
        <div className=" navbar flex flex-col sm:hidden w-full bg-turkishBlue-default p-2 gap-2 font-poppins">
            <div className="flex justify-between items-center">
                <div aria-label="Menu" className="flex items-center gap-2 justify-between">
                   {
                     !userDetails  &&  <LuMenu id="mobileSideBar" onClick={toggleMenu} className="text-white cursor-pointer" fontSize={"1.80rem"} />
                   }
                    <img
                        loading="lazy"
                        src={logo}
                        alt="logo"
                        className="w-[136px] object-contain h-[31px] cursor-pointer"
                        onClick={() => navigate("/")}
                    />
                </div>
                {/* {
                    userDetails &&
                    <div className="mr-3 relative cursor-pointer ml-auto" onClick={() => navigate("/cart")}>
                        <BsFillHandbagFill className="text-white " fontSize={"1.60rem"} />
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                            12
                        </span>

                    </div>
                } */}
                {
                    userDetails ?

                    <LuMenu id="mobileSideBar" onClick={toggleMenu} className="text-white cursor-pointer" fontSize={"1.80rem"} />
                        : 
                        <Link
                            to={"/signup"}
                            aria-label="Login"
                            className="flex text-white items-center gap-2"
                        >
                            <span className="font-medium text-[11px]">{"Login >"}</span>
                            <FaRegUser fontSize={"1.80rem"} />
                        </Link>
                }

            </div>
            <div className="relative h-[42px] rounded-[4px] overflow-hidden w-full bg-white">
                <input
                    type="text"
                    className="w-full h-full bg-white pl-3 placeholder:text-slate-400 text-slate-700 text-sm transition duration-300 ease rounded-[4px] focus:outline-none focus:ring-2 focus:ring-turkishBlue-light"
                    placeholder="Search.."
                    aria-label="Search"
                    onClick={() => navigate("/searchpage")}
                />
                <button
                    className="absolute right-0 top-0 rounded bg-[#FF9300] h-full w-[45px] border border-transparent text-center text-sm flex justify-center items-center"
                    type="button"
                    aria-label="Search Button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default MobileNavbar;
// import { LuMenu } from "react-icons/lu";
// import logo from "../../assets/assets/chawlaMobileLogo.png";
// import { FaRegUser } from "react-icons/fa";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../../Redux/store";
// import Profile from "../../assets/profile/DefualtProfile.png";
// interface mobileProps {
//     toggleMenu: () => void
// }
// const MobileNavbar = ({ toggleMenu }: mobileProps) => {
//     const navigate = useNavigate()
//     const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
//     return (
//         <div className=" navbar flex flex-col sm:hidden w-full bg-turkishBlue-default p-2 gap-2 font-poppins">
//             <div className="flex justify-between items-center">
//                 <div aria-label="Menu" className="flex items-center gap-2 justify-between">
//                     <LuMenu id="mobileSideBar" onClick={toggleMenu} className="text-white cursor-pointer" fontSize={"1.80rem"} />
//                     <img
//                         loading="lazy"
//                         src={logo}
//                         alt="logo"
//                         className="w-[136px] object-contain h-[31px] cursor-pointer"
//                         onClick={() => navigate("/")}
//                     />
//                 </div>
//                 {/* {
//                     userDetails &&
//                     <div className="mr-3 relative cursor-pointer ml-auto" onClick={() => navigate("/cart")}>
//                         <BsFillHandbagFill className="text-white " fontSize={"1.60rem"} />
//                         <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
//                             12
//                         </span>

//                     </div>
//                 } */}
//                 {
//                     userDetails ?
//                         // <button
//                         //     aria-label="Login"
//                         //     className="flex text-white items-center gap-2"
//                         //     onClick={() => navigate("/profile")}
//                         // >
//                         //     <img
//                         //         className="w-[31px] h-[31px] bg-white  rounded-full object-cover object-center  ring-gray-300 dark:ring-gray-500 ring-2 "
//                         //         src={userDetails?.avatar || Profile}
//                         //         alt="profile"
//                         //     />

//                         // </button>
//                         " "

//                         : <Link
//                             to={"/signup"}
//                             aria-label="Login"
//                             className="flex text-white items-center gap-2"
//                         >
//                             <span className="font-medium text-[11px]">{"Login >"}</span>
//                             <FaRegUser fontSize={"1.80rem"} />
//                         </Link>
//                 }

//             </div>
//             <div className="relative h-[42px] rounded-[4px] overflow-hidden w-full bg-white">
//                 <input
//                     type="text"
//                     className="w-full h-full bg-white pl-3 placeholder:text-slate-400 text-slate-700 text-sm transition duration-300 ease rounded-[4px] focus:outline-none focus:ring-2 focus:ring-turkishBlue-light"
//                     placeholder="Search.."
//                     aria-label="Search"
//                     onClick={() => navigate("/searchpage")}
//                 />
//                 <button
//                     className="absolute right-0 top-0 rounded bg-[#FF9300] h-full w-[45px] border border-transparent text-center text-sm flex justify-center items-center"
//                     type="button"
//                     aria-label="Search Button"
//                 >
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 16 16"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path
//                             fillRule="evenodd"
//                             d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
//                             clipRule="evenodd"
//                         />
//                     </svg>
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default MobileNavbar;
