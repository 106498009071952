import React from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/store';
import { GoBellFill, GoHomeFill } from 'react-icons/go';
import { Link, useLocation } from 'react-router-dom';

export const AdminHeader = () => {
    const userDetails = useSelector(
        (state: RootState) => state.Login.ContractorData
    );
    const location = useLocation()

    const category = location.pathname.startsWith("/admin/category")
    const categoryItem = location.pathname.startsWith("/admin/category/view") || location.pathname.startsWith("/admin/category/item")
    const company = location.pathname.startsWith("/admin/company")
    const companyItem = /^\/admin\/company\/[^\/]+$/.test(location.pathname)

    return (

        <div className="w-full bg-[#E9ECEF] py-2">
            <div className="bg-[#334155] px-4 py-3  rounded-xl mx-3">
                <div className="flex justify-between items-center">
                    {/* Left Section */}
                    <div>
                        <h4 className="text-lg font-semibold font-poppins text-white leading-6">
                            {
                                category ? "Category Management" : "Company Management"
                            }
                        </h4>
                        <div className="flex items-center gap-2 mt-1">
                            <GoHomeFill size={20} className="text-blue-400" />
                            <span className="text-sm font-inter text-gray-300">/</span>

                            {/* Conditional Rendering for Category */}
                            {category && (
                                <>
                                    <Link to="/admin/category" className="text-sm font-inter text-blue-400 hover:underline">
                                        Category
                                    </Link>
                                    {categoryItem && (
                                        <>
                                            <span className="text-sm font-inter text-gray-300">/</span>
                                            <p className="text-sm font-inter text-gray-100">View Item</p>
                                        </>
                                    )}
                                </>
                            )}

                            {/* Conditional Rendering for Company */}
                            {company && (
                                <>
                                    <Link to="/admin/company" className="text-sm font-inter text-blue-400 hover:underline">
                                        Company
                                    </Link>
                                    {companyItem && (
                                        <>
                                            <span className="text-sm font-inter text-gray-300">/</span>
                                            <p className="text-sm font-inter text-gray-100">Product</p>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="flex items-center gap-4">
                        <div className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
                            <p className="text-sm font-inter text-[#1C1D22]">{userDetails?.name}</p>
                        </div>
                        <GoBellFill size={22} className="text-red-400 cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>


    )
}
