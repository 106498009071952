import { useEffect, useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { RequestFilter } from "./components/RequestFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { showFilter } from "../../../Redux/reducer/RequestReducer";
import { useDeleteContractorMutation, useUnpublishedContractorQuery } from "../../../Redux/Api/Contractor";
import { Contractor } from "../../../Redux/Api/types";
import { setSelectedCategories } from "../../../Redux/reducer/Filter";
import * as XLSX from "xlsx";
import { MdDelete } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";

interface DeleteState {
	id: string,
	name: string,
	remark: string
}
export const ContractorsRequest = () => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState<string>("");
	const [showModal, setShowModal] = useState<boolean>(false);
	const [remarkState, setRemarkState] = useState<boolean>(false);
	const [limit, setLimit] = useState<string | number>("20");
	const [page, setPage] = useState<number>(1);

	const { data, refetch } = useUnpublishedContractorQuery({ page, limit, search });
	const [deleteContractorMutation, { isSuccess, isError, isLoading }] = useDeleteContractorMutation();


	const [selectedId, setSelectedId] = useState<string>("");
	const [deleteDataState, setDeleteDataState] = useState<DeleteState>({ id: "", name: "", remark: "" });
	const [validationMessage, setValidationMessage] = useState<string>("");

	const options = ["9", "8", "7", "All"];
	const Contractor = useSelector((state: RootState) => state.admin.Contractor);
	const filterVisible = useSelector((state: RootState) => state.request.isFilterVisible);


	const handleSelect = (option: string) => { setLimit(option === "All" ? "All" : parseInt(option)) };
	const toggleFilter = (id: string, categoryProduct: string[]) => {
		dispatch(showFilter());
		setSelectedId(id);
		dispatch(setSelectedCategories(categoryProduct));
	};

	const contractor = Contractor?.data?.pendingContractor;

	useEffect(() => {
		refetch();
	}, [page, limit, search, refetch]);

	const handleDeleteContractorWithRemark = async () => {
		if (deleteDataState.remark.trim().split(/\s+/).length < 5) {
			setValidationMessage("Input must have at least 5 words.");
			toast.warn("Remark must contain at least 5 words.")
			return;
		}

		try {
			await deleteContractorMutation({
				id: deleteDataState.id,
				remark: deleteDataState.remark,
			}).unwrap();

			refetch();
			setShowModal(false);
			setRemarkState(false);
			setDeleteDataState({
				id: "",
				name: "",
				remark: "",
			});
		} catch (error) {
			console.error("Error deleting contractor:", error);
		}
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success("Contractor deleted successfully!");
		}

		if (isError) {
			toast.error("Failed to delete contractor. Please try again.");
		}
	}, [isSuccess, isError]);

	const handleInputChange = (e: any) => {
		const value = e.target.value;
		setDeleteDataState((prev) => ({ ...prev, remark: value }));
		if (value.trim().split(/\s+/).length >= 5) {
			setValidationMessage("");
		}
	};

	const totalPages = data?.totalPages || 1;

	const prevPage = () => { if (page > 1) { setPage(page - 1) } };
	const nextPage = () => { if (page < totalPages) { setPage(page + 1) } };

	const exportToExcel = () => {
		const worksheet = XLSX.utils.json_to_sheet(data?.contractors || []);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");
		const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
		const blob = new Blob([wbout], { type: "application/octet-stream" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "contractors.xlsx";
		link.click();
		window.URL.revokeObjectURL(url);
	};

	const startItem = (page - 1) * (typeof limit === "number" ? limit : parseInt(limit)) + 1;
	const endItem = Math.min(page * (typeof limit === "number" ? limit : parseInt(limit)), contractor || 0);
	const resetAll = () => {
		setSearch("");
		setPage(1);
		setLimit("20");
	};

	return (
		<>
			<div className="w-full h-auto px-3 mt-3 pb-3 ">
				<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
					{/* Filters */}
					<div className="flex justify-between py-2 px-4">
						<div className="flex items-center gap-2">
							<input
								type="text"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className="admin-input"
								placeholder="Search.."
							/>
						</div>
						<div className="flex gap-2">
							<button
								onClick={resetAll}
								className="reset-btn">
								Reset
							</button>
							<button
								onClick={exportToExcel}
								className="excel-btn">
								Excel{" "}
								<RiFileExcel2Fill className="w-[20px] h-[20px]" />
							</button>
						</div>
					</div>
					{/* Table */}
					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative ">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
									{
										["", "Name", "Contact", "Contractor Type", "City", "Action", "Status", "Delete"].map((headerItem: string, i: number) => (
											<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
												{
													headerItem
												}
											</th>
										))
									}

								</tr>
							</thead>
							<tbody className="bg-white">
								{data?.contractors?.map(
									(i: Contractor, index: number) => (
										<tr key={index}>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer">

											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer">
												{i?.name || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.phoneNumber || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.contractorType || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.city || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<span
													className="text-blue-600 bg-blue-100  border-[1px] border-blue-600 text-xs font-medium me-2 px-2.5 py-[6px]  rounded-xl  cursor-pointer hover:text-white hover:bg-blue-600 duration-200"
													onClick={() =>
														toggleFilter(
															i?._id,
															i?.categoryProduct
														)
													}>
													Action
												</span>
											</td>
											<td className="px-6 py-1 whitespace-nowrap font-inter text-sm text-[#6E7079] flex justify-start    items-center">
												{i?.isVerified ? (
													<div className="text-white mt-2 bg-green-500 text-xs font-medium me-2 px-2.5 py-[6px] rounded-xl">
														Approved
													</div>
												) : (
													<div className="text-white mt-2 bg-orange-500 text-xs font-medium me-2 px-2.5 py-[6px] rounded-xl">
														Pending
													</div>
												)}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-xl  w-[10%] cursor-pointer text-red-600">
												<span
													onClick={() => {
														setDeleteDataState(
															(prev) => ({
																...prev,
																id: i?._id,
																name: i?.name,
															})
														);

														setShowModal(true);
													}}>
													<MdDelete />
												</span>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
					{/* Pagination */}
					<div className="flex flex-row justify-between p-3 m-1 border-t-2 ">
						<div>
							<div className="flex flex-row gap-3 ">
								<Dropdown
									options={options}
									onSelect={handleSelect}
								/>
								<p className="font-semibold">
									Showing{" "}
									{limit === "All"
										? "All"
										: `${startItem} - ${endItem || ""
										}`}{" "}
									Contractors Request
								</p>
								<p className="text-[#A6A8B1] ">
									Contractors Request
								</p>
							</div>
						</div>


						<div className="flex flex-row gap-3">
							<p className="text-[#A6A8B1]">
								{page}of {totalPages} pages
							</p>
							<div className="flex flex-row items-center gap-2 text-[#666666]">
								<span
									onClick={prevPage}
									className={`cursor-pointer ${page === 1 ? "opacity-50" : ""
										}`}>
									<FaChevronLeft size={15} />
								</span>
								<span
									onClick={nextPage}
									className={`cursor-pointer ${page === totalPages
										? "opacity-50"
										: ""
										}`}>
									<FaChevronRight size={15} />
								</span>
							</div>
						</div>
					</div>


					<AnimatePresence>

						{showModal && (
							<motion.div
								id="progress-modal"
								tabIndex={-1}
								className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.2, ease: 'easeOut' }}
							>
								<motion.div className="bg-white rounded-lg shadow-lg w-full max-w-sm px-5 py-10"
									initial={{ scale: 0.95 }}
									animate={{ scale: 1 }}
									exit={{ scale: 0.95 }}
									transition={{ duration: 0.2, ease: 'easeOut' }}


								>
									<div className="text-center space-y-5">

										{!remarkState ? (
											<h3 className="text-lg font-medium text-gray-900">
												Do you want to delete
												<br />
												<span className="text-red-600 font-semibold">
													{deleteDataState.name}?
												</span>
											</h3>
										) : (
											<div>
												<label
													htmlFor="remark"
													className="block text-sm font-medium text-gray-700"
												>
													Enter a remark
												</label>
												<input
													id="remark"
													type="text"
													className="admin-input mt-3"
													placeholder="Write a reason (min. 5 words)"
													onChange={handleInputChange}
												/>
												{validationMessage && (
													<p className="text-red-600 text-xs mt-1">{validationMessage}</p>
												)}
											</div>
										)}

										<div className="flex justify-center gap-3">
											{!remarkState ? (
												<button
													className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
													onClick={() => setRemarkState(true)}
												>
													Yes
												</button>
											) : (
												<button
													className={`px-4 py-2 text-white rounded-md ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-red-600 hover:bg-red-700"
														}`}
													onClick={handleDeleteContractorWithRemark}
													disabled={isLoading}
												>
													{isLoading ? "Processing..." : "Submit"}
												</button>
											)}
											<button
												className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
												onClick={() => {
													setShowModal(false);
													setRemarkState(false);
												}}
											>
												Cancel
											</button>
										</div>
									</div>
								</motion.div>
							</motion.div>
						)}

					</AnimatePresence>

					{filterVisible && <RequestFilter id={selectedId} refetch={refetch} />}
				</div>
			</div>
		</>
	);
};
