import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { RootState } from "../../Redux/store";
import { Company } from "./types";
import defaultImg from "../../assets/assets/dummyProduct.png"
import { toast } from "react-toastify";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div
            className="absolute top-1/2 -translate-y-1/2 left-[-50px] z-10 cursor-pointer"
            onClick={onClick}
        >
            <div className="w-12 h-12 flex items-center justify-center bg-gradient-to-br from-[#E2E8F0] to-[#FFFFFF] rounded-full ">
                <MdKeyboardArrowLeft className="w-6 h-6" />
            </div>
        </div>
    );
};

const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
        <div
            className="absolute top-1/2 -translate-y-1/2 right-[-50px] z-10 cursor-pointer"
            onClick={onClick}
        >
            <div className="w-12 h-12 flex items-center justify-center bg-gradient-to-br from-[#E2E8F0] to-[#FFFFFF] rounded-full ">
                <MdKeyboardArrowRight className="w-6 h-6" />
            </div>
        </div>
    );
};

const HomeAllCompanies = () => {
    const companies = useSelector((state: RootState) => state.values.allCompanies);
    const navigate = useNavigate();
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const handleItemClick = (_id?: string, name?: string | null) => {
        if (_id) {
            navigate(`/items/${_id}/company/${name}`, { state: { company: _id } });
        } else {
            toast.warning("Something went wrong! Please try again.");
        }
    };



    return (
        <div className="w-full lg:w-[90%] max-w-[1540px] mx-auto font-inter lg:bg-gradient-to-b from-[#E2E8F0] to-slate-50">
            <div className="px-16">
                <div className="flex items-center justify-between px-2">
                    <div className="text-3xl font-bold">All Companies</div>
                    <Link to="/company" className="text-lg font-bold text-blue-500 underline">View more</Link>
                </div>
                <Slider {...settings} className="p-2 mt-4">
                    {companies.length > 0 ? (
                        companies.map((company: Company, index: number) => (
                            <div key={index} className="px-2">
                                <div className="border-[5px] border-[#E5EAF16E] rounded-3xl ring-[5px] ring-white mt-2 mx-1 bg-white z-30 overflow-hidden">
                                    <div className="h-[191px] w-full">
                                        <img src={company.image || defaultImg} alt="" className="w-full h-full object-contain" />
                                    </div>
                                    <div className="p-6">
                                        <h3 className="text-lg font-bold truncate">{company.name}</h3>
                                        <div className="flex items-center justify-between">
                                            <span className="mt-2 truncate text-sm  text-gray-400">Category : {company.productCount}</span>
                                            <button
                                                onClick={() =>
                                                    handleItemClick(company?.id, company?.name)
                                                }
                                                className="text-sm font-medium text-[#007185]">View more</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        Array.from({ length: 3 }).map((_, index) => (
                            <div key={index} className="px-2">
                                <div className="border-[5px] border-[#E5EAF16E] rounded-3xl ring-[5px] ring-white mt-2 mx-1 bg-white z-30 overflow-hidden">
                                    <div className="h-[191px] w-full bg-gray-200 animate-pulse"></div>
                                    <div className="p-6">
                                        <div className="h-6 bg-gray-200 animate-pulse mb-2"></div>
                                        <div className="h-4 bg-gray-200 animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </Slider>
            </div>
        </div>
    );
};

export default HomeAllCompanies;



// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";
// import { RootState } from "../../Redux/store";
// import { Company } from "./types";
// type Props = {};

// const HomeAllCompanies = ({ }: Props) => {
//     const companies = useSelector((state: RootState) => state.values.allCompanies);
//     return (
//         <div className="w-full lg:w-[90%] max-w-[1540px] mx-auto  font-inter bg-gradient-to-b  from-[#E2E8F0] to-slate-50">
//             <div className="px-16">
//                 <div className="flex items-center justify-between px-2">
//                     <div className="text-3xl font-bold">All Companies</div>
//                     <Link to="/companies" className="text-lg font-bold text-blue-500">View more</Link>
//                 </div>
//                 <Slider
//                     dots={false}
//                     infinite={true}
//                     speed={500}
//                     slidesToShow={3}
//                     slidesToScroll={1}
//                     responsive={[
//                         {
//                             breakpoint: 1024,
//                             settings: {
//                                 slidesToShow: 2,
//                                 slidesToScroll: 1,
//                                 infinite: true,
//                                 dots: false
//                             }
//                         },
//                         {
//                             breakpoint: 600,
//                             settings: {
//                                 slidesToShow: 1,
//                                 slidesToScroll: 1,
//                                 initialSlide: 1
//                             }
//                         }
//                     ]}
//                     className="p-2 mt-4"
//                 >
//                     {companies.map((company: Company, index: number) => (
//                         <div key={index} className="px-2">
//                             <div className="border-[5px] border-[#E5EAF16E] rounded-3xl ring-[5px] ring-white mt-2 mx-1 bg-white z-30 overflow-hidden">
//                                 <div className="h-[191px] w-full">
//                                     <img src={company.image} alt="" className="w-full h-full object-contain" />
//                                 </div>
//                                 <div className="bg-white shadow-md rounded-lg p-6">
//                                     <h3 className="text-xl font-bold">{company.name}</h3>
//                                     <span className="mt-2 truncate">{company.description}</span>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </Slider>

//             </div>
//         </div>
//     );
// };

// export default HomeAllCompanies;
