
interface TabsProps {
    coins: string,
    handleCoinApi: (condition: string) => void
}

const CoinHistoryTabs = ({ coins, handleCoinApi }: TabsProps) => {
    return (

        <div
            className="px-1 py-1 flex items-center border-[1.5px] border-[#5097A4] font-inter w-full md:w-fit rounded-md mb-4 gap-2"
            >
            {["All", "Credited", "Debited"].map((condition) => (
                <span
                    key={condition}
                    className={`py-1 px-5 sm:px-10 lg:px-6  font-medium w-full text-center text-[14px] cursor-pointer rounded-md transition-all duration-200 ease-in-out text-nowrap flex justify-center gap-1 capitalize ${coins === condition
                        ? "text-white bg-[#5097A4]"
                        : "text-gray-600 hover:text-[#5097A4] bg-transparent"
                        }`}
                    onClick={() => handleCoinApi(condition)}>
                    {condition}
                </span>
            ))}
        </div>
    )
}

export default CoinHistoryTabs