import React, { useEffect, useState } from "react";
import file1 from "../../../../../assets/file.png";
import { CombinedState } from "../../../../../types/api-types";
import { useSearchFiltersMutation } from "../../../../../Redux/Api/ProductAPI";
import { useLocation } from "react-router-dom";

const BlueBox = () => {
  const [combinedState, setCombinedState] = useState<CombinedState>({
    search: "",
    company: [],
    category: [],
    limit: 12,
    page: 1,
  });

  const [
    searchFilters,
    { data: SearchProductsResponse }
  ] = useSearchFiltersMutation();

  useEffect(() => { }, [SearchProductsResponse]);
  useEffect(() => {
    searchFilters({ combinedState });
  }, [combinedState, searchFilters]);

  const location = useLocation();
  const hideTabs = location.pathname.startsWith("/admin/category/view/");
  return (
    <>
      {!hideTabs && (
        <div className="mx-3 px-4 py-4 rounded-xl bg-white" style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
          <div className="flex justify-between">
            <div className="bg-[#E2E8F0] p-1 rounded-lg ml-3">
              <img src={file1} alt="file" />
            </div>
          </div>

          <div className="mt-3 flex flex-col justify-center items-start ml-3">
            <h1 className="font-inter text-[13px] text-[#6B6B6B]">
              All Products
            </h1>
            <p className="font-inter text-[35px] font-medium text-[#6B6B6B]">
              {SearchProductsResponse?.total}
            </p>
          </div>
        </div>
      )}
      
    </>
  );
};

export default BlueBox;
