import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "./components/Dropdown";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useChangeStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import { useAdminCategoriesQuery } from "../../../Redux/Api/Category";
import ItemStatus from "./inventoryStatus";
import { Items } from "../types";
import defaultImage from "../../../assets/item.png";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { toast } from "react-toastify";
import ManagePriority from "./components/ManagePriority";

const Inventory = () => {
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState("15");
  const [search, setSearch] = useState<string>("");

  const { data: categoryResponse, refetch, isLoading } = useAdminCategoriesQuery({
    page: currentPage,
    limit: limit === "All" ? undefined : limit,
    search: search,
  });

  const [changeStatusMutation, { isSuccess, isError, error }] = useChangeStatusMutation();
  const navigate = useNavigate();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => { setSearch(event.target.value) }, []);
  const handleTogglePopup = (index: number) => { setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index)) };

  const options = ["9", "8", "7", "All"];
  const handleSelect = (option: any) => { setLimit(option === "All" ? "All" : option) };


  const handleStatusChange = async (id: string, status: string, index: number) => {
    try {
      await changeStatusMutation({ id, status: status === "publish" });
      refetch();
      handleTogglePopup(index);
    } catch (err) {
      console.error('Error occurred while changing status:', err);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      toast.success('Status changed successfully!');
    } else if (isError) {
      toast.error(`Error: ${error?.message || 'Something went wrong'}`);
    }
  }, [isSuccess, isError, error]);


  const prevPage = () => {
    const nextPageNumber = currentPage - 1;
    if (nextPageNumber >= 1) {
      setCurrentPage(nextPageNumber);
    }
  };

  const nextPage = () => {
    const nextPageNumber = currentPage + 1;
    if (nextPageNumber <= totalPages) {
      setCurrentPage(nextPageNumber);
    }
  };


  const handleCategoryNameClick = (name: string, id: string) => {
    navigate(`/admin/category/view/${name}`, {
      state: { name, id },
    });
  };

  const totalPages = Math.ceil((categoryResponse?.total || 0) / parseInt(limit));
  const startItemIndex = (currentPage - 1) * parseInt(limit) + 1;
  const endItemIndex = Math.min(currentPage * parseInt(limit), categoryResponse?.total || 0);

  return (

    <div className="w-full h-auto px-3 mt-[13px] py-3">
      <div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
        {/* Heading & Filters */}
        <div className="flex justify-between py-2 px-4">
          <div className="font-medium">Inventory Categories</div>
          <div className="flex gap-2">
            <input
              type="text"
              value={search}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
              placeholder="Search.."
              style={{
                boxShadow:
                  "0px 0px 7.6px 0px #00000040 inset",
              }}
            />
          </div>
        </div>

        {/* Table */}
        <div className="w-full overflow-x-auto">
          <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
            <thead className="bg-white divide-y divide-gray-200">
              <tr className="border-t">
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Image
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Priority
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  No of products
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Published products
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Category Details
                </th>

                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
                  Action
                </th>
                <th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
              </tr>
            </thead>
            <tbody className="bg-white ">
              {isLoading ? (
                <TableLoading colCount={7} />
              ) : categoryResponse?.result?.length > 0 ? (
                categoryResponse?.result?.map(
                  (i: Items, index: number) => (
                    <tr key={i?.id}>
                      <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                        <img
                          className="w-[36px] h-[36px] border rounded-full"
                          src={
                            i?.image
                              ? i?.image
                              : defaultImage
                          }
                          alt={i?.name}
                        />
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer"
                        onClick={() => {
                          if (i?.name && i?.id) {
                            handleCategoryNameClick(i.name, i.id);
                          }
                        }}
                      >
                        {i?.name}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                        <ManagePriority id={i?.id} priority={i.priority} refetch={refetch} total={Number(categoryResponse?.total)} type="category" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                        {i?.itemCount}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                        {i?.publishItemCount}
                      </td>
                      <Link
                        to={`/admin/categoryDetails/${i?.id}`}>
                        <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-blue-500 underline  ">
                          change Details
                        </td>
                      </Link>
                      <ItemStatus
                        key={index}
                        i={i}
                        index={index}
                        handleStatusChange={
                          handleStatusChange
                        }
                        openPopupIndex={
                          openPopupIndex
                        }
                        handleTogglePopup={
                          handleTogglePopup
                        }
                      />
                      <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      </td>
                    </tr>
                  )
                )
              ) : (
                <TableNoData
                  colCount={7}
                  text={"No Data Avaiable"}
                />
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex flex-row justify-between p-3 m-1 border-t-2">
          {/* Fist div */}
          <div className="flex flex-row gap-3 ">
            <Dropdown
              options={options}
              onSelect={handleSelect}
            />
            <p className="text-[#A6A8B1]">Items per page</p>
            <p className="text-[#666666]"> {categoryResponse?.total} Total Categories </p>
            <p className="text-[#666666]"> Displaying{" "} {limit === "All" ? "All" : `${startItemIndex} to ${endItemIndex}`}{" "}  items  </p>
          </div>
          {/* Second div */}
          <div className="flex flex-row gap-3">
            <p className="text-[#666666]">
              {currentPage} of{" "}
              {limit === "All" ? 1 : `${totalPages}`} pages
            </p>
            <div className="flex flex-row items-center gap-2 text-[#666666]">
              <span
                onClick={prevPage}
                className={`cursor-pointer ${currentPage === 1 ? "text-gray-400 " : ""}`}
              >
                <FaChevronLeft size={15} />
              </span>
              <span
                onClick={nextPage}
                className={`cursor-pointer ${currentPage === totalPages ? "text-gray-400 " : ""}`}
              >
                <FaChevronRight size={15} />
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Inventory;
