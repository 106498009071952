import search1 from "../../assets/Search.png";

interface Props {
  search: string;
  searchResults: any[]
}

const NoResult = ({ search, searchResults }: Props) => {
  const hasNoResults = searchResults.length === 0;
  const isSearchEmpty = !search.trim();
  return (
    <div className="w-full h-full flex justify-center items-center    flex-col">
      <div className=" flex justify-center items-center flex-col w-[200px] h-full mt-3">
        <div>
          <img src={search1} alt="search" className="object-contain w-16" />
        </div>

        <div className="flex flex-col">
          {isSearchEmpty ? (
            <>
              <span className="text-sm text-gray-500">Start typing</span>
            </>
          ) : hasNoResults ? (
            <>
              <span className="text-sm text-red-500">No results found</span>
              <span className="font-[600] text-sm leading-[20px] text-[#6366F1] text-center  border-b-[#6366F1]">
                Search Again
              </span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NoResult;
