import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllContractorQuery } from "../../../../../Redux/Api/Contractor";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setContractorData } from "../../../../../Redux/reducer/adminReducer";
import { GoBellFill, GoHomeFill } from "react-icons/go";
import { RootState } from "../../../../../Redux/store";

export const ContractorsNavbar = () => {
	const navigate = useNavigate();
	const { data: ContractorResponse } = useGetAllContractorQuery();
	const dispatch = useDispatch();
	const location = useLocation();
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
);
	const customerProfile = location.pathname.startsWith(
		"/admin/contractors/profile"
	);

	const contractor = ContractorResponse?.data?.totalContractor;

	useEffect(() => {
		if (ContractorResponse) {
			dispatch(setContractorData(ContractorResponse?.data));
		}
	}, [contractor, ContractorResponse]);

	// const getNavLinkClass = (path: any) => {
	// 	return location.pathname === path ? "border-b-2 border-b-black" : "";
	// };

	const navLinksClasses = (path: any) => {
		return location.pathname === path
			? "border-[#334155] bg-[#334155] text-white"
			: "";
	};
	const hideTabs = ![
		"/admin/contractors",
		"/admin/contractors/request",
		"/admin/contractors/delete",
	].includes(location.pathname);
	return (
		<div className="w-full bg-[#E9ECEF] py-2">
			<div className="bg-[#334155] px-4 py-3  rounded-xl mx-3">
				<div className="flex justify-between items-center">
					{/* Left Section */}
					<div>
						<h4 className="text-lg font-semibold font-poppins text-white leading-6">
							Contractors
						</h4>
						<div className="flex items-center gap-2 mt-1">
							<GoHomeFill size={20} className="text-blue-400" />
							<span className="text-sm font-inter text-gray-300">
								/
							</span>
							<div className="text-sm font-inter text-blue-400 hover:underline">
               contractors
							</div>
						</div>
					</div>

					{/* Right Section */}
					<div className="flex items-center gap-4">
						<div className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
							<p className="text-sm font-inter text-[#1C1D22]">{userDetails?.name}</p>
						</div>
						<GoBellFill
							size={22}
							className="text-red-400 cursor-pointer"
						/>
					</div>
				</div>
			</div>
		</div>
		// <div className="flex flex-col gap-[10px]  mb-[10px] box-border">
		//   <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px] pl-3">
		//     Contractors
		//   </div>
		//   {
		//     !hideTabs && (
		//       <div className="mx-3">
		//         <div
		//           className="flex items-center gap-2 h-[45px]  bg-white text-black text-[14px] rounded-xl mb-3 w-fit p-[4px] font-medium "
		//           style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
		//           <div
		//             onClick={() => navigate("/admin/contractors")}
		//             className={`rounded-xl cursor-pointer h-full flex items-center justify-center  px-3 py-1 ${navLinksClasses(
		//               "/admin/contractors"
		//             )}`}>
		//             Contractors
		//           </div>
		//           <div
		//             onClick={() =>
		//               navigate("/admin/contractors/request")
		//             }
		//             className={`rounded-xl cursor-pointer h-full flex items-center justify-center   px-3 py-1 ${navLinksClasses(
		//               "/admin/contractors/request"
		//             )}`}>
		//             {" "}
		//             Contractors Request
		//           </div>
		//           <div
		//             onClick={() =>
		//               navigate("/admin/contractors/delete")
		//             }
		//             className={`rounded-xl cursor-pointer h-full flex items-center justify-center   px-3 py-1 ${navLinksClasses(
		//               "/admin/contractors/delete"
		//             )}`}>
		//             Contractors History
		//           </div>
		//         </div>
		//         {!customerProfile && (
		//           <div
		//             className="w-full flex flex-col md:flex-row justify-around sm:justify-around sm:gap-x-6 sm:pl-3 h-auto rounded-xl bg-white p-4"
		//             style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
		//           >
		//             <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
		//               <div className="text-[#8B8D97]">Total Contractors</div>
		//               <div className="text-[#F59E0B]">{contractor}</div>
		//             </div>

		//             <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
		//               <div className="text-[#8B8D97]">Approve Contractors</div>
		//               <div className="text-[#F59E0B]">{ContractorResponse?.data?.approveContractor}</div>
		//             </div>

		//             <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
		//               <div className="text-[#8B8D97]">Pending Contractors Requests</div>
		//               <div className="text-[#F59E0B]">{ContractorResponse?.data?.pendingContractor}</div>
		//             </div>

		//             <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
		//               <div className="text-[#8B8D97]">Deleted Contractors</div>
		//               <div className="text-[#F59E0B]">{ContractorResponse?.data?.deletedContractor}</div>
		//             </div>
		//           </div>

		//         )}
		//       </div>
		//     )
		//   }
		// </div>
	);
};
