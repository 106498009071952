import React, { useCallback, useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import {  useCategoriesNameQuery} from "../../../Redux/Api/NamesAPI";
import { useDispatch } from "react-redux";
import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
import { clearResults } from "../../../Redux/reducer/Filter";
import { debounce } from "lodash";

interface Categories {
  companyCount: number;
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

interface Comapnies {
  basicRate: number;
  description: string;
  id: string;
  image: string;
  name: string;
  productCount: number;
  publish: boolean;
  srNo: number;
}

export const MobileFilter = ({  smallFilter,  smallCompanyFilter,  sidebarCategory,  sidebarCompany,  close,}: any) => {
  const [formData, setFormData] = useState<any>({  search: "" });
  const [categories, setCategories] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(sidebarCategory || []);
  const [selectedCompany, setSelectedCompany] = useState<any>( sidebarCompany || []);

  const { data: categoriesResponse } =  useCategoriesNameQuery({},{ refetchOnMountOrArgChange: true, keepPreviousData: true });

  const [mutation, { data: CompaniesResponse }] =  useGetCompanyProductMutation();

  const filteredCategories = categoriesResponse
    ? categoriesResponse?.data?.filter((category: any) => {
        const regex = new RegExp(formData.search, "i"); 
        return regex.test(category.name);
      })
    : [];

  const filteredCompanies = CompaniesResponse
    ? CompaniesResponse?.data?.filter((company: any) => {
        const regex = new RegExp(formData.search, "i"); 
        if (selectedCategory?.length === 0) {
          return regex.test(company.name);
        } 
      })
    : [];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

   const dispatch = useDispatch();

  const handleCategoryClick = (id: any) => {
    const categoryExists = filteredCategories.some(
      (category: any) => category.id === id
    );
    if (categoryExists) {
      if (selectedCategory.includes(id)) {
        setSelectedCategory(
          selectedCategory.filter((category: any) => category !== id)
        );
      } else {
        setSelectedCategory([...selectedCategory, id]);
      }
    }
  };

  const handleCompanyClick = (id: string) => {
    const companyExists = filteredCompanies.some(
      (company: any) => company.id === id
    );
    
    if (companyExists) {
      if (selectedCompany.includes(id)) {
        setSelectedCompany(
          selectedCompany.filter((company: any) => company !== id)
        );
      } else {
        setSelectedCompany([...selectedCompany, id]);
      }
    }
  };

  const debouncedMutation = useCallback(
    debounce((category) => {
      mutation({ category });
    }, 300), 
    []
  );
  
  useEffect(() => {
    debouncedMutation([...selectedCategory]);
  }, [selectedCategory, debouncedMutation]);

  // useEffect(() => {
  //   mutation({ category: [...selectedCategory] });
  // }, [mutation, selectedCategory]);

  useEffect(() => {
    setSelectedCategory(selectedCategory)
    setSelectedCompany(selectedCompany)
  }, [selectedCategory, selectedCompany]);

  const handleShowResults = () => {
    mutation({ category: [...selectedCategory] });
    smallFilter(selectedCategory);
    smallCompanyFilter(selectedCompany);
    close();
  };

  const results = () => {
    dispatch(clearResults())
    smallFilter([]);
    smallCompanyFilter([]);
  };

   useEffect(()=> {
        if(sidebarCategory){
          setSelectedCategory(sidebarCategory)
        }

        if(sidebarCompany){
          setSelectedCompany(sidebarCompany)
        }
   },[sidebarCategory,sidebarCompany])

  const handleClose =():void =>{
    close()
    setCategories(false)
    setCompanies(false)
  }
  return (
    // <div className="w-full border rounded-[6px]">
    //   <div className="flex justify-between px-[12px] py-[8px]">
    //     <div className="flex w-[80%] items-center gap-[3px] border rounded-[6px] px-[10px] py-[4px] bg-[#F2F2F2]">
    //       <IoSearch className="h-[13px] w-[13px]" />
    //       <input
    //         type="text"
    //         placeholder="Search Items and Categories"
    //         className="font-[300] text-[12px] leading-[20px] w-full bg-[#F2F2F2] "
    //         value={formData.search}
    //         onChange={handleInputChange}
    //         name="search"
    //       />
    //     </div>
    //     <div
    //       className="flex items-center text-[#5097A4] font-[500] text-[14px] leading-[15px] cursor-pointer"
    //       onClick={() => close()}
    //     >
    //       Close
    //     </div>
    //   </div>

    //   <div className="flex flex-col">
    //     <div
    //       className={`flex justify-between px-[10px] h-[40px] border-t items-center`}
    //     >
    //       <div className="flex font-[400] text-[16px] leading-[24px]">
    //         Categories
    //       </div>
    //       <div
    //         onClick={() => setCategories(!categories)}
    //         className="cursor-pointer"
    //       >
    //         {categories ? <IoChevronDown /> : <IoChevronUp />}
    //       </div>
    //     </div>
    //     {categories && (
    //       <div
    //         className={`overflow-auto bg-white flex flex-wrap px-[12px] gap-[3px] py-[10px] ${
    //           categories ? "max-h-[120px]" : "max-h-0"
    //         }`}
    //       >
    //         {filteredCategories.map((category: Categories, index: any) => (
    //           <div
    //             key={category?.id}
    //             className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] overflow-auto cursor-pointer ${
    //               selectedCategory.includes(category?.id)
    //                 ? "bg-red-500 text-white"
    //                 : ""
    //             }`}
    //             onClick={() => handleCategoryClick(category?.id)}
    //           >
    //             {category?.name}
    //           </div>
    //         ))}
    //       </div>
    //     )}

    //     <div className="flex justify-between px-[10px] h-[40px] border-t items-center">
    //       <div className="flex font-[400] text-[16px] leading-[24px]">
    //         Companies
    //       </div>
    //       <div
    //         onClick={() => setCompanies(!companies)}
    //         className="cursor-pointer"
    //       >
    //         {companies ? <IoChevronDown /> : <IoChevronUp />}
    //       </div>
    //     </div>
    //     {companies && (
    //       <div
    //         className={` overflow-auto bg-white  flex flex-wrap px-[12px] gap-[3px] py-[10px] ${
    //           companies ? "max-h-[120px]" : "max-h-0"
    //         }`}
    //       >
    //         {filteredCompanies.map((company: Comapnies, index: any) => (
    //           <div
    //             key={company?.id}
    //             className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] cursor-pointer ${
    //               selectedCompany.includes(company?.id)
    //                 ? "bg-red-500 text-white"
    //                 : ""
    //             }`}
    //             onClick={() => handleCompanyClick(company?.id)}
    //           >
    //             {company?.name}
    //           </div>
    //         ))}
    //       </div>
    //     )}
    //   </div>

    //   <div className="p-[12px] flex justify-between">
    //     <div
    //       className="py-[4px] px-[12px] text-[#5097A4] border rounded-[6px] cursor-pointer"
    //       onClick={() => results()}
    //     >
    //       Clear Results
    //     </div>

    //     <div
    //       className="py-[4px] px-[12px] text-white bg-[#5097A4] border rounded-[6px] cursor-pointer"
    //       onClick={() => {
    //         handleShowResults();
    //       }}
    //     >
    //       Show Results
    //     </div>
    //   </div>
    // </div>
    <div className="w-full  bg-white">
    <div className="flex justify-between px-4 py-3">
      <div className="flex w-[75%] items-center gap-3 border rounded-lg px-4 py-2 bg-[#F7FAFC]">
        <IoSearch className="h-4 w-4 text-gray-600" />
        <input
          type="text"
          placeholder="Search Items and Categories"
          className="font-light text-xs leading-5 w-full bg-[#F7FAFC] text-gray-600 outline-none"
          value={formData.search}
          onChange={handleInputChange}
          name="search"
        />
      </div>
      <div
        className="flex items-center text-[#5097A4] font-semibold text-sm cursor-pointer"
        onClick={handleClose}>
        Close
      </div>
    </div>

    <div className="flex flex-col">
      <div className="flex justify-between px-4 py-3 border-y ">
        <div className="text-md font-semibold text-gray-800 ">Categories</div>
        <div 
        onClick={() => {
          setCategories(!categories); 
          if (!categories) setCompanies(false); 
        }} 
        className="cursor-pointer">
          {categories ? (
            <IoChevronDown className="text-gray-600" />
          ) : (
            <IoChevronUp className="text-gray-600" />
          )}
        </div>
      </div>

      {/* Categories List */}
      <div
        className={`overflow-y-auto bg-white flex flex-wrap items-center px-4 gap-3   rounded-md border-b transition-all duration-300 ease-in-out ${categories ? "h-[100px] bg-gray-50  py-4" : "h-0"
          }`}
      >
        {categories &&
          filteredCategories.map((category: Categories) => (
            <div
              key={category.id}
              className={`px-4 py-2 rounded-md text-xs  duration-150 font-medium cursor-pointer border border-transparent ${selectedCategory.includes(category.id)
                  ? "bg-[#CEE4E8] text-gray-800"
                  : "bg-[#F7FAFC] text-gray-700"
                }`}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </div>
          ))}
      </div>

      {/* Companies Section */}
      <div className="flex justify-between px-4 py-3 border-b ">
        <div className="text-md font-semibold text-gray-800">Companies</div>
        <div 
        onClick={() => {
          setCompanies(!companies); 
          if (!companies) setCategories(false); 
        }} 
        className="cursor-pointer">
          {companies ? (
            <IoChevronDown className="text-gray-600" />
          ) : (
            <IoChevronUp className="text-gray-600" />
          )}
        </div>
      </div>

      {/* Companies List  */}
      <div
        className={`overflow-y-auto bg-white flex items-center flex-wrap px-4 gap-3 rounded-md border-b transition-all duration-300 ease-in-out ${companies ? "h-[100px] bg-gray-50  py-4" : "h-0"
          }`}
      >
        {companies &&
          filteredCompanies.map((company: any) => (
            <div
              key={company.id}
              className={`px-4 py-2 rounded-md text-xs duration-150 font-medium cursor-pointer border border-transparent ${selectedCompany.includes(company.id)
                  ? "bg-[#CEE4E8] text-gray-800"
                  : "bg-[#F7FAFC] text-gray-700"
                }`}
              onClick={() => handleCompanyClick(company.id)}
            >
              {company.name}
            </div>
          ))}
      </div>
    </div>




    <div className="px-4 py-3 flex justify-between">
      <div
        className="py-2 px-4 text-[#5097A4] border rounded-lg cursor-pointer font-medium text-xs"
        onClick={() => results()}>
        Clear Results
      </div>
      <div
        className="py-2 px-4 text-white bg-[#5097A4] border rounded-lg cursor-pointer font-medium text-xs"
        onClick={() => handleShowResults()}>
        Show Results
      </div>
    </div>
  </div>
  );
};

// import React, { useEffect, useState } from "react";
// import { IoSearch } from "react-icons/io5";
// import { IoChevronDown } from "react-icons/io5";
// import { IoChevronUp } from "react-icons/io5";
// import {  useCategoriesNameQuery} from "../../../Redux/Api/NamesAPI";
// import { useDispatch } from "react-redux";
// import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
// import { clearResults } from "../../../Redux/reducer/Filter";

// interface Categories {
//   companyCount: number;
//   id: string;
//   image: string;
//   itemCount: number;
//   name: string;
// }

// interface Comapnies {
//   basicRate: number;
//   description: string;
//   id: string;
//   image: string;
//   name: string;
//   productCount: number;
//   publish: boolean;
//   srNo: number;
// }

// export const MobileFilter = ({
//   smallFilter,
//   smallCompanyFilter,
//   sidebarCategory,
//   sidebarCompany,
//   close,
// }: any) => {
//   const [formData, setFormData] = useState<any>({
//     search: "",
//   });
//   const [categories, setCategories] = useState(false);
//   const [companies, setCompanies] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState<any>(sidebarCategory || []);
//   const [selectedCompany, setSelectedCompany] = useState<any>( sidebarCompany || []);
//   const [categoriesCompaniesMap, setCategoriesCompaniesMap] = useState<any>({});

//   const { data: categoriesResponse, isLoading: isCategoriesLoading } =
//     useCategoriesNameQuery(
//       {},
//       { refetchOnMountOrArgChange: true, keepPreviousData: true }
//     );

//   const [mutation, { data: CompaniesResponse }] =
//     useGetCompanyProductMutation();

//   const filteredCategories = categoriesResponse
//     ? categoriesResponse?.data?.filter((category: any) => {
//         const regex = new RegExp(formData.search, "i"); 
//         return regex.test(category.name);
//       })
//     : [];

//   const filteredCompanies = CompaniesResponse
//     ? CompaniesResponse?.data?.filter((company: any) => {
//         const regex = new RegExp(formData.search, "i"); 
//         if (selectedCategory?.length === 0) {
//           return regex.test(company.name);
//         } else {
//           const associatedCategories =
//             categoriesCompaniesMap[company.name] || [];

//           return (
//             <div>
//               {regex.test(company.name) &&
//                 associatedCategories.some((category: any) =>
//                   selectedCategory.includes(category)
//                 )}
//             </div>
//           );
//         }
//       })
//     : [];

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//    const dispatch = useDispatch();
  

//   const handleCategoryClick = (id: any) => {
//     const categoryExists = filteredCategories.some(
//       (category: any) => category.id === id
//     );
//     if (categoryExists) {
//       if (selectedCategory.includes(id)) {
//         setSelectedCategory(
//           selectedCategory.filter((category: any) => category !== id)
//         );
//       } else {
//         setSelectedCategory([...selectedCategory, id]);
//       }
//     }
//   };

//   const handleCompanyClick = (id: string) => {
//     const companyExists = filteredCompanies.some(
//       (company: any) => company.id === id
//     );

//     if (companyExists) {
//       if (selectedCompany.includes(id)) {
//         setSelectedCompany(
//           selectedCompany.filter((company: any) => company !== id)
//         );
//       } else {
//         setSelectedCompany([...selectedCompany, id]);
//       }
//     }
//   };

//   useEffect(() => {
//     mutation({ category: [...selectedCategory] });
//   }, [mutation, selectedCategory]);

//   useEffect(() => {
//     setSelectedCategory(selectedCategory)
//     setSelectedCompany(selectedCompany)
//   }, [selectedCategory, selectedCompany]);

//   const handleShowResults = () => {
//     mutation({ category: [...selectedCategory] });
//     smallFilter(selectedCategory);
//     smallCompanyFilter(selectedCompany);
//     close();
//   };

//   const results = () => {
//     dispatch(clearResults())
//     // setSelectedCategory(useSelector((state:any) => state.selectionReducer.slectedCategories))
//     // setSelectedCompany(useSelector((state:any) => state.selectionReducer.selectedCompanies))
//     smallFilter([]);
//     smallCompanyFilter([]);
//   };

//    useEffect(()=> {
//         if(sidebarCategory){
//           setSelectedCategory(sidebarCategory)
//         }

//         if(sidebarCompany){
//           setSelectedCompany(sidebarCompany)
//         }
//    },[sidebarCategory,sidebarCompany])


//   return (
//     <div className="w-full border rounded-[6px]">
//       <div className="flex justify-between px-[12px] py-[8px]">
//         <div className="flex w-[80%] items-center gap-[3px] border rounded-[6px] px-[10px] py-[4px] bg-[#F2F2F2]">
//           <IoSearch className="h-[13px] w-[13px]" />
//           <input
//             type="text"
//             placeholder="Search Items and Categories"
//             className="font-[300] text-[12px] leading-[20px] w-full bg-[#F2F2F2] "
//             value={formData.search}
//             onChange={handleInputChange}
//             name="search"
//           />
//         </div>
//         <div
//           className="flex items-center text-[#5097A4] font-[500] text-[14px] leading-[15px] cursor-pointer"
//           onClick={() => close()}
//         >
//           Close
//         </div>
//       </div>

//       <div className="flex flex-col">
//         <div
//           className={`flex justify-between px-[10px] h-[40px] border-t items-center`}
//         >
//           <div className="flex font-[400] text-[16px] leading-[24px]">
//             Categories
//           </div>
//           <div
//             onClick={() => setCategories(!categories)}
//             className="cursor-pointer"
//           >
//             {categories ? <IoChevronDown /> : <IoChevronUp />}
//           </div>
//         </div>
//         {categories && (
//           <div
//             className={`overflow-auto bg-white flex flex-wrap px-[12px] gap-[3px] py-[10px] ${
//               categories ? "max-h-[120px]" : "max-h-0"
//             }`}
//           >
//             {filteredCategories.map((category: Categories, index: any) => (
//               <div
//                 key={category?.id}
//                 className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] overflow-auto cursor-pointer ${
//                   selectedCategory.includes(category?.id)
//                     ? "bg-red-500 text-white"
//                     : ""
//                 }`}
//                 onClick={() => handleCategoryClick(category?.id)}
//               >
//                 {category?.name}
//               </div>
//             ))}
//           </div>
//         )}

//         <div className="flex justify-between px-[10px] h-[40px] border-t items-center">
//           <div className="flex font-[400] text-[16px] leading-[24px]">
//             Companies
//           </div>
//           <div
//             onClick={() => setCompanies(!companies)}
//             className="cursor-pointer"
//           >
//             {companies ? <IoChevronDown /> : <IoChevronUp />}
//           </div>
//         </div>
//         {companies && (
//           <div
//             className={` overflow-auto bg-white  flex flex-wrap px-[12px] gap-[3px] py-[10px] ${
//               companies ? "max-h-[120px]" : "max-h-0"
//             }`}
//           >
//             {filteredCompanies.map((company: Comapnies, index: any) => (
//               <div
//                 key={company?.id}
//                 className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] cursor-pointer ${
//                   selectedCompany.includes(company?.id)
//                     ? "bg-red-500 text-white"
//                     : ""
//                 }`}
//                 onClick={() => handleCompanyClick(company?.id)}
//               >
//                 {company?.name}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <div className="p-[12px] flex justify-between">
//         <div
//           className="py-[4px] px-[12px] text-[#5097A4] border rounded-[6px] cursor-pointer"
//           onClick={() => results()}
//         >
//           Clear Results
//         </div>

//         <div
//           className="py-[4px] px-[12px] text-white bg-[#5097A4] border rounded-[6px] cursor-pointer"
//           onClick={() => {
//             handleShowResults();
//           }}
//         >
//           Show Results
//         </div>
//       </div>
//     </div>
//   );
// };
