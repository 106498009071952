import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Error.css";
import { FaArrowLeft } from "react-icons/fa";

export default function ErrorPage() {
	return (
		<div className="bg-gradient-to-r from-[#5097A4] to-[#9be4f1]">
			<div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
				<div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
					<div className="border-t border-gray-200 text-center pt-8">
						<motion.h1
							className="text-9xl font-bold text-[#5097A4]"
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.8 }}>
							404
						</motion.h1>
						<motion.h1
							className="text-6xl font-medium py-8"
							initial={{ x: "-100vw" }}
							animate={{ x: 0 }}
							transition={{
								type: "spring",
								stiffness: 120,
							}}>
							Page not found
						</motion.h1>
						<motion.p
							className="text-2xl pb-8 px-12 font-medium"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 0.5, duration: 0.8 }}>
							Sorry, we couldn’t find the page you’re looking for.
						</motion.p>
						<motion.div
							initial={{ scale: 0.8 }}
							animate={{ scale: 1 }}
							whileHover={{ scale: 1.1 }}
							transition={{ duration: 0.3 }}
							className="flex items-center justify-center pb-4">
							<Link
								to="/"
								className="bg-gradient-to-r from-[#5097A4] to-[#9be4f1] hover:to-[#3f7984] hover:from-[#9be4f1] text-white font-semibold px-6 py-3 rounded-md mr-6 flex w-fit justify-center items-center">
								<FaArrowLeft className="mr-2" /> Go Back Home
							</Link>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	);
}
