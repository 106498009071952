import { useEffect, useState } from "react";
import { RootState } from "../../Redux/store";
import { useSelector } from "react-redux";


interface PriceData {
    finalPrice: Number;
    unit: string;
}

interface PriceSectionProps {
    data: PriceData;
    isLoading: boolean;
}

const PriceSection = () => {


    const [displayedText, setDisplayedText] = useState<string>("");
    const [index, setIndex] = useState<number>(0);

    const userDetails = useSelector(
        (state: RootState) => state.Login.ContractorData
    );
    const fullText = userDetails ? "Best Prices!" : "To unlock the best prices"
    const typingSpeed = 150;



    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (index < fullText.length) {
                setDisplayedText((prev) => prev + fullText[index]);
                setIndex((prev) => prev + 1);
            } else {
                setTimeout(() => {
                    setIndex(0);
                    setDisplayedText("");
                }, 1000);
            }
        }, typingSpeed);

        return () => clearTimeout(typingTimeout);
    }, [index]);

    return (
        <div className="flex flex-col justify-center gap-0.5 md:w-[19rem]">
            <div className="font-[500] text-[1.49rem] md:text-[2.21rem]  leading-[1.0rem] md:leading-[1.80rem]  text-gray-700  items-center w-full border-l-2 border-l-black pl-1 py-2 ">
                {/* {isLoading ? (
                    <div className="animate-pulse space-y-2">
                        <div className="bg-gray-300 h-5 w-16 rounded-md"></div>
                        <div className="bg-gray-300 h-6 w-24 rounded-md"></div>
                    </div>
                ) : (
                    <div className="w-full text-[23px] flex flex-col  items-start">
                        <div>Start At</div>
                        <div className="mt-2 md:mt-0">
                            {data &&
                                `₹${data?.finalPrice?.toFixed(2)}/${data?.unit}`}
                        </div>
                    </div>
                )} */}
                <div className="w-full text-[23px] flex flex-col  items-start ">
                    {/* <div>Start At</div>
                        <div className="mt-2 md:mt-0">
                            {data &&
                                `₹${data?.finalPrice?.toFixed(2)}/${data?.unit}`}
                        </div> */}
                    <div>{userDetails ? "Top Quality" : "Sign Up !!"} </div>
                    <div className={`mt-2 md:mt-0`}>
                        {displayedText}
                        {displayedText.length < fullText.length && <span className="typewriter-cursor">|</span>}
                    </div>
                </div>



            </div>
        </div>
    );
};

export default PriceSection;
