import { Product } from "../type";

interface MobileProductDescriptionProps {
    product: Product;
    isLoading: boolean
}

const MobileProductDescription = ({ product, isLoading }: MobileProductDescriptionProps) => {
    const companyDescriptionLabels = product?.companyDescriptionLabels;
    const categoryDescriptionLabels = product?.categoryDescriptionLabels;
    const categoryProductDescriptionLabels = product?.categoryProductDescriptionLabels;
    const companyProductDescriptionLabels = product?.companyProductDescriptionLabels;

    const skeletonLoader = (
        <div className="space-y-3 w-full">
            {
                Array.from({ length: 4 }).map((_,i) => (
                    <div key={i}>
                        <div className="w-[180px] h-[18px] bg-gray-300 rounded-md animate-pulse"></div>
                        <ul className="px-6 space-y-4">
                            {Array.from({ length: 3 }).map((_, idx) => (
                                <li
                                    key={idx}
                                    className="h-[12px] bg-gray-300 rounded-md w-[90%] animate-pulse"
                                ></li>
                            ))}
                        </ul></div>
                ))
            }

        </div>
    );
    return (
        <div className="w-full px-4 py-2 space-y-4">
            {
                isLoading ? (skeletonLoader) : (
                    <>
                        <div className="w-full">
                            <p className="text-[18px] font-medium text-gray-600 tracking-wide leading-4">
                                Company Description
                            </p>
                            {companyDescriptionLabels && companyDescriptionLabels.length > 0 ? (
                                <ul className="px-6 mt-3 space-y-1">
                                    {companyDescriptionLabels.map((el: any, idx: number) => (
                                        <li
                                            key={idx}
                                            className="list-disc text-[12px] text-gray-500 leading-5 tracking-normal w-full"
                                        >
                                            {el?.key}: {el?.value}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-[12px] text-gray-500 italic mt-3">
                                    No description available.
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <p className="text-[18px] font-medium text-gray-600 tracking-wide leading-4">
                                Category Description
                            </p>
                            {categoryDescriptionLabels && categoryDescriptionLabels.length > 0 ? (
                                <ul className="px-6 mt-3 space-y-1">
                                    {categoryDescriptionLabels.map((el: any, idx: number) => (
                                        <li
                                            key={idx}
                                            className="list-disc text-[12px] text-gray-500 leading-5 tracking-normal"
                                        >
                                            {el?.key}: {el?.value}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-[12px] text-gray-500 italic mt-3">
                                    No description available.
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <p className="text-[18px] font-medium text-gray-600 tracking-wide leading-4">
                                Category Product Description
                            </p>
                            {categoryProductDescriptionLabels && categoryProductDescriptionLabels.length > 0 ? (
                                <ul className="px-6 mt-3 space-y-1">
                                    {categoryProductDescriptionLabels.map((el: any, idx: number) => (
                                        <li
                                            key={idx}
                                            className="list-disc text-[12px] text-gray-500 leading-5 tracking-normal"
                                        >
                                            {el?.key}: {el?.value}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-[12px] text-gray-500 italic mt-3">
                                    No description available.
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <p className="text-[18px] font-medium text-gray-600 tracking-wide leading-4">
                                Company Product Description
                            </p>
                            {companyProductDescriptionLabels && companyProductDescriptionLabels.length > 0 ? (
                                <ul className="px-6 mt-3 space-y-1">
                                    {companyProductDescriptionLabels.map((el: any, idx: number) => (
                                        <li
                                            key={idx}
                                            className="list-disc text-[12px] text-gray-500 leading-5 tracking-normal"
                                        >
                                            {el?.key}: {el?.value}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-[12px] text-gray-500 italic mt-3">
                                    No description available.
                                </p>
                            )}
                        </div></>
                )
            }

        </div>
    );
};

export default MobileProductDescription;
