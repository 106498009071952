import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

const Unauthorized: React.FC = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);



  if (userDetails && userDetails?.isVerified) {
    // If user is logged in, redirect to home
    return <Navigate to="/" replace />;
  }

  if(userDetails && !userDetails?.isVerified)

  return <Outlet />;

  return <Navigate to="/" replace />;
};

export default Unauthorized;
