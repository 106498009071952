import { useEffect, useState } from "react";
import { usePriceMutation } from "../../../Redux/Api/priceAPI";
import { useTopSellingQuery } from "../../../Redux/Api/top"
import MobileTopSellingCard from "./MobileTopSellingCard";

interface Selling {
    name?: string;
    id?: string;
    type: string;
    view: number;
}


interface IHighLowPrice {
    lowFinalPrice: number;
    highFinalPrice: number;
}
interface Price {
    id: string;
    finalPrice: number;
    unit: string;
    highLowPrice: IHighLowPrice | null;
}
const TopSellingMobile = () => {

    const [data, setData] = useState([]);
    const { data: selling } = useTopSellingQuery();
    const [priceMutation] = usePriceMutation();
    const [priceData, setPriceData] = useState<{ [key: string]: Price }>({});

    useEffect(() => {
        if (selling) {
            setData(selling);
        }
    }, [selling]);

    useEffect(() => {
        const fetchPrices = async (productId: string[]) => {
            try {
                const priceResponse = await priceMutation({ productId })
                const priceDataObj: { [key: string]: Price } = {};
                priceResponse?.data?.results?.slice(0, 4).forEach((result: any) => {
                    priceDataObj[result.productId] = {
                        id: result.productId,
                        finalPrice: result.finalPrice,
                        unit: result.unit,
                        highLowPrice: result.highLowPrice ? {
                            lowFinalPrice: result.highLowPrice.lowFinalPrice,
                            highFinalPrice: result.highLowPrice.highFinalPrice
                        } : null
                    };
                });
                setPriceData(priceDataObj);
            } catch (error) {
                console.log(error, "error occured")
            }
        }

        if (selling?.length > 0) {
            const productId = selling.map((selling: Selling) => selling.id)
            fetchPrices(productId)
        }
    }, [selling, priceMutation])
    return (
        <div className="grid grid-cols-2 gap-4 mt-3 px-4 py-3">
            {data && data.slice(0, 4).map((item: any) => {
                if (item.id === priceData[item.id]?.id) {
                    return (
                        <MobileTopSellingCard data={item} priceData={priceData[item.id]} key={item.id} />
                    )
                }
                return null
            }

            )}
        </div>
    )
}

export default TopSellingMobile