import { useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useApproveClaimRewardsMutation, useGetRequestClaimRewardsMutation } from "../../../Redux/Api/Admin/Reward";
import { LiaCoinsSolid } from "react-icons/lia";
import { ConfirmModal } from "../../../utils/CustomModal";
import { exportToExcel } from "../../../utils/exportToExcel";
import useDebounce from "../../../common/hooks/useDebounce";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Dropdown from "../Inventory/components/Dropdown";
import TableNoData from "../../../common/admin-common/TableNoData";
import TableLoading from "../../../common/admin-common/TableLoading";
import { toast } from "react-toastify";


interface Reward {
	_id: string;
	rewardId: string;
	contractorId: string;
	contractorName: string;
	phoneNumber: string;
	image: string;
	packageName: string;
	packageImage: string;
	packageCoin: number;
	contractorTotalReward: number;
	claimStatus: boolean;
	createdAt: string;
}

export const RequestReward = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [limit, setLimit] = useState<number | "All">(10);
	const [search, setSearch] = useState<string>("");
	const [rewardId, setRewardId] = useState<string | null>(null);
	const [showPopup, setShowPopup] = useState(false);
	const [requestClaimRewardMutation, { data, isLoading }] = useGetRequestClaimRewardsMutation();
	const [approveClaimMutation, { isSuccess, isError }] = useApproveClaimRewardsMutation();

	const handleApproveButton = (id: string) => {
		setRewardId(id);
		setShowPopup(true);
	};

	const confirmApproval = (): void => {
		if (!rewardId) return;
		approveClaimMutation(rewardId);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success("Reward claim approved successfully!");
			closePopup();
		} else if (isError) {
			toast.error("Error approving reward claim.");
			closePopup();
		}
	}, [isSuccess, isError]);


	const closePopup = (): void => {
		setShowPopup(false);
		setRewardId(null);
	};

	const debounceSearch = useDebounce(search, 300);
	useEffect(() => {
		requestClaimRewardMutation({
			search: debounceSearch,
			page: currentPage,
			limit: limit === "All" ? data?.total : limit,
		});
	}, [debounceSearch, currentPage, limit]);


	const totalItems = data?.total || 0;
	const totalPages = limit === "All" ? 1 : Math.ceil(totalItems / Number(limit));
	const startItemIndex = (currentPage - 1) * (limit === "All" ? totalItems : Number(limit)) + 1;
	const endItemIndex = Math.min(currentPage * (limit === "All" ? totalItems : Number(limit)), totalItems);

	const options = ["10", "9", "8", "All"];

	const handleLimitChange = (option: string) => {
		const newLimit = option === "All" ? "All" : parseInt(option);
		setLimit(newLimit);
		setCurrentPage(1);
	};

	const prevPage = () => {
		if (currentPage > 1) setCurrentPage((prev: number) => prev - 1);
	};

	const nextPage = () => {
		if (currentPage < totalPages) setCurrentPage((prev: number) => prev + 1);
	};


	const exportData = () => {
		const formattedData = data.rewardData.map((item: Reward, i: number) => ({
			"S.No": i + 1,
			Name: item.contractorName,
			Contact: item.phoneNumber,
			"Assign Package": item.packageName,
			"Contractor Reward": item.contractorTotalReward,
			"Package Reward": item.packageCoin,
			"Claim Approval": item.claimStatus ? "Claimed" : "Pending",
		}));
		exportToExcel(formattedData, "RequestRewardData");
	};

	const resetFilters = (): void => {
		setSearch("");
		setLimit(10);
		setCurrentPage(1);
	};

	return (
		<>
			<div className="w-full h-auto px-3 mt-3 pb-3">
				<div className="w-full bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
					<div className="flex justify-between py-2 px-4">
						<div className="flex items-center gap-2">
							<input
								type="text"
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full sm:w-[200px] py-2 px-3"
								placeholder="Search.."
								style={{
									boxShadow:
										"0px 0px 7.6px 0px #00000040 inset",
								}}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</div>
						<div className="flex gap-2">
							<button
								onClick={resetFilters}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
								Reset
							</button>
							<button
								onClick={exportData}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
								Excel{" "}
								<RiFileExcel2Fill className="w-[20px] h-[20px]" />
							</button>
						</div>
					</div>

					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
									{["", "Name", "Contact", "Assign Package", "Contractor Reward", "Package Reward", "Claim Approval"].map((header) => (
										<th
											key={header}
											className="px-6 py-3 text-left text-sm font-medium text-gray-900"
										>
											{header}
										</th>
									))}
								</tr>
							</thead>
							<tbody className="bg-white">
								{isLoading ? (
									<TableLoading colCount={6} />
								) : data?.rewardData.length > 0 ? (
									data?.rewardData.map((reward: Reward) => (
										<tr
											key={reward._id}
										>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B] flex items-center">
												{reward.image ? (
													<img
														src={reward.image}
														alt={reward.image}
														className="w-12 h-12 object-cover rounded-full mr-3"
													/>
												) : (
													<span className="text-blue-500 w-12 h-12 rounded-full ring-4 ring-blue-500 mr-4 text-3xl font-bold flex justify-center items-center">
														{reward?.contractorName
															?.charAt(0)
															?.toUpperCase()}
													</span>
												)}

												{reward.contractorName}
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
												{reward.phoneNumber}
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B] flex items-center">
												<img
													src={reward.packageImage}
													alt={reward.packageName}
													className="w-12 h-12 object-cover rounded-full mr-3"
												/>
												{reward.packageName}
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
												<span className="flex items-center">
													<LiaCoinsSolid className="text-[#FF9900] text-3xl" />
													{
														reward.contractorTotalReward
													}
												</span>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
												<span className="flex items-center">
													<LiaCoinsSolid className="text-[#FF9900] text-3xl" />
													{reward.packageCoin}
												</span>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
												<button
													className="bg-blue-100 border text-blue-700 border-blue-700  px-3 py-2  rounded-xl hover:text-white hover:bg-blue-700 duration-300 text-sm"
													onClick={() =>
														handleApproveButton(
															reward._id
														)
													}>
													Approve
												</button>
											</td>
										</tr>
									))
								) : (
									<TableNoData
										colCount={6}
										text={"No rewards available."}
									/>
								)}
							</tbody>
						</table>
					</div>

					<div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleLimitChange}
							/>
							<p className="text-[#A6A8B1]">Items per page</p>
							<p className="text-[#666666]">
								Displaying{" "}
								{limit === "All"
									? "All"
									: `${startItemIndex} to ${endItemIndex}`}{" "}
								items
							</p>
						</div>
						<div className="flex flex-row gap-3">
							<p className="text-[#666666]">
								{currentPage} of{" "}
								{limit === "All" ? 1 : `${totalPages}`} pages
							</p>
							<div className="flex flex-row items-center gap-2 text-[#666666]">
								<span
									onClick={prevPage}
									className={`cursor-pointer ${currentPage === 1 &&
										"text-gray-400 cursor-not-allowed"
										}`}>
									<FaChevronLeft size={15} />
								</span>
								<span
									onClick={nextPage}
									className={`cursor-pointer ${currentPage === totalPages &&
										"text-gray-400 cursor-not-allowed"
										}`}>
									<FaChevronRight size={15} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConfirmModal
				isOpen={showPopup}
				handleConfirm={confirmApproval}
				handleClose={closePopup}
				text={"Are you sure you want to approve this reward?"}
				heading="Confirm Approval ?"
				buttonBgColor="bg-[#334155] hover:bg-opacity-90"
			/>
		</>
	);
};
