import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, Messaging, onMessage } from "firebase/messaging";
interface FirebaseConfig {
	apiKey: string;
	authDomain: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
	measurementId: string;
}

const firebaseConfig = {
	apiKey: "AIzaSyCrjor-ctw25P8uQ-Yu0LgHyq4BiDBA_3E",
	authDomain: "chawla-ispat.firebaseapp.com",
	projectId: "chawla-ispat",
	storageBucket: "chawla-ispat.firebasestorage.app",
	messagingSenderId: "211176113863",
	appId: "1:211176113863:web:eb125925ffc5402de920e2",
	measurementId: "G-8GNH1JMDFJ"
  };

const vapidKey ="BKFF4JuRF0rEhC55_3VZumncwrMUxOQgKfrbYFyjaCKxDd6J262xVBr8LDqNnktlhDCsRQ1tUo4rHvCmcd9bZjE";

const app: FirebaseApp = initializeApp(firebaseConfig);
const messaging: Messaging = getMessaging(app);

export const requestFCMToken = async (): Promise<string | null> => {
	try {
		const permission = await Notification.requestPermission();
		if (permission !== "granted") {
			console.warn("Notification permission denied.");
			return null;
		}
		const token = await getToken(messaging, { vapidKey });
		return token;
	} catch (error) {
		console.error("Error retrieving FCM token:", error);
		return null;
	}
};

export const onMessageListener = async (): Promise<MessagePayload | null> => {
	try {
		return await new Promise((resolve) => {
			onMessage(messaging, (payload) => {
				resolve(payload);
			});
		});
	} catch (error) {
		console.error("Error receiving foreground message:", error);
		return null;
	}
};

// const firebaseConfig: FirebaseConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
// 	// storageBucket: "fir-push-notification-77681.firebasestorage.app",
// 	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
// 	appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
// 	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
// };

// storageBucket: "fir-push-notification-77681.firebasestorage.app",