import { toast } from "react-toastify";
import * as XLSX from "xlsx";

export const exportToExcel = (data: [] | any, filename: string) => {
	if (!data || data.length === 0) {
		toast.error("No data available for export!", {
			position: "top-center",
			autoClose: 5000,
		});
		return;
	}
	const worksheet = XLSX.utils.json_to_sheet(data);

	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, `${filename}`);

	const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
	const blob = new Blob([wbout], { type: "application/octet-stream" });
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = url;
	link.download = `${filename || "noname"}.xlsx`;
	link.click();
	window.URL.revokeObjectURL(url);
};
