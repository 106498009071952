  import { createSlice } from "@reduxjs/toolkit";
  
  const popupSlice =  createSlice({
     name:"popup",
     initialState: false,
     reducers:{
        showpopup: state => true,
        closepopup: state => false,
        
     }
     
  })

  export const {showpopup,closepopup} = popupSlice.actions
  export default popupSlice.reducer;


