import { memo, useEffect } from 'react';
import Marquee from 'react-fast-marquee';  
import { useGetCompanyProductMutation } from '../../Redux/Api/Admin/Company';

interface Company {
  id: string;
  name: string;
  description: string;
  image: string;
  basicRate: number;
  productCount: number;
  publish: boolean;
  srNo: number;
}

interface SlidderProps {
  rtl?: boolean;
}

const Slidder = memo(({ rtl = false }:SlidderProps) => {
  const [mutation, { data: companyName }] = useGetCompanyProductMutation();

  useEffect(() => {
    mutation({
      category: [],
    });
  }, [mutation]);

  const validNamePattern = /^[A-Za-z\s()]+$/;

  const filteredCompanies = companyName?.data?.filter((company: Company) =>
    validNamePattern.test(company.name.trim())
  );

  return (
    <div className="max-w-full overflow-hidden">
      <Marquee direction={rtl ? 'right' : 'left'} speed={58} className="w-full">
        {filteredCompanies &&
          filteredCompanies.map((company: Company) => (
            <div key={company.id} className="inline-block mx-2">
              {company.image && (
                <img
                  src={company.image}
                  alt={`Company Image ${company.id}`}
                  className="w-[70px] h-[70px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] rounded-[6px] border object-contain"
                />
              )}
            </div>
          ))}
      </Marquee>
    </div>
  );
});

const Sliders = memo(() => {
  return (
    <div className="flex flex-col gap-6 justify-center py-2 items-center w-full overflow-hidden">
      <Slidder rtl={false} />
    </div>
  );
});

export default Sliders;



// import React, { useEffect, useRef } from 'react';
// // import { useGetCompanyProductQuery } from '../../Redux/Api/Admin/Company';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './ImageCarousel.css'; 

// import { useGetCompanyProductMutation } from '../../Redux/Api/Admin/Company';


// interface Company {
//   id: string;
//   name: string;
//   description: string;
//   image: string;
//   basicRate: number;
//   productCount: number;
//   publish: boolean;
//   srNo: number;
// }

// interface SlidderProps {
//   rtl?: boolean;
// }

// const Slidder: React.FC<SlidderProps> = React.memo(({rtl = false}) => {
//   const [mutation , {data:companyName}] = useGetCompanyProductMutation();

//   useEffect(() => {    
//     mutation({
//        category :[]
//      })
    
// },[mutation])

//   const settings = {
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 0,
//     speed: 1000,
//     cssEase: 'linear',
//     infinite: true,
//     variableWidth: true,
//     rtl: rtl,
//   };


//   const validNamePattern = /^[A-Za-z\s()]+$/;

//   const filteredCompanies = companyName?.data?.filter((company: Company) =>
//     validNamePattern.test(company.name.trim())
//   );

//   return (
//     <div className="max-w-full overflow-hidden">
//     <Slider {...settings}>
//       {filteredCompanies &&
//         filteredCompanies.map((company: Company, index: number) => (
//            <div key={company?.id}>
//             {company?.image && (
//               <img
//                 src={company?.image}
//                 alt={`Slide ${index}`}
//                 className="w-[100px] h-[100px] md:w-[120px] md:h-[120px] rounded-[6px] border"
//               />
//             )}
//          </div>
//         ))}
//      </Slider>
//     </div>
//   );
// });

// const Sliders = React.memo(() => {
//   return (
//     <div className="flex flex-col gap-6 justify-center py-2 items-center w-full overflow-hidden">
//       <Slidder rtl={false} />
//       {/* <Slidder rtl={true} /> */}
//     </div>
//   );
// });

// export default Sliders;



