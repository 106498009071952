import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useAllCategoriesQuery } from "../../../Redux/Api/Category";
import {
	useCreateImageURLMutation,
	useUploadImageMutation,
} from "../../../Redux/Api/Admin/Image";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ImageCarouselDetail {
	image: string;
	title: string;
}

interface FormState {
	imageCarouselDetails: ImageCarouselDetail[];
}

type FormAction =
	| { type: "SET_TITLE"; index: number; value: string }
	| { type: "SET_IMAGE"; index: number; value: string };

interface Category {
	id: string;
	name: string;
	companyCount: number;
	itemCount: number;
	image: string;
}

const formReducer = (state: FormState, action: FormAction): FormState => {
	switch (action.type) {
		case "SET_TITLE":
			const updatedDetails = [...state.imageCarouselDetails];
			updatedDetails[action.index].title = action.value;

			return { ...state, imageCarouselDetails: updatedDetails };

		case "SET_IMAGE":
			const updatedImages = [...state.imageCarouselDetails];
			updatedImages[action.index].image = action.value;

			return { ...state, imageCarouselDetails: updatedImages };

		default:
			return state;
	}
};

export const CategoryDetails = () => {
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const params = useParams();
	const { id } = params;
	const { data: allCategories } = useAllCategoriesQuery("");
	const [getImageURL, { data }] = useCreateImageURLMutation();
	const [formState, dispatch] = useReducer(formReducer, {
		imageCarouselDetails: [{ image: "", title: "" }],
	});
	const [uploadImage] = useUploadImageMutation();

	const CategoryToShow = allCategories?.data?.find(
		(category: Category) => category?.id === id
	);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0] || null;

		if (file) {
			setUploadedFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setImagePreview(reader.result as string);
			};
			reader.readAsDataURL(file);

			const body = new FormData();
			body.append("image", file);

			try {
				await getImageURL(body).unwrap();
			} catch (error) {
				console.error("Error uploading image:", error);
			}
		}
	};

	console.log(data);
	const triggerFileInput = () => {
		document.getElementById("imageInput")?.click();
	};

	useEffect(() => {
		if (data && data[0]) {
			dispatch({ type: "SET_IMAGE", index: 0, value: data[0] });
		}
	}, [data]);

	const handleTitleChange = (index: number, value: string) => {
		dispatch({ type: "SET_TITLE", index, value });
	};

	const validateForm = () => {
		const validationErrors: string[] = [];
		const { title, image } = formState.imageCarouselDetails[0];

		if (!title.trim()) {
			validationErrors.push("Title is required.");
		}
		if (!uploadedFile) {
			validationErrors.push("Image file is required.");
		}

		setErrors(validationErrors);

		return validationErrors.length === 0;
	};

	const onSubmit = async () => {
		if (validateForm()) {
			try {
				console.log(formState, id);
				await uploadImage({ formState, id });
				toast.success("Image carousel details updated successfully!");
			} catch (error) {
				console.error("Error uploading image:", error);
				toast.error(
					"Failed to update image carousel details. Please try again."
				);
			}
		} else {
			toast.error("file all the Fields.");
		}
	};

	return (
		<div className="px-9 py-9 font-sans flex flex-col gap-4 bg-white rounded-t-xl mx-3 mt-4 ">
			<div className="mb-6">
				<h2 className="text-3xl font-bold text-gray-800">
					{CategoryToShow?.name}
				</h2>
			</div>

			<div className="flex justify-between items-center ">
				<h3 className="text-xl font-semibold text-gray-700">
					Companies Available :{" "}
					<span className="text-blue-600">
						{CategoryToShow?.companyCount}
					</span>
				</h3>
				<h3 className="text-xl font-semibold text-gray-700">
					Items Available :{" "}
					<span className="text-blue-600">
						{CategoryToShow?.itemCount}
					</span>
				</h3>
			</div>
			<div className="flex justify-center  gap-6 flex-col ">
				<img
					src={CategoryToShow?.image}
					alt={CategoryToShow?.name}
					className="w-48 h-48 object-cover rounded-lg shadow-md"
				/>
				<button
					onClick={() => setIsDialogOpen(true)}
					className="px-6 py-3 bg-blue-600 w-48  text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out font-semibold">
					Add Image
				</button>
			</div>

			{isDialogOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
					<div className="bg-white rounded-lg p-8 relative w-[450px] shadow-xl">
						<button
							className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
							onClick={() => setIsDialogOpen(false)}>
							&times;
						</button>

						<div className="flex flex-col gap-6">
							<h3 className="font-bold text-2xl text-gray-800">
								Upload Photo
							</h3>

							<div className="w-full bg-gray-50 py-10 border-2 border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center">
								<div className="flex flex-col items-center text-center gap-3">
									<svg
										onClick={onSubmit}
										className="w-16 h-16 text-blue-600 cursor-pointer"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
										/>
									</svg>

									<div className="text-lg text-gray-600 font-medium">
										Upload Your Files
									</div>

									<div className="">
										{formState.imageCarouselDetails.map(
											(detail, index) => (
												<div key={index}>
													<input
														type="text"
														value={detail.title}
														onChange={(e) =>
															handleTitleChange(
																index,
																e.target.value
															)
														}
													/>
													{errors.includes(
														"Title is required."
													) && (
														<p className="text-red-500 text-sm">
															Title is required.
														</p>
													)}
												</div>
											)
										)}
									</div>
									<div
										className="text-blue-600 font-semibold underline cursor-pointer text-lg hover:text-blue-700"
										onClick={triggerFileInput}>
										Browse
									</div>
									<input
										type="file"
										id="imageInput"
										accept="image/*"
										onChange={handleFileChange}
										className="hidden"
									/>
								</div>

								{imagePreview && (
									<div className="mt-6">
										<img
											src={imagePreview}
											alt="Uploaded Preview"
											className="w-48 h-48 object-cover rounded-lg shadow-md"
										/>
									</div>
								)}

								{uploadedFile && (
									<div className="mt-4 text-center">
										<p className="text-gray-700 font-semibold">
											Selected File: {uploadedFile?.name}
										</p>
										<p className="text-gray-500">
											Size:{" "}
											{(
												uploadedFile?.size / 1024
											).toFixed(2)}{" "}
											KB
										</p>
									</div>
								)}
								{errors.includes("Image file is required.") && (
									<p className="text-red-500 text-sm">
										Image file is required.
									</p>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
