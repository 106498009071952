import defaultImage from "../../../assets/assets/dummyProduct.png";
import bgImage from "../../../assets/assets/reward-effect.png";
import { toast } from "react-toastify";
import { useReward } from "react-rewards";
interface Props {
    id: string;
    image?: string;
    name: string;
    itemCount: number;
    onNavigate: () => void;

}
const AssignedCategoryCard = ({
    image,
    name,
    itemCount,
    id,
    onNavigate,
}: Props) => {
    const { reward, isAnimating } = useReward(`reward-${id}`, "confetti");
    const handleNavigate = () => {
        if (itemCount > 0) {
            onNavigate();
        } else {
            toast.warn("Category has no items yet!");
        }
    };



    const handleMouseEnter = () => {
        if (!isAnimating) reward();
    };



    return (

        <div
            onClick={handleNavigate}
            onMouseEnter={() => {
                if (window.innerWidth > 640) {
                    handleMouseEnter();
                }
            }}

            className="relative cursor-pointer bg-[#F0FDFF] border-[3px] sm:hover:bg-[#F7D000] sm:hover:border-white group border-[#BFEBF3] rounded-md  sm:rounded-xl shadow-sm sm:shadow-md transform transition duration-500 sm:hover:scale-105 space-y-4 pt-3 pb-2 sm:pb-6 sm:pt-4 sm:px-3 px-1 ">
            <div
                className="p-1 sm:p-2 flex justify-center"
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: 'no-repeat'
                }}>
                <a href="#">
                    <img
                        className="rounded-md h-[50px] w-full sm:w-11/12 sm:h-[170px] object-contain"
                        src={image || defaultImage}
                        loading="lazy"
                    />
                </a>
            </div>
            <div className=" px-2 sm:px-4 pb-1 sm:pb-3 flex flex-col items-center mt-1 sm:mt-4">
                <div className="flex flex-col items-center" id={`reward-${id}`} >
                    <h5 id={`reward-${id}`} className="text-xs sm:text-xl font-semibold tracking-tight mx-auto    text-[#5DA4B1]  sm:group-hover:text-white">
                        {name?.length > 14
                            ? `${name.slice(0, 14)}...`
                            : name}
                    </h5>

                    <p className="antialiased text-[#5DA4B1] text-[10px] sm:text-sm break-all flex mt-1 sm:mt-2 justify-center sm:group-hover:text-white ">
                        <span className="font-semibold">
                            Item Count :{" "}
                        </span>{" "}
                        {itemCount}
                    </p>
                </div>
            </div>
        </div>

    );
};

export default AssignedCategoryCard;