import { motion } from 'framer-motion';
import React from 'react';
import bestChoice from '../../assets/bestChoice.png'

const SealAnimation = () => {
  return (
    <div className="relative h-screen z-50 overflow-hidden">

      <motion.div
        className="absolute bg-yellow-500 opacity-60 h-4 w-24 rounded-full"
        animate={{
          scale: [1, 1.5, 1], 
          opacity: [1, 0.5, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 1.5,
          ease: "easeInOut",
        }}
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
      />

      <motion.div
        className="absolute"
        initial={{ y: '-100%', scale: 0 }} 
        animate={{ y: '50%', scale: 1 }} 
        exit={{ scale: 0 }} 
        transition={{
          duration: 2, 
          ease: "easeInOut",
        }}
      >
        <img
          src={bestChoice}
          alt="Best Choice Seal"
          className="h-[8.25rem] w-[8.25rem] md:h-[13.25rem] md:w-[13.25rem] xl:h-[16.25rem] xl:w-[16.25rem] block"
        />
      </motion.div>
    </div>
  );
};

export default SealAnimation;
