import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { IoMdShare } from "react-icons/io";
import { GrEdit } from "react-icons/gr";
import { useRef, useState } from "react";
import { toPng } from "html-to-image";
import ChangePassword from "./ProfileModals/ChangePassword";
import ChangeUserInfo from "./ProfileModals/ChangeUserInfo";
import MobileProfileInfo from "./MobileProfileInfo";

const MyProfileInfo = () => {
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
    const [isEmail, setIsEmail] = useState<boolean>(false);
    const [isFullName, setIsFullName] = useState<boolean>(false);
    const qrRef = useRef<HTMLDivElement>(null);

    const referralCode = userDetails?.referralCode || "ihuiu";

    const handleShareToWhatsApp = async () => {
        if (qrRef.current) {
            const dataUrl = await toPng(qrRef.current);
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
                `Use my referral code: ${referralCode}`
            )}&url=${encodeURIComponent(dataUrl)}`;
            window.open(whatsappUrl, "_blank");
        }
    };

    const styles = {
        boxShadowStyle: {
            boxShadow: "0px 0.5px 7px 0px #00000040",
        },
    };
    return (

        <>
            <div className="w-full hidden my-auto py-4 sm:py-6 sm:flex flex-col sm:flex-row justify-between gap-2  max-w-[1536px] px-3 sm:px-10 sm:mt-4  ">
                <div className="w-full sm:w-[70%] xl:w-[65%] space-y-2">
                    <dl className="text-gray-900  border py-5 px-5 sm:px-3 rounded-xl shadow-md hover:bg-gray-100 duration-300">
                        <div className="flex gap-3 sm:gap-5 flex-col sm:flex-row text-sm 2xl:text-[18px]  ">
                            <dt className=" text-gray-500 w-[160px] dark:text-gray-400">
                                Full Name
                            </dt>
                            <dd className=" font-semibold w-full flex items-center">
                                {userDetails?.name}{" "}
                                <span
                                    onClick={() => setIsFullName(true)}
                                    className=" text-[#5097A4]  h-4 w-4 ml-auto flex items-center justify-center rounded-full cursor-pointer hover:text-gray-500">
                                    <GrEdit className="h-full w-full" />
                                </span>
                            </dd>
                        </div>
                    </dl>
                    <dl className="text-gray-900  border py-5 px-5 sm:px-3 rounded-xl shadow-md hover:bg-gray-100 duration-300">
                        <div className="flex gap-3 sm:gap-5 flex-col sm:flex-row text-sm 2xl:text-[18px]  ">
                            <dt className=" text-gray-500 w-[160px] dark:text-gray-400">
                                Phone Number
                            </dt>
                            <dd className=" font-semibold w-full flex items-center">
                                {userDetails?.phoneNumber}
                            </dd>
                        </div>
                    </dl>
                    <dl className="text-gray-900  border py-5 px-5 sm:px-3 rounded-xl shadow-md hover:bg-gray-100 duration-300">
                        <div className="flex gap-3 sm:gap-5 flex-col sm:flex-row text-sm 2xl:text-[18px]  ">
                            <dt className=" text-gray-500 w-[160px] dark:text-gray-400">
                                Email
                            </dt>
                            <dd className=" font-semibold w-full flex items-center">
                                {userDetails?.email}{" "}
                                <span
                                    onClick={() => setIsEmail(true)}
                                    className=" text-[#5097A4]  h-4 w-4 ml-auto flex items-center justify-center rounded-full cursor-pointer hover:text-gray-500">
                                    <GrEdit className="h-full w-full" />
                                </span>
                            </dd>
                        </div>
                    </dl>
                    <dl className="text-gray-900  border py-5 px-5 sm:px-3 rounded-xl shadow-md hover:bg-gray-100 duration-300">
                        <div className="flex gap-3 sm:gap-5 flex-col sm:flex-row text-sm 2xl:text-[18px]  ">
                            <dt className=" text-gray-500 w-[160px] dark:text-gray-400">
                                Password
                            </dt>
                            <dd className=" font-semibold w-full flex items-center">
                                ***********{" "}
                                <span
                                    onClick={() =>
                                        setIsChangePassword(true)
                                    }
                                    className=" text-[#5097A4]  h-4 w-4 ml-auto flex items-center justify-center rounded-full cursor-pointer hover:text-gray-500">
                                    <GrEdit className="h-full w-full" />
                                </span>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div className="w-full h-auto sm:w-[30%] xl:w-[40%] flex justify-end mt-3 sm:mt-0">
                    <div
                        className="w-full  flex flex-col  pb-3 sm:w-[200px] border-4 p-4 rounded-xl shadow-md border-[#5097A4] text-xs md:text-sm 2xl:text-[18px]  "
                        style={styles.boxShadowStyle}>
                        <div className="mb-1 text-gray-500 text-center  dark:text-gray-400">
                            Referral Code
                        </div>
                        <div
                            className="flex flex-col items-center w-full gap-1  "
                            ref={qrRef}>
                            <QRCode value={referralCode} size={128} />
                            <div className="flex justify-center w-full ">
                                {referralCode}
                            </div>
                        </div>
                        <div className="flex w-full gap-2 mt-auto items-center justify-center ">
                            <span
                                className="py-2 text-4xl cursor-pointer"
                                onClick={handleShareToWhatsApp}>
                                <IoMdShare className="h-4 w-5" />
                            </span>
                            <button
                                onClick={handleShareToWhatsApp}
                                className="text-green-600">
                                Share QR Code
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <MobileProfileInfo />


            {/* Change Password Popup   */}
            <ChangePassword
                isOpen={isChangePassword}
                setIsOpen={setIsChangePassword}
            />
            {/* Change Email , FullName */}
            <ChangeUserInfo
                isEmail={isEmail}
                setIsEmail={setIsEmail}
                isFullName={isFullName}
                setIsFullName={setIsFullName}
            />
        </>
    )
}

export default MyProfileInfo