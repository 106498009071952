import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, clearCategory } from "../../../Redux/reducer/Filter";
import { RootState } from "../../../Redux/store";

interface Categories {
  companyCount: number;
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

export const Filters = () => {
  const [displayCount, setDisplayCount] = useState<number>(5);
  const [formData, setFormData] = useState({ search: "", checkboxes: {} as Record<string, boolean> });

  const dispatch = useDispatch();
  const selectedCategories = useSelector((state: RootState) => state.selection.selectedCategories);
  const allCategories = useSelector((state: RootState) => state.values.allCategories);


  useEffect(() => {
    try {
      const updatedCheckboxes = selectedCategories.reduce(
        (acc: Record<string, boolean>, category: string) => {
          acc[category] = true;
          return acc;
        },
        {}
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkboxes: updatedCheckboxes,
      }));
    } catch (error) {
      console.error("Error updating checkboxes:", error);
    }
  }, [selectedCategories]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  },
    []
  );

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, categoryId: string) => {
      try {
        const { checked } = e.target;
        setFormData((prevData) => {
          const updatedCheckboxes = { ...prevData.checkboxes };
          if (checked) {
            dispatch(addCategory(categoryId));
            updatedCheckboxes[categoryId] = true;
          } else {
            dispatch(clearCategory(categoryId));
            delete updatedCheckboxes[categoryId];
          }
          return { ...prevData, checkboxes: updatedCheckboxes };
        });
      } catch (error) {
        console.error("Error handling checkbox change:", error);
      }
    },
    [dispatch]
  );

  const filteredCategories = React.useMemo(() => {
    if (!allCategories) return [];
    try {
      const regex = new RegExp(formData.search, "i");
      return allCategories.filter((category: Categories) =>
        regex.test(category.name)
      );
    } catch (error) {
      console.error("Error filtering categories:", error);
      return [];
    }
  }, [allCategories, formData.search]);

  const sortedCategories = React.useMemo(() => {
    const clickedCategories = filteredCategories.filter(
      (category) => formData.checkboxes[category.id]
    );
    const nonClickedCategories = filteredCategories.filter(
      (category) => !formData.checkboxes[category.id]
    );
    return [...clickedCategories, ...nonClickedCategories];
  }, [filteredCategories, formData.checkboxes]);

  const handleMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const isChecked = useCallback(
    (categoryId: string) => formData.checkboxes[categoryId] || false,
    [formData.checkboxes]
  );

  const categoriesToDisplay = sortedCategories.slice(0, displayCount);

  return (
    <div>
      <div className="flex flex-col gap-[0.6rem] ">
        <div className="flex flex-col gap-[0.6rem] mt-[16px]">
          <h1 className="font-[700] text-[14px] leading-[16px]">Category</h1>

          <div className="flex border items-center bg-[#F8FAFC] mr-3 gap-1 pl-1 py-1 rounded-[7px]">
            <IoSearch className="text-[#9CA3AF] h-[22px] w-[22px] pl-[4px]" />
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={formData?.search}
              onChange={handleInputChange}
              className="w-full font-inter text-[#9CA3AF] text-sm font-[500] leading-6 bg-[#F8FAFC] border-none focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
              style={{ outline: "none", boxShadow: "none" }}
            />
          </div>

          <div className="pl-[12px] flex flex-col gap-[4px]">
            {categoriesToDisplay?.length > 0 ? (
              categoriesToDisplay.map((category: Categories, index: number) => (
                <div
                  key={category?.id}
                  className="font-[500] text-[14px] leading-[16px]"
                >
                  <input
                    type="checkbox"
                    id={`myCheckbox${index}`}
                    name={`myCheckbox${index}`}
                    checked={isChecked(category?.id)}
                    onChange={(e) => handleCheckboxChange(e, category?.id)}
                    className="mr-2"
                  />
                  {category?.name}
                </div>
              ))
            ) : (
              <div>No categories available</div>
            )}
          </div>

          <div
            className="flex items-center gap-[3px] cursor-pointer"
            onClick={handleMoreClick}
          >
            <IoIosArrowDown />
            <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
              See more
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// import React, { useCallback, useEffect, useState } from "react";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoSearch } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
// import { addCategory, clearCategory } from "../../../Redux/reducer/Filter";
// import { RootState } from "../../../Redux/store";

// interface Categories {
//   companyCount: number;
//   id: string;
//   image: string;
//   itemCount: number;
//   name: string;
// }

// export const Filters = () => {
//   const [displayCount, setDisplayCount] = useState<number>(5);
//   const [formData, setFormData] = useState({
//     search: "",
//     checkboxes: {} as Record<string, boolean>,
//   });

//   const dispatch = useDispatch();
//   const selectedCategories = useSelector((state: RootState) => state.selection.selectedCategories);
//   const allCategories = useSelector((state: RootState) => state.values.allCategories );

//   // Effect to update checkboxes based on selected categories

//   useEffect(() => {
//     try {
//       const updatedCheckboxes = selectedCategories.reduce(
//         (acc: Record<string, boolean>, category: string) => {
//           acc[category] = true;
//           return acc;
//         },
//         {}
//       );

//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         checkboxes: updatedCheckboxes,
//       }));
//     } catch (error) {
//       console.error("Error updating checkboxes:", error);
//     }
//   }, [selectedCategories]);

//   // Handler for input field changes
//   const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
//       const { name, value } = e.target;
//       setFormData((prevData) => ({ ...prevData, [name]: value }));
//     },
//     []
//   );

//   // Handler for checkbox changes
//   const handleCheckboxChange = useCallback(
//     (e: React.ChangeEvent<HTMLInputElement>, categoryId: string) => {
//       try {
//         const { checked } = e.target;
//         setFormData((prevData) => {
//           const updatedCheckboxes = { ...prevData.checkboxes };
//           if (checked) {
//             dispatch(addCategory(categoryId));
//             updatedCheckboxes[categoryId] = true;
//           } else {
//             dispatch(clearCategory(categoryId));
//             delete updatedCheckboxes[categoryId];
//           }
//           return { ...prevData, checkboxes: updatedCheckboxes };
//         });
//       } catch (error) {
//         console.error("Error handling checkbox change:", error);
//       }
//     },
//     [dispatch]
//   );

//   const filteredCategories = React.useMemo(() => {
//     if (!allCategories) return [];
//     try {
//       const regex = new RegExp(formData.search, "i");
//       return allCategories.filter((category: Categories) =>
//         regex.test(category.name)
//       );
//     } catch (error) {
//       console.error("Error filtering categories:", error);
//       return [];
//     }
//   }, [allCategories, formData.search]);

//   const sortedCategories = React.useMemo(() => {
//     const clickedCategories = filteredCategories.filter(
//       (category) => formData.checkboxes[category.id]
//     );
//     const nonClickedCategories = filteredCategories.filter(
//       (category) => !formData.checkboxes[category.id]
//     );
//     return [...clickedCategories, ...nonClickedCategories];
//   }, [filteredCategories, formData.checkboxes]);

//   const handleMoreClick = () => {
//     setDisplayCount((prevCount) => prevCount + 5);
//   };

//   const isChecked = useCallback(
//     (categoryId: string) => formData.checkboxes[categoryId] || false,
//     [formData.checkboxes]
//   );

//   const categoriesToDisplay = sortedCategories.slice(0, displayCount);

//   return (
//     <div>
//       <div className="flex flex-col gap-[0.6rem] ">
//         <div className="flex flex-col gap-[0.6rem] mt-[16px]">
//           <h1 className="font-[700] text-[14px] leading-[16px]">Category</h1>

//           <div className="flex border items-center bg-[#F8FAFC] mr-3 gap-1 pl-1 py-1 rounded-[7px]">
//             <IoSearch className="text-[#9CA3AF] h-[22px] w-[22px] pl-[4px]" />
//             <input
//               type="text"
//               name="search"
//               placeholder="Search"
//               value={formData?.search}
//               onChange={handleInputChange}
//               className="w-full font-inter text-[#9CA3AF] text-sm font-[500] leading-6 bg-[#F8FAFC] border-none focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
//               style={{ outline: "none", boxShadow: "none" }}
//             />
//           </div>

//           <div className="pl-[12px] flex flex-col gap-[4px]">
//             {categoriesToDisplay?.length > 0 ? (
//               categoriesToDisplay.map((category: Categories, index: number) => (
//                 <div
//                   key={category?.id}
//                   className="font-[500] text-[14px] leading-[16px]"
//                 >
//                   <input
//                     type="checkbox"
//                     id={`myCheckbox${index}`}
//                     name={`myCheckbox${index}`}
//                     checked={isChecked(category?.id)}
//                     onChange={(e) => handleCheckboxChange(e, category?.id)}
//                     className="mr-2"
//                   />
//                   {category?.name}
//                 </div>
//               ))
//             ) : (
//               <div>No categories available</div>
//             )}
//           </div>

//           <div
//             className="flex items-center gap-[3px] cursor-pointer"
//             onClick={handleMoreClick}
//           >
//             <IoIosArrowDown />
//             <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
//               See more
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };


