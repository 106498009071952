import { IoArrowBack } from "react-icons/io5";
import shapeImage from "../../../assets/assets/singleProductShape.png";

interface ProductInfoTopProps {
    productName: string | undefined | null;
    isLoading: boolean;
    unit: string | undefined | null;
    handleBack:()=>void;
}

const ProductInfoTop = ({ productName, isLoading, unit,handleBack }: ProductInfoTopProps) => {
    return (
        <div className="">
            {isLoading ? (
                // Skeleton Loader
                <div className="px-3 py-2 flex flex-col gap-2 animate-pulse">
                    <div className="flex items-start gap-2">
                        <div className="w-[55px] h-[16px] bg-gray-300 rounded"></div>
                        <div className="w-32 h-[16px] bg-gray-300 rounded"></div>
                    </div>
                    <div className="w-full h-[16px] bg-gray-300 rounded"></div>
                </div>
            ) : (
                // Content
                <>
                    <div className="flex items-center gap-4 mb-2 p-2 bg-gray-50 shadow-sm border-b border-gray-200">
                        <button
                            className="p-2 rounded-full bg-gray-200 shadow-md hover:bg-gray-300 active:bg-gray-400 transition-all duration-200 "
                            onClick={() => handleBack()}
                        >
                            <IoArrowBack className="text-2xl text-gray-800" />
                        </button>

                        <h2 className="text-lg font-bold">Product Details</h2>
                    </div>

                    <div className="px-3 py-2  flex flex-col gap-2">

                        <div className="flex items-start gap-2 text-wrap">
                            <p
                                className="w-[55px] h-[16px] text-[11px] text-center flex items-center justify-center font-medium text-wrap"
                                style={{
                                    backgroundImage: `url(${shapeImage})`,
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <span>Product</span>
                            </p>
                            <span className="text-[11px] font-medium">
                                {productName || "Unknown Product"}
                            </span>
                        </div>
                        <div className="font-medium text-[11px] text-gray-500">
                            <span className="text-black">{`50+ ${unit}`}</span> Quantity Bought in past
                            month
                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

export default ProductInfoTop;
