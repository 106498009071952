import { motion } from "framer-motion";
import { buttonBorderAnimation, buttonStyle } from "./StyleComponent";
import { Link } from "react-router-dom";
const hoverEffect = {
	hover: { scale: 1.05, transition: { type: "spring", stiffness: 300 } },
};

const ButtonWithAnimation = ({ to, children, imgSrc, imgAlt, id }: any) => {
	return (
		<motion.div whileHover="hover" id={`${id}`} variants={hoverEffect}>
			<Link to={to} className={buttonStyle}>
				<div
					className="absolute inset-0 border-2 rounded-[4px]"
					style={{
						background:
							"linear-gradient(90deg, #FF9300, #FFF500, #FF9300)",
						backgroundSize: "200% 100%",
						animation: "borderMove 2s linear infinite",
					}}></div>
				<span className="relative z-10 flex items-center gap-2">
					{children}
					<img
						src={imgSrc}
						alt={imgAlt}
						className="h-[17px] w-[17px]"
					/>
				</span>
			</Link>
		</motion.div>
	);
};
export default ButtonWithAnimation

// CSS keyframe injection (use a style tag or inject into global CSS)
const style = document.createElement("style");
style.innerHTML = buttonBorderAnimation;
document.head.appendChild(style);