import React, { useEffect, useRef, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useChangePasswordOtpVerificationMutation, useChangePasswordtOtpSendMutation } from "../../../Redux/Api/Signup";


const encodePhoneNumber = (phone: string) => {
    if (phone?.length >= 10) {
        return `${phone.slice(0, 3)}****${phone.slice(-2)}`;
    }
    return phone;
};

const VerifyOTP: React.FC = () => {
    const location: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const { phoneNumber } = location?.state
    const [timer, setTimer] = useState(60);
    const [isResending, setIsResending] = useState(false);
    const timerInterval = useRef<NodeJS.Timeout | null>(null);
    const [mutation, { data, isLoading, isSuccess, isError, error }] = useChangePasswordOtpVerificationMutation();
    const [otpSendMutation, { data: otpSendData, isError: otpSendError, isSuccess: otpSendSuccuss, isLoading: otpSendLoading, error: otpError }] = useChangePasswordtOtpSendMutation();

    // ----------------------------Handle OTP Timer And Resend OTP Start------------------------

    const timerStart = localStorage.getItem("otpTimerStart2");

    useEffect(() => {
        if (!timerStart) {
            localStorage.removeItem("otpTimerStart2");
            setTimer(0);
        } else {
            const startTime = parseInt(timerStart);
            const elapsed = Math.floor((Date.now() - startTime) / 1000);
            const remaining = 60 - elapsed;

            if (remaining > 0) {
                setTimer(remaining);
                startTimer();
            } else {
                localStorage.removeItem("otpTimerStart2");
                setTimer(0);
            }
        }
    }, [timerStart]);

    const startTimer = () => {
        stopTimer();
        timerInterval.current = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(timerInterval.current!);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const stopTimer = () => {
        if (timerInterval.current) {
            clearInterval(timerInterval.current);
            timerInterval.current = null;
        }
    };

    const handleResendOTP = () => {
        if (timer === 0 && !isResending) {
            otpSendMutation({ phoneNumber });
        }
    };

    useEffect(() => {
        if (otpSendSuccuss) {
            toast.success(otpSendData?.message || "OTP sent successfully");
            setIsResending(true);
            const currentTime = Date.now();
            localStorage.setItem("otpTimerStart2", currentTime.toString());
            setTimer(60);
            startTimer();
            setIsResending(false);

        } else if (otpSendError) {
            toast.error(otpError?.data?.message || "An error occurred");
        }
    }, [otpSendError, otpSendSuccuss]);



    // ----------------------------Handle  OTP Verification-------------------------

    useEffect(() => {
        if (isSuccess) {
            const otp = inputsRef.current.map((input) => input?.value).join("");
            console.log("Navigating with OTP and phone number", phoneNumber, otp);
            toast.success(data?.message || "OTP Verified successfully!");
            navigate("/forgot-password", {
                state: {
                    phoneNumber,
                    otp
                }
            });
        }
        else if (isError) {
            toast.error(error?.data?.message || "Invalid OTP or an error occurred. Please try again.");
        }
    }, [isSuccess, isError]);


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const otp = inputsRef.current.map((input) => input?.value).join("");
        if (otp.length !== 6) {
            toast.error("Please enter a valid 6-digit OTP.");
            return;
        }
        await mutation({ phoneNumber, otp });
    };

    // ----------------------------Handle OTP Inputs -----------------------------

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const isPasting = useRef(false);

    useEffect(() => {
            const handleInput = (e: Event) => {
                if (isPasting.current) return;
                const target = e.target as HTMLInputElement;
                const index = inputsRef.current.indexOf(target);
    
                const length = target.value.length;
                if (length > 1) {
                    target.value = target.value.slice(0, 1);
                }
                target.setSelectionRange(length, length);
                if (target.value && index < inputsRef.current.length - 1) {
                    inputsRef.current[index + 1]?.focus();
                }
            };
    
            const handleKeyDown = (e: KeyboardEvent) => {
                const target = e.target as HTMLInputElement;
                const index = inputsRef.current.indexOf(target);
                const length = target.value.length;
    
                if (e.key === "Backspace") {
                    if (target.value === "" && index > 0) {
                        inputsRef.current[index - 1]?.focus();
                    }
                }
                target.setSelectionRange(length, length);
            };
    
            const handleFocus = (e: FocusEvent) => {
                const target = e.target as HTMLInputElement;
                if (isPasting.current) return;
                const length = target.value.length;
                target.setSelectionRange(length, length);
            };
    
            const handlePaste = (e: ClipboardEvent) => {
                e.preventDefault();
                const clipboardData = e.clipboardData?.getData("text") || "";
                const isValidOTP =
                    /^[0-9]+$/.test(clipboardData) && clipboardData.length === inputsRef.current.length;
    
                if (!isValidOTP) return;
    
                isPasting.current = true;
                const digits = clipboardData.split("");
                inputsRef.current.forEach((input, index) => {
                    if (input) {
                        input.value = digits[index] || "";
                    }
                });
    
                setTimeout(() => {
                    isPasting.current = false;
                    inputsRef.current.forEach((input, i) => {
                        if (input) {
                            if (i === inputsRef.current.length - 1) {
                                input.focus();
                            }
                            else {
                                input.blur();
                            }
                        }
                    });
                }, 0);
            };
    
            inputsRef.current.forEach((input) => {
                if (input) {
                    input.addEventListener("input", handleInput);
                    input.addEventListener("keydown", handleKeyDown);
                    input.addEventListener("focus", handleFocus);
                    input.addEventListener("paste", handlePaste);
                }
            });
    
            return () => {
                inputsRef.current.forEach((input) => {
                    if (input) {
                        input.removeEventListener("input", handleInput);
                        input.removeEventListener("keydown", handleKeyDown);
                        input.removeEventListener("focus", handleFocus);
                        input.removeEventListener("paste", handlePaste);
                    }
                });
            };
        }, []);


    useEffect(() => {
        if (!phoneNumber) {
            navigate("/signup", { replace: true });
        }
    }, [phoneNumber, navigate]);

    return (
        <main className="relative h-screen flex flex-col justify-center overflow-hidden"
            style={{
                background: "linear-gradient(0.32deg, #6DACBD 0.3%, #FFFFFF 99.74%)",
            }}
        >
            <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-12 sm:py-16">
                <div className="flex justify-center w-full">
                    <div className="max-w-md mx-auto text-center bg-white px-6 sm:px-8 py-10 rounded-2xl shadow-lg w-full">
                        <header className="mb-6">
                            <h1 className="text-2xl md:text-3xl font-bold text-slate-900 mb-3">OTP Verification</h1>
                            <p className="text-sm md:text-base text-slate-600">
                                Enter the 4-digit code sent to{" "} {" "} <br /> <span className="font-semibold">{encodePhoneNumber(phoneNumber)}</span>..
                            </p>
                        </header>
                        <form id="otp-form" onSubmit={handleSubmit}>
                            <div className="flex items-center justify-center gap-2 mb-6">
                                {[0, 1, 2, 3, 4, 5].map((_, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        ref={(el) => (inputsRef.current[index] = el)}
                                        className="w-10 h-10 sm:w-14 sm:h-14 text-center text-lg sm:text-xl md:text-2xl font-bold text-slate-900 bg-transparent border-b-4 border-gray-300 focus:border-[#5097A4] focus:outline-none transition-all duration-300 ease-in-out sm:focus:shadow-[0px_4px_10px_rgba(80,151,164,0.4)] focus:scale-110 placeholder-transparen"
                                    />
                                ))}
                            </div>


                            <div className="max-w-[260px] mx-auto mt-6">
                                <button
                                    disabled={isLoading}
                                    type="submit"
                                    className="w-full inline-flex justify-center items-center rounded-lg bg-gradient-to-r from-[#5097A4] to-[#76B4C5] px-4 py-3 text-sm md:text-base font-semibold text-white shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5097A4] transition-all duration-200"
                                >
                                    {isLoading ? (
                                        <span className="flex items-center">
                                            <FaSpinner className="animate-spin mr-2" />
                                            Verifying...
                                        </span>
                                    ) : (
                                        'Verify OTP'
                                    )}
                                </button>
                            </div>
                        </form>
                        <div className="text-sm text-slate-600 mt-4">
                            Didn&apos;t receive the code?{" "}
                            <button
                                onClick={handleResendOTP}
                                disabled={timer > 0 || isResending}
                                className={`font-medium ${timer > 0 || isResending ? "text-gray-400" : "text-[#5097A4]"
                                    } hover:text-[#76B4C5] transition-colors duration-200`}
                            >
                                Resend {timer > 0 && `in ${timer}s`}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    );
};

export default VerifyOTP;