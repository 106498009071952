import Sliders from "./Slidder";
import { Certificates } from "./Certificates";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";


import OurClients from "./components/OurClients";
import AboutSection from "./components/AboutSection";

const AboutUs = () => {
  const title =
    "About Us | Building Material Supplier | Chawla Ispat";
  const description =
    "Learn more about Chawla Ispat and our mission, values, and services.";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  return (
    <div className="font-poppins sm:font-inter bg-white mb-4">
      <div className="flex flex-col gap-[36px] w-full sm:w-[90%] mx-auto  ">
        <div className="px-auto  py-3 sm:py-[40px] flex flex-col items-center gap-[20px]  px-4 sm:px-0">
          <div className="flex flex-col items-start sm:items-center sm:gap-3">
            <h1
              className=" text-lg sm:text-3xl md:text-4xl font-bold sm:leading-[48.41px] sm:text-[#5097A4]"
              >
              About Us
            </h1>
            <p className="text-[#646464] font-medium text-[10px] sm:text-lg text-wrap text-start sm:text-center mt-1 sm:mt-0 sm:px-2 ">
              We offer instant online quotes, payment and ordering
              for high-quality, high custom metal parts –
              straight from our factory.
            </p>
          </div>
          <AboutSection />
          <OurClients />
        </div>
      </div>
      <div className="my-5 sm:my-[30px] md:my-[50px] mx-auto  sm:px-[30px] w-full md:w-full flex justify-center items-center ">
        <Sliders />
      </div>
      <div className="mx-auto  sm:px-[10px] w-full flex justify-center ">
        <Certificates />
      </div>
    </div>
  );
};

export default AboutUs;
