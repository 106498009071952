import React, { useEffect, useState } from "react";
import CustomModal from "../../../utils/CustomModal";
import { useCreateProductMutation, useGetUnitsQuery } from "../../../Redux/Api/priceAPI";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useNavigate } from "react-router-dom";

interface IQueryProps {
    onClose: () => void;
    isOpen: boolean;
    productId: string | undefined;
    number: number | string | undefined;
    unitId: string | null | undefined
}

const QuotationForm = ({ isOpen, onClose, productId, number,unitId }: IQueryProps) => {
    const { data } = useGetUnitsQuery();
    const navigate= useNavigate();
    const [createProductQuery, { isLoading ,isSuccess ,isError }] = useCreateProductMutation();
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const [formData, setFormData] = useState({
        quantity: "",
        unitId: localStorage.getItem("unitId") || "",
        contact: userDetails?.phoneNumber,
        // query: "",
        productId: "",
    });

    useEffect(() => {
        const storedUnitId = localStorage.getItem("unitId");
        setFormData((prev) => ({
            ...prev,
            productId: productId || prev.productId,
            unitId: unitId || storedUnitId || prev.unitId,
        }));
    
        if (productId) localStorage.setItem("productId", productId);
        if (unitId) localStorage.setItem("unitId", unitId);
    
        return () => {
            localStorage.removeItem("productId");
            localStorage.removeItem("unitId");
        };
    }, [productId, unitId]);
    
    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "contact" || name === "quantity") {
            setFormData((prev) => ({
                ...prev,
                [name]: value ? Number(value) : "",
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const validateForm = () => {
        const { quantity, unitId, contact } = formData;
        if (!quantity) return "Quantity is required.";
        if (!unitId) return "Unit selection is required.";
        if (!contact) return "Mobile number is required.";
        if (!/^\d{10}$/.test(contact)) {
            return "Mobile number must be 10 digits.";
        }

        return null;
    };

    useEffect(() => {
        if (isSuccess) {
          toast.success("Product quotation created successfully!");
          navigate("/profile/quotation-tracker")
        }
       
        if (isError) {
          toast.error("Error creating product quotation. Please try again.");
        }
      }, [ isSuccess, isError]);
      

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
      
        const error = validateForm();
        if (error) {
          toast.error(error);
          return;
        }
        try {
          await createProductQuery(formData).unwrap();
          onClose();
        } catch (error) {
          console.error("Error submitting product quotation:", error);
        }
      
        setFormData(() => ({
          quantity: "",
          unitId: unitId || "",
          contact: userDetails?.phoneNumber,
          productId: productId || "",
        }));
      };

    const resetForm = () => {
        const storedProductId = localStorage.getItem("productId");
        const storedUnitId = localStorage.getItem("unitId");
        setFormData({
            quantity: "",
            unitId: storedUnitId || "",
            contact: userDetails?.phoneNumber || "",
            // query: "",
            productId: storedProductId || "",
        });
    };
    

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <CustomModal isOpen={isOpen} onClose={handleClose} title="Query Form" width="max-w-md">
            <form className="flex flex-wrap w-full justify-between" onSubmit={handleSubmit}>
                {/* Quantity Field */}
                <div className="mb-3  w-full flex flex-col">
                    <label className="text-xs font-[400] mb-2" htmlFor="quantity">
                        Quantity<span className="text-red-500">*</span>
                    </label>
                    <div className="h-[40px] flex text-xs">
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity === "" ? "" : Number(formData.quantity)}
                            onChange={handleChange}
                            className="rounded-l-lg border-[1.5px] border-[#5097A4] px-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out w-[60%]"
                            placeholder="Quantity (Number)"
                        />
                        <select
                            name="unitId"
                            value={formData.unitId}
                            onChange={handleChange}
                            className="bg-[#5097A4] text-white rounded-r-lg border-[1.5px] border-[#5097A4] pl-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out w-[40%] pr-4 cursor-pointer"
                        >
                            <option className="bg-white text-black hover:bg-[#5097A4]" value="">
                                select..
                            </option>
                            {data && data.map((item: any) => (
                                <option
                                    key={item._id}
                                    className="bg-white text-black hover:bg-[#5097A4]"
                                    value={item._id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Contact Field */}
                <div className="  w-full flex flex-col text-xs">
                    <label className="text-xs font-[400] mb-2" htmlFor="mobileNumber">
                        Mobile Number<span className="text-red-500">*</span>
                    </label>
                    <div className="h-[40px] text-xs">
                        <input
                            type="number"
                            name="contact"
                            value={formData.contact === "" ? "" : Number(formData.contact)}
                            onChange={handleChange}
                            className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out"
                            placeholder="Enter mobile number"
                        />
                    </div>
                </div>

                {/* Query Field */}
                {/* <div className="mb-3 w-full flex flex-col">
                    <label className="text-xs font-[400] mb-2" htmlFor="query">
                        Query
                    </label>
                    <textarea
                        name="query"
                        rows={4}
                        value={formData.query}
                        onChange={handleChange}
                        className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out"
                        placeholder="Enter Query"
                    />
                    <span className="font-bold text-[11px] flex justify-end text-[#5097A4]">
                        “Your query will be resolved within 10 minutes, guaranteed assistance!”
                    </span>
                </div> */}

                <div className="flex justify-center mt-5 sm:mt-5 space-x-4 text-xs w-full">
                    <button
                        type="submit"
                        className="px-4 py-2 text-white bg-[#5097A4] rounded-lg hover:bg-[#47848e] transition duration-300 ease-in-out"
                    >
                        {isLoading ? "Submitting.." : "Submit"}
                    </button>
                </div>

            </form>
        </CustomModal>
    );
};

export default QuotationForm;
