import { RefObject, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = (ref?: RefObject<HTMLElement>) => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (ref?.current) {
			ref.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		} else {
			window.scrollTo(0, 0);
		}
	}, [pathname]);
};

export default useScrollToTop;
