import { CategoriesResponse, CategoryProductsResponse, CompaniesResponse } from  "../../types/api-types"
import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";

export const categoryQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({
       
      categoriesName: builder.query<CategoriesResponse, string>({
        query: () => {
          return {
            url: `${lhwpath}/global/getCategories/publish`,
            method: "GET",
          };
        }   
      }),  
      companiesName: builder.query<CompaniesResponse, string>({
        query: () => {
          return {
            url: `${lhwpath}/global/getCompanyList/publish`,
            method: "GET",
          };
        }  
      }),  
      categoryProduct: builder.query<CategoryProductsResponse, string>({
        query: (categoryId) => ({
          url: `/category/${categoryId}/products`,
          method: "GET",
        }),
      }),

       
    }),
  });
  
  
  
  export const { 
    useCategoriesNameQuery,
     useCompaniesNameQuery,
  } = categoryQuery