import React from "react"

type FiltersProps = {
    selectedDate: string,
    orderNumber: string,
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    resetFilters: () => void
}
const CoinHistoryFilters = ({ selectedDate, orderNumber, handleInputChange, resetFilters }: FiltersProps) => {
    return (
        <div className="flex flex-col sm:flex-row items-center gap-3 w-full sm:w-fit">
            <div className="w-full sm:max-w-[200px]">
                <input
                    type="text"
                    placeholder="Order Number..."
                    name="orderNumber"
                    value={orderNumber}
                    onChange={handleInputChange}
                    className="input-style"
                />
            </div>
            <div className="w-full sm:max-w-[200px]">
                <input
                    type="date"
                    name="selectedDate"
                    value={selectedDate}
                    onChange={handleInputChange}
                    className="input-style"
                />
            </div>

            <button
                onClick={resetFilters}
                className=" w-full justify-center sm:w-fit px-3 py-2 text-sm font-medium text-white inline-flex items-center bg-turkishBlue-default  focus:ring-4 focus:outline-none focus:ring-[#cff2f8] rounded-md text-center"
            >
                Reset
            </button>
        </div>

    )
}

export default CoinHistoryFilters