import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface DetailsSectionProps {
	highLowPrice: {
		lowFinalPrice: number;
		highFinalPrice: number;
	};
	description: string;
	unit: string | null;
	name: string;
	itemCode: string;
	companyName: string;
	isLoading: boolean;
	companyId: string;
	handleClick: () => void
}

const DetailsSection = ({
	highLowPrice,
	description,
	unit,
	name,
	itemCode,
	companyName,
	companyId,
	isLoading,
	handleClick,
}: DetailsSectionProps) => {
	const navigate = useNavigate();
	const handleItemClick = (_id?: string, name?: string | null) => {
		if (_id) {
			navigate(`/items/${_id}/company/${name}/`, { state: { company: _id } });
		} else {
			toast.warning("Something went wrong! Please try again.");
		}
	};
	return (
		<div className="w-full px-3.5 py-1.5 space-y-2">
			{isLoading ? (
				<div className="space-y-4 animate-pulse">
					{/* Price skeleton */}
					<div className="h-[26px] bg-gray-300 rounded w-3/4"></div>

					{/* Name skeleton */}
					<div className="h-[18px] bg-gray-300 rounded w-2/3"></div>

					{/* Description skeleton */}
					<div className="h-[12px] bg-gray-300 rounded w-full"></div>
					<div className="h-[12px] bg-gray-300 rounded w-4/5"></div>

					{/* Item code skeleton */}
					<div className="h-[12px] bg-gray-300 rounded w-1/3"></div>

					{/* Company skeleton */}
					<div className="h-[12px] bg-gray-300 rounded w-1/2"></div>

					{/* Quantity and Unit skeletons */}
					<div className="flex gap-2">
						<div className="h-[28px] bg-gray-300 rounded-full  max-w-[160px]"></div>
						<div className="h-[28px] bg-gray-300 rounded-full  max-w-[160px]"></div>
					</div>

					{/* Button skeleton */}
					<div className="h-[35px] bg-gray-300 rounded-full w-full"></div>
				</div>
			) : (
				<>
					<div className="font-bold text-[26px]">
						{highLowPrice?.lowFinalPrice !== undefined &&
							highLowPrice?.highFinalPrice !== undefined ? (
							`₹${Number(highLowPrice.lowFinalPrice).toFixed(
								2
							)} - ₹${Number(highLowPrice.highFinalPrice).toFixed(
								2
							)}/${unit}`
						) : (
							<span className="font-medium text-[10px] sm:text-[16px]">
								Price Not Available!
							</span>
						)}
					</div>
					<div className="w-full flex flex-col gap-1">
						<span className="font-medium text-[18px]">{name}</span>
						<div className="font-[400] text-[12px] text-[#747474] ">
							{description}
						</div>
						<div className="font-semibold text-[12px] mt-4">
							Item Code - {itemCode || 0}
						</div>
						<div className="font-semibold text-[12px] text-gray-500">
							Company -{" "}
							<span onClick={() => handleItemClick(companyId, companyName)} className="text-blue-500">{companyName}</span>
						</div>
					</div>
					<div className="w-full flex items-center gap-2 pt-4 pb-4 flex-wrap">
						{/* Quantity Select */}
						<div className="relative inline-flex items-center border rounded-full px-3 py-1 max-w-[160px] h-[28px] bg-[#ECECEC] flex-1">
							<label
								htmlFor="quantity"
								className="mr-2 font-medium text-[15px]">
								Quantity :
							</label>
							<select
								disabled
								id="quantity"
								className=" bg-transparent focus:outline-none cursor-pointer font-medium  flex-1 bg-[#ECECEC] text-[15px]">
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
							</select>
						</div>
						{/* Unit Select */}
						<div className="relative inline-flex items-center border rounded-full px-3 py-1 max-w-[160px] flex-1 bg-[#ECECEC] ">
							<label
								htmlFor="unit"
								className="mr-2 font-medium text-[15px]">
								Unit :
							</label>
							<select
								disabled
								id="unit"
								className=" bg-transparent focus:outline-none cursor-pointer font-medium text-[15px] flex-1 bg-[#ECECEC]">
								<option value="Ton">{`${unit}`}</option>

							</select>
						</div>
					</div>
					<button className="hidden  h-[35px] bg-[#FFA41C] rounded-full text-center w-full  text-[15px] font-medium">
						Confirm Order
					</button>
					<button onClick={handleClick} className=" h-[35px] bg-[#FFA41C] rounded-full text-center w-full  text-[15px] font-medium">
						Get Latest Price
					</button>
				</>
			)}
		</div>
	);
};

export default DetailsSection;
