import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import defaultImage from "../../../assets/defaultImage.svg"

// const dummyImages = [
//   "https://images.unsplash.com/photo-1485827404703-89b55fcc595e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
//   "https://plus.unsplash.com/premium_photo-1681426687411-21986b0626a8?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
//   "https://plus.unsplash.com/premium_photo-1683836722388-8643468c327d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
//   "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
//   "https://plus.unsplash.com/premium_photo-1663040543387-cb7c78c4f012?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
//   "https://images.unsplash.com/photo-1484807352052-23338990c6c6?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D",
// ];

const Imagesection = ({ singleProduct }: any) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const imagesToShow = 3;

  const newImageArray = Array(6).fill(singleProduct?.productImage[0] || defaultImage);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0
        ? Math.max(newImageArray.length - imagesToShow, 0)
        : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex >= newImageArray.length - imagesToShow
        ? 0
        : prevIndex + 1
    );
  };

  return (
    <div className="relative h-auto w-full md:w-[100%] xl:w-[70%] mt-8 xl:mt-">
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
        <button
          onClick={handlePrevClick}
          className="bg-black text-white rounded-full p-2 transition-transform transform hover:scale-110"
        >
          <FaAngleLeft />
        </button>
      </div>

      <div className="overflow-hidden relative w-[80%] mx-auto">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentImageIndex * (100 / imagesToShow)}%)`,
          }}
        >
          {newImageArray.map((image, index) => (
            <div key={index} className="flex-shrink-0 w-1/3 px-2 ">
              <img
                src={image}
                alt={`Product ${index}`}
                className=" h-[60px] w-[60px] sm:h-[80px] sm:w-[80px] mx-auto rounded-xl shadow-[1px_1px_20px_0px_#00000040] transition-transform duration-300 hover:scale-105"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <button
          onClick={handleNextClick}
          className="bg-black text-white rounded-full p-2 transition-transform transform hover:scale-110"
        >
          <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default Imagesection;

