import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiArrowLeftSLine } from "react-icons/ri";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
}
const ProfilePagination = ({ currentPage, totalPages, handlePageChange }: PaginationProps) => {
    return (
        <div className="flex justify-center my-4 text-[#5097A4] text-sm font-medium items-center mt-5">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 sm:ml-2 disabled:text-gray-300 flex items-center"
            >
                <RiArrowLeftSLine className="h-8 w-8" /> Previous
            </button>

            <span className="flex items-center justify-center space-x-3 text-gray-700">
                <span>Page</span>{' '}
                <span className="px-2 py-1 bg-gray-200 rounded-md">{currentPage} </span>{' '}
                <span>of</span>{' '}
                <span className="px-2 py-1 bg-gray-200 rounded-md">{totalPages}</span>
            </span>

            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 ml-2 disabled:text-gray-300 flex items-center"
            >
                Next <MdOutlineKeyboardArrowRight className="h-8 w-8" />
            </button>
        </div>
    )
}

export default ProfilePagination