

// import React, { useEffect, useState } from "react";
// import { VscSearch } from "react-icons/vsc";
// import { useCategoriesNameQuery } from "../../../../Redux/Api/NamesAPI";
// import { useDispatch, useSelector } from "react-redux";
// import { addCategory, clearCategory } from "../../../../Redux/reducer/Filter"; // Corrected import path
// import { closeCategoryFilter, toggleCategoryFilter } from "../../../../Redux/reducer/RequestReducer";
// import { RootState } from "../../../../Redux/store";
// import { Categories } from "../../types";
// import { useAdminCategoriesQuery } from "../../../../Redux/Api/Category";
// import { useLocation } from "react-router-dom";

// export const CategoryFilter = () => {
//   const [currentPage, setCurrentPage] = useState<number>(1);
//   const [limit, setLimit] = useState("");
//   const [search,setSearch] = useState<string >("")
//   const { data: categoriesResponse, refetch } = useAdminCategoriesQuery({
//     page: currentPage,
//     limit: limit,
//     search:search
//   });
//   // console.log(categoriesResponse,"categories")

//   const [formData, setFormData] = useState({
//     search: "",
//     checkboxes: {} as any,
//   });

//   const dispatch = useDispatch();
//   const selectedCategories = useSelector(
//     (state: RootState) => state.selection.selectedCategories
//   );

//   // useEffect to set checkboxes based on selectedCategories from Redux state
//   useEffect(() => {
//     const updatedCheckboxes = categoriesResponse?.result?.reduce(
//       (acc: any, category: Categories) => {
//         const isSelected = selectedCategories.includes(category.id);
//         acc[category.id] = isSelected;
//         return acc;
//       },
//       {}
//     );
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       checkboxes: updatedCheckboxes,
//     }));
//   }, [selectedCategories, categoriesResponse]);

//   // Handler for input change for search
//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handler for checkbox change
//   const handleCheckboxChange = (
//     e: React.ChangeEvent<HTMLInputElement>,
//     categoryId: string
//   ) => {
//     const { checked } = e.target;
//     const updatedCheckboxes = { ...formData.checkboxes };
//     const category = categoriesResponse?.result?.find(
//       (category: Categories) => category.id === categoryId
//     );

//     if (category) {
//       if (checked) {
//         dispatch(addCategory(category.id));
//         updatedCheckboxes[categoryId] = true;
//       } else {
//         dispatch(clearCategory(category.id));
//         updatedCheckboxes[categoryId] = false;
//       }
//     }

//     const updatedFormData = { ...formData, checkboxes: updatedCheckboxes };
//     setFormData(updatedFormData);
//   };

//   const handleCancel = () => {
//     dispatch(closeCategoryFilter());
//   };

//   const handleAdd = () => {
//     dispatch(toggleCategoryFilter());
//   };

//   // Filter categories based on search input
//   const filteredCategories = categoriesResponse
//     ? categoriesResponse?.result?.filter((category: Categories) => {
//         const regex = new RegExp(formData.search, "i");
//         return regex.test(category.name);
//       })
//     : [];

//     const handleAddAllCategories = () => {
//       categoriesResponse?.result.forEach((category: Categories) => {
//         dispatch(addCategory(category.id));
//       });
//     };

//   // Check if the checkbox should be checked
//   const isChecked = (category: string) => {
//     return formData.checkboxes?.[category] || false;
//   };

//   const location = useLocation();
//   const showAddAllCategoriesButton = location.pathname === '/admin/contractors/request';

//   console.log(filteredCategories)

//   return (
//     <div className="p-[10px]">
//       <div className="flex flex-col gap-[0.6rem]">
//         <div className="flex flex-col gap-[0.6rem] mt-[16px]">
//             <div className="flex justify-between w-full" >
//           <div className={`flex border items-center bg-white py-1 px-[12px] ${showAddAllCategoriesButton ? " w-[52%]" : "w-full"} rounded-[7px]`}>
//             <VscSearch size={18} className="text-[#9CA3AF] pl-[4px]" />
//             <input
//               type="text"
//               name="search"
//               placeholder="Search"
//               value={formData.search}
//               onChange={handleInputChange}
//               className="font-inter text-[#9CA3AF] ml-1 text-[16px] leading-[20px] bg-white border-[#D1D5DB] focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md w-full"
//               style={{ outline: "none", boxShadow: "none" }}
//             />
//           </div>
//           {showAddAllCategoriesButton &&
//            <div
//            className="px-[22px] py-[8px] bg-[#0078FB] text-[#FFF] border rounded-[4px] w-[45%] justify-center items-center flex cursor-pointer"
//           onClick={handleAddAllCategories}
//          >
//            Add All Categories
//          </div>
//           }

//             </div>

//           <div className="pl-[12px] flex flex-col gap-[4px] h-[150px] overflow-y-auto border p-2">
//             {filteredCategories?.length > 0 ? (
//               filteredCategories.map((category: Categories, index: number) => (
//                 <div
//                   key={index}
//                   className="font-[500] text-[14px] leading-[16px] flex justify-between items-center"
//                 >
//                   <span>{category.name}</span>
//                   <input
//                     type="checkbox"
//                     id={`myCheckbox${index}`}
//                     name={`myCheckbox${index}`}
//                     checked={isChecked(category.id)}
//                     onChange={(e) => handleCheckboxChange(e, category.id)}
//                     className="mr-2  focus:bg-[#334155] p-1 "
//                   />
//                 </div>
//               ))
//             ) : (
//               <div>No categories available</div>
//             )}
//           </div>
//           <div className="px-[24px] py-[12px] flex justify-between">
//             <div
//               className="px-[22px] py-[8px] border-[#0078FB] text-[#0078FB] border rounded-[4px] w-[45%] justify-center items-center flex cursor-pointer"
//               onClick={handleCancel}
//             >
//               Cancel
//             </div>

//             <div
//               className="px-[22px] py-[8px] bg-[#0078FB] text-[#FFF] border rounded-[4px] w-[45%] justify-center items-center flex cursor-pointer"
//               onClick={handleAdd}
//             >
//               Add
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };




import React, { useEffect, useState } from "react";
import { VscSearch } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { addCategoryPrice, clearCategoryPrice } from "../../../../Redux/reducer/Filter";
import {
	closeCategoryFilter,
	toggleCategoryFilter,
} from "../../../../Redux/reducer/RequestReducer";
import { RootState } from "../../../../Redux/store";
import { Categories } from "../../types";
import { useAdminCategoriesQuery } from "../../../../Redux/Api/Category";
import { useLocation } from "react-router-dom";
import { BiPlus } from "react-icons/bi";

export const ContractorCategoryPriceFilter = () => {
	const { data: categoriesResponse, isLoading } = useAdminCategoriesQuery({
		page: 1,
		limit: "",
		search: ""
	});


	const [formData, setFormData] = useState({ search: "", checkboxes: {} as any });

	const dispatch = useDispatch();
	const selectedCategories = useSelector((state: RootState) => state.selection.selectedCategoriesPrice);

	useEffect(() => {
		const updatedCheckboxes = categoriesResponse?.result?.reduce(
			(acc: any, category: Categories) => {
				const isSelected = selectedCategories.includes(category.id);
				acc[category.id] = isSelected;
				return acc;
			},
			{}
		);
		setFormData((prevFormData) => ({
			...prevFormData,
			checkboxes: updatedCheckboxes,
		}));
	}, [selectedCategories, categoriesResponse]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};


	const handleCheckboxChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		categoryId: string
	) => {
		const { checked } = e.target;
		const updatedCheckboxes = { ...formData.checkboxes };
		const category = categoriesResponse?.result?.find(
			(category: Categories) => category.id === categoryId
		);

		if (category) {
			if (checked) {
				dispatch(addCategoryPrice(category.id));
				updatedCheckboxes[categoryId] = true;
			} else {
				dispatch(clearCategoryPrice(category.id));
				updatedCheckboxes[categoryId] = false;
			}
		}

		const updatedFormData = { ...formData, checkboxes: updatedCheckboxes };
		setFormData(updatedFormData);
	};

	const handleCancel = () => {
		dispatch(closeCategoryFilter());
	};

	const handleAdd = () => {
		dispatch(toggleCategoryFilter());
	};

	const filteredCategories = categoriesResponse
		? categoriesResponse?.result?.filter((category: Categories) => {
			const regex = new RegExp(formData.search, "i");
			return regex.test(category.name);
		})
		: [];

	const handleAddAllCategories = () => {
		categoriesResponse?.result.forEach((category: Categories) => {
			dispatch(addCategoryPrice(category.id));
		});
	};

    const isChecked = (category: string) => {
		return formData.checkboxes?.[category] || false;
	};

	const location = useLocation();
	const showAddAllCategoriesButton = location.pathname === "/admin/contractors/request";



	return (
		<div className="p-[10px]">
			<div className="flex flex-col gap-[0.6rem]">
				<div className="flex flex-col gap-[0.6rem] mt-[16px]">
					<div className="flex justify-between w-full">
						<div
							className={`flex border items-center bg-white py-1 mr-2 px-[12px] ${showAddAllCategoriesButton
								? " w-[80%]"
								: "w-full"
								} rounded-[7px]`}>
							<VscSearch
								size={18}
								className="text-[#9CA3AF] pl-[4px]"
							/>
							<input
								type="text"
								name="search"
								placeholder="Search Categories Price.."
								value={formData.search}
								onChange={handleInputChange}
								className="font-inter text-[#9CA3AF] ml-1 text-[16px] leading-[20px] bg-white border-[#D1D5DB] focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md w-full"
								style={{ outline: "none", boxShadow: "none" }}
							/>
						</div>
						{showAddAllCategoriesButton && (
							<div
								className="px-3 py-[8px] bg-[#334155] text-[#FFF] border rounded-[4px]  justify-center items-center flex cursor-pointer"
								onClick={handleAddAllCategories}>
								<BiPlus className="text-2xl mr-1" /> All
							</div>
						)}
					</div>

					<div className="pl-[12px] flex flex-col gap-[4px] h-[150px] overflow-y-auto scrollbar-customXy border p-2">
						{isLoading ? (
							<div className="text-center py-4">Loading categories...</div>
						) : filteredCategories?.length > 0 ? (
							filteredCategories.map((category: Categories, index: number) => (
								<div
									key={index}
									className="font-[500] text-[14px] leading-[16px] flex justify-between items-center"
								>
									<span>{category.name}</span>
									<input
										type="checkbox"
										id={`myCheckbox${index}`}
										name={`myCheckbox${index}`}
										checked={isChecked(category.id)}
										onChange={(e) => handleCheckboxChange(e, category.id)}
										className="mr-2 focus:bg-[#334155] p-1"
									/>
								</div>
							))
						) : (
							<div className="text-center py-4 text-red-500" >No categories available</div>
						)}

					</div>
				</div>
			</div>
		</div>
	);
};
