import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import QuotationTabs from "./QuotationTrackerComponents/QuotationTabs";
import checkedPng from "../../../assets/profile/quotationTrackerImages/Checked.png";
import failedPng from "../../../assets/profile/quotationTrackerImages/Failed.png";
import pendingPng from "../../../assets/profile/quotationTrackerImages/Pending.png";
import waitPng from "../../../assets/profile/quotationTrackerImages/wait.png";
import { CustomPopupModal } from "../../../utils/CustomModal";
import { useGetProductQuotationQuery } from "../../../Redux/Api/Query";
import { ProductQuery } from "./profileTyps";
import QuotationTrackerData from "./QuotationTrackerComponents/QuotationTrackerData";
import ProfileNoData from "./ProfileNoData";
import ProfilePagesLoading from "./ProfilePagesLoading";
import ProfilePagination from "./ProfilePagination";
import { formatDateTime } from "../../../utils/formatDateTime";
import ProfileHeading from "./ProfileHeading";

interface Counts {
	rejected: number;
	pending: number;
	completed: number;
	all: number;
}
const QuotationTracker = () => {
	const [isOpenMpdal, setIsOpenModal] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<{
		price: number;
		validity: string;
	} | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [itemsPerPage] = useState<number>(5);
	const [activeFilter, setActiveFilter] = useState("all");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { data, isFetching } = useGetProductQuotationQuery(
		{
			status: activeFilter === "all" ? "" : activeFilter,
			page: currentPage,
			limit: itemsPerPage,
		},
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		if (isFetching) setIsLoading(true);
		else setIsLoading(false);
	}, [isFetching]);

	const counts: Counts = data?.counts;
	const filterConditions = [
		{ label: "all", value: counts?.all || 0 },
		{ label: "pending", value: counts?.pending || 0 },
		{ label: "rejected", value: counts?.rejected || 0 },
		{ label: "completed", value: counts?.completed || 0 },
	];

	const handleFilterClick = (condition: string) => {
		setActiveFilter(condition);
		setCurrentPage(1);
	};

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	const totalPages = data ? Math.ceil(data?.total / itemsPerPage) : 1;

	const getStatusImages = (status: string) => {
		switch (status) {
			case "pending":
				return pendingPng;
			case "rejected":
				return failedPng;
			case "completed":
				return checkedPng;
			default:
				return waitPng;
		}
	};

	const getNoDataMessage = () => {
		if (activeFilter === "all") return "No queries available yet !";
		if (activeFilter === "pending") return "No pending queries found !";
		if (activeFilter === "rejected") return "No rejected queries found !";
		if (activeFilter === "completed")
			return "No completed queries available !";
	};

	const handleModalOpen = (price: number | null, validity: string | null) => {
		if (price !== null) {
			setSelectedItem({ price, validity: validity || "Not specified" });
			setIsOpenModal(true);
		} else {
			toast.warn("Price data is not available for this product");
		}
	};

	const handleClose = () => {
		setIsOpenModal(false);
		setSelectedItem(null);
	};
	return (
		<>
			<ProfileHeading heading="Quotation Tracker" />
			<div className="profile-container relative  sm:font-inter ">
				<div className="flex flex-col xl:flex-row items-start  gap-5  xl:justify-between mb-5">
					<div className="flex flex-col sm:flex-row gap-2 w-full mr-auto xl:ml-auto "></div>
					<div className=" bg-gray-100 sm:bg-white font-poppins sm:font-inter py-2 px-2 sm:px-1 sm:py-1 flex flex-row gap-1 sm:gap-2 items-center  w-full md:w-fit rounded-full sm:rounded-md   sm:border-[1.5px] border-[#5097A4]  ">
						{filterConditions.map((condition) => (
							<QuotationTabs
								key={condition.label}
								condition={condition.label}
								isActive={activeFilter === condition.label}
								onClick={handleFilterClick}
								count={condition.value}
							/>
						))}
					</div>
				</div>

				{isLoading ? (
					<ProfilePagesLoading />
				) : data?.data?.length > 0 ? (
					data?.data?.map((item: ProductQuery, index: number) => {
						return (
							<QuotationTrackerData
								key={index}
								item={item}
								getStatusImages={getStatusImages}
								handleModalOpen={handleModalOpen}
							/>
						);
					})
				) : (
					<ProfileNoData text={getNoDataMessage()} />
				)}
				{!isFetching && data?.data?.length > 0 && (
					<ProfilePagination
						currentPage={currentPage}
						totalPages={totalPages}
						handlePageChange={handlePageChange}
					/>
				)}
				<CustomPopupModal
					isOpen={isOpenMpdal}
					onClose={handleClose}
					title={"Price & Validity"}
					width="md:max-w-sm">
					{selectedItem ? (
						<div className="p-4 border rounded-xl">
							<div className="flex justify-between mb-2">
								<span className="font-semibold">Price:</span>
								<span className="text-right text-green-500">{`₹${selectedItem.price}`}</span>
							</div>
							<div className="flex justify-between">
								<span className="font-semibold">Validity:</span>
								<span className="text-right">
									{
										formatDateTime(selectedItem.validity)
									}
								</span>
							</div>
						</div>
					) : (
						<div className="text-center">Loading...</div>
					)}
				</CustomPopupModal>
			</div>

		</>
	);
};

export default QuotationTracker;
