import { useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAdminCategoriesQuery } from "../../../Redux/Api/Category";
import useDebounce from "../../../common/hooks/useDebounce";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { exportToExcel } from "../../../utils/exportToExcel";

export const RewardSytem = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [limit, setLimit] = useState<number | "All">(15);
	const [search, setSearch] = useState<string>("");
	const debounceSearch = useDebounce(search, 300);
	const {
		data: categoryResponse,
		isLoading,
	} = useAdminCategoriesQuery({
		page: currentPage,
		limit: limit === "All" ? undefined : limit,
		search: debounceSearch,
	});

	const navigate = useNavigate();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const options = ["10", "9", "8", "All"];

	const handleSelect = (option: string) => {
		const numericLimit = option === "All" ? "All" : parseInt(option);
		setLimit(numericLimit);
		setCurrentPage(1); 
	};

	const prevPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	const nextPage = () => {
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};

	const handleCategoryName = (name: string, id: string) => {
		navigate(`/admin/category/view/${name}`, {
			state: { name, id },
		});
	};

	const totalItems = categoryResponse?.total || 0;
	const totalPages = limit === "All" ? 1 : Math.ceil(totalItems / Number(limit));
	const startItemIndex = (currentPage - 1) * (limit === "All" ? totalItems : limit) + 1;
	const endItemIndex = Math.min(currentPage * (limit === "All" ? totalItems : limit), totalItems);

	const resetAll = () => {
		setCurrentPage(1);
		setLimit(15);
		setSearch("");
	};

	const exportData = () => {
		const filteredData = (categoryResponse?.result || []).map(
			(item: any, index: number) => ({
				"S.No": index + 1,
				"Category": item.name,
				"Product Name": item.phoneNumber,
				"Reward": "50", 
				"Status": "active", 
			})
		);
		exportToExcel(filteredData, "RewardSystemData");
	};

	return (
		<div className="w-full h-auto px-3 mt-3 pb-3">
			<div className="w-full bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
				{/* Filters */}
				<div className="flex justify-between py-2 px-4">
					<div className="flex items-center gap-2">
						<input
							type="text"
							onChange={handleChange}
							value={search}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
							placeholder="Search.."
							style={{
								boxShadow: "0px 0px 7.6px 0px #00000040 inset",
							}}
						/>
					</div>
					<div className="flex gap-2">
						<button
							onClick={resetAll}
							className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white hover:bg-red-500 duration-150">
							Reset
						</button>
						<button
							onClick={exportData}
							className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white hover:bg-green-500 duration-150">
							Excel <RiFileExcel2Fill className="w-[20px] h-[20px]" />
						</button>
					</div>
				</div>

				{/* Table */}
				<div className="w-full overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
						<thead className="bg-white divide-y divide-gray-200">
							<tr className="border-t">
								<th className="w-16"></th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">Category</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">Product Name</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">Reward</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">Status</th>
							</tr>
						</thead>
						<tbody className="bg-white">
							{isLoading ? (
								<TableLoading colCount={4} />
							) : categoryResponse?.result?.length > 0 ? (
								categoryResponse.result.map((i: any, index: number) => (
									<tr key={i?._id || index}>
										<td></td>
										<td
											className="px-6 py-4 whitespace-nowrap font-inter text-sm cursor-pointer underline text-blue-400"
											onClick={() => handleCategoryName(i?.name, i?.id)}>
											{i?.name}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i?.phoneNumber} jk lakshmi
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											50
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											Active
										</td>
									</tr>
								))
							) : (
								<TableNoData colCount={4} text="Data Not Available" />
							)}
						</tbody>
					</table>
				</div>

				{/* Pagination */}
				<div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
					<div className="flex flex-row gap-3">
						<Dropdown options={options} onSelect={handleSelect} />
						<p className="text-[#A6A8B1]">Items per page</p>
						<p className="text-[#666666]">
							Displaying {limit === "All" ? "All" : `${startItemIndex} to ${endItemIndex}`} items
						</p>
					</div>
					<div className="flex flex-row gap-3">
						<p className="text-[#666666]">
							{currentPage} of {limit === "All" ? 1 : `${totalPages}`} pages
						</p>
						<div className="flex flex-row items-center gap-2 text-[#666666]">
							<span
								onClick={prevPage}
								className={`cursor-pointer ${currentPage === 1 && "text-gray-400 cursor-not-allowed"}`}>
								<FaChevronLeft size={15} />
							</span>
							<span
								onClick={nextPage}
								className={`cursor-pointer ${currentPage === totalPages && "text-gray-400 cursor-not-allowed"}`}>
								<FaChevronRight size={15} />
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


// import { useState } from "react";

// import Dropdown from "../Inventory/components/Dropdown";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// import { RiFileExcel2Fill } from "react-icons/ri";

// import { useNavigate } from "react-router-dom";

// import { useAdminCategoriesQuery } from "../../../Redux/Api/Category";
// import useDebounce from "../../../common/hooks/useDebounce";
// import TableLoading from "../../../common/admin-common/TableLoading";
// import TableNoData from "../../../common/admin-common/TableNoData";
// import { exportToExcel } from "../../../utils/exportToExcel";

// export const RewardSytem = () => {
// 	const [currentPage, setCurrentPage] = useState<number>(1);
// 	const [limit, setLimit] = useState("15");
// 	const [search, setSearch] = useState<string>("");
// 	const debounceSearch = useDebounce(search, 300);
// 	const {
// 		data: categoryResponse,
// 		refetch,
// 		isLoading,
// 	} = useAdminCategoriesQuery({
// 		page: currentPage,
// 		limit: limit === "All" ? undefined : limit,
// 		search: debounceSearch,
// 	});

// 	const navigate = useNavigate();

// 	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
// 		let value = event.target.value;
// 		setSearch(value);
// 	};

// 	const options = ["9", "8", "7", "All"];

// 	const handleSelect = (option: any) => {
// 		setLimit(option === "All" ? "All" : option);
// 	};

// 	const prevPage = () => {
// 		const nextPageNumber = currentPage - 1;
// 		if (nextPageNumber >= 1) {
// 			setCurrentPage(nextPageNumber);
// 		}
// 	};

// 	const nextPage = () => {
// 		const nextPageNumber = currentPage + 1;
// 		setCurrentPage(nextPageNumber);
// 	};

// 	const handleCategoryName = (name: any, id: any) => {
// 		navigate(`/admin/category/view/${name}`, {
// 			state: { name: name, id: id },
// 		});
// 	};

// 	const totalPages = Math.ceil(
// 		(categoryResponse?.total || 0) / parseInt(limit)
// 	);

// 	const startItemIndex = (currentPage - 1) * parseInt(limit) + 1;
// 	const endItemIndex = Math.min(
// 		currentPage * parseInt(limit),
// 		categoryResponse?.total || 0
// 	);

// 	const resetAll = () => {
// 		setCurrentPage(1);
// 		setLimit("15");
// 		setSearch("");
// 	};

// 	const exportData = () => {
// 		const filteredData = (categoryResponse?.result || []).map(
// 		  (item: any, index: number) => ({
// 			"S.No": index + 1,
// 			"Category": item.name,
// 			"Product Name": item.phoneNumber,
// 			"Reward": "50", // Adjust as needed for reward data
// 			"Status": "active", // Adjust as needed for status data
// 		  })
// 		);
	
// 		exportToExcel(filteredData, "RewardSystemData");
// 	  };

// 	return (
// 		<>
// 			<div className="w-full h-auto px-3 mt-3 pb-3">
// 				<div className="w-full   bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
// 					{/* Filters Start */}
// 					<div className="flex justify-between py-2 px-4">
// 						<div className="flex items-center gap-2">
// 							<input
// 								type="text"
// 								onChange={handleChange}
// 								value={search}
// 								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
// 								placeholder="Search.."
// 								style={{
// 									boxShadow:
// 										"0px 0px 7.6px 0px #00000040 inset",
// 								}}
// 							/>
// 						</div>
// 						<div className="flex gap-2">
// 							<button
// 								onClick={resetAll}
// 								className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
// 								Reset
// 							</button>
// 							<button
// 								onClick={exportData}
// 								className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
// 								Excel{" "}
// 								<RiFileExcel2Fill className="w-[20px] h-[20px]" />
// 							</button>
// 						</div>
// 					</div>
// 					{/* Filters End */}
// 					{/* Table Start */}
// 					<div className="w-full overflow-x-auto">
// 						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
// 							<thead className="bg-white divide-y divide-gray-200">
// 								<tr className="border-t">
// 									<th className="w-16"></th>
// 									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
// 										Category
// 									</th>
// 									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
// 										Product Name
// 									</th>
// 									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
// 										Reward
// 									</th>
// 									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
// 										Status
// 									</th>
// 								</tr>
// 							</thead>
// 							<tbody className="bg-white ">
// 								{isLoading ? (
// 									<tr>
// 										<TableLoading colCount={4} />
// 									</tr>
// 								) : !isLoading &&
// 								  categoryResponse?.result?.length > 0 ? (
// 									categoryResponse.result.map(
// 										(i: any, index: number) => (
// 											<tr key={i?._id || index}>
// 												<td></td>
// 												<td
// 													className="px-6 py-4 whitespace-nowrap font-inter text-sm cursor-pointer underline text-blue-400"
// 													onClick={() =>
// 														handleCategoryName(
// 															i?.name,
// 															i?.id
// 														)
// 													}>
// 													{i?.name}
// 												</td>
// 												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
// 													{i?.phoneNumber} jk lakshmi
// 												</td>
// 												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
// 													50
// 												</td>
// 												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
// 													active
// 												</td>
// 											</tr>
// 										)
// 									)
// 								) : (
// 									<TableNoData
// 										colCount={4}
// 										text="Data Not Available"
// 									/>
// 								)}
// 							</tbody>
// 						</table>
// 					</div>
// 					{/* Table End */}

// 					{/* Pagination Start*/}
// 					<div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
// 						{/* Fist div */}
// 						<div>
// 							<div className="flex flex-row gap-3 ">
// 								<Dropdown
// 									options={options}
// 									onSelect={handleSelect}
// 								/>
// 								<p className="text-[#A6A8B1]">Items per page</p>
// 								{/* <p className="text-[#666666]">  {categoryResponse?.total} Total Categories </p> */}
// 								<p className="text-[#666666]">
// 									Displaying{" "}
// 									{limit === "All"
// 										? "All"
// 										: `${startItemIndex} to ${endItemIndex}`}{" "}
// 									items
// 								</p>
// 							</div>
// 						</div>
// 						{/* Second div */}
// 						<div>
// 							<div className="flex flex-row gap-3">
// 								<p className="text-[#666666]">
// 									{currentPage} of{" "}
// 									{limit === "All" ? 1 : `${totalPages}`}{" "}
// 									pages
// 								</p>
// 								<div className="flex flex-row items-center  gap-2 text-[#666666]">
// 									<span
// 										onClick={prevPage}
// 										className="cursor-pointer">
// 										<FaChevronLeft size={15} />
// 									</span>
// 									<span
// 										onClick={nextPage}
// 										className="cursor-pointer">
// 										<FaChevronRight size={15} />
// 									</span>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 					{/* Pagination End*/}
// 				</div>
// 			</div>
// 		</>
// 	);
// };
