import deletedPng from "../../assets/deletedPng.png";
import Danger from "../../assets/Warning-icon.-The-attention-icon.png";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
const DeleteAccountResponse = () => {
	return (
		<div className="w-full max-w-[1540px] h-auto mx-auto flex justify-center items-center relative">
			<div className="flex flex-col items-center justify-center  w-[80%] md:w-[50%] my-12 gap-y-3 ">
				<img
					src={Danger}
					alt="Danger"
					className="w-16 md:w-24 object-contain"
				/>
				<h2 className="text-xl md:text-3xl font-inter font-[800] text-[#CECECE]">
					ALERT !
				</h2>
				<img
					src={deletedPng}
					alt=""
					className="object-contain w-full"
				/>
				<p className=" text-xs md:text-sm text-start md:text-center font-inter font-medium   text-[#000000]">
					Your account has been marked for deletion and is currently
					inactive. 
					If you believe this is a mistake or you wish to have your
					account reviewed, please <Link className="text-blue-600 hover:underline" to={'/contactUs'}>contact us</Link> at <span className="font-semibold">9258294692</span>
				</p>

                <Link
                    to="/signup"
                    className="bg-gradient-to-r mt-3 from-[#5097A4] to-[#9be4f1] hover:to-[#3f7984] hover:from-[#9be4f1] text-white font-medium px-3 py-2 rounded-md mr-6 flex w-fit justify-center items-center  text-xs md:text-sm duration-300">
                      <FaArrowLeft className="mr-2" /> Go Back 
                  </Link>
			</div>
		</div>
	);
};

export default DeleteAccountResponse;
