import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubCategory,
  clearSubCategory,
  setSubSearch} from "../../../Redux/reducer/Filter";
import { RootState } from "../../../Redux/store";

export const Filters= () => {
  const [displayCount, setDisplayCount] = useState<number>(5);
  const [formData, setFormData] = useState({
    search: "",
    checkboxes: {} as any,
  });

  const dispatch = useDispatch();
  const selectedCategories = useSelector(
    (state: RootState) => state.selection.selectedSubCategories
  );

  const allCategories = useSelector(
    (state: RootState) => state.values.allCategories
  );

  useEffect(() => {
    const updatedCheckboxes = selectedCategories.reduce(
      (acc: any, category: any) => {
        acc[category] = true;
        return acc;
      },
      {}
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      checkboxes: updatedCheckboxes,
    }));
  }, [selectedCategories]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: string
  ) => {
    const { checked } = e.target;
  
    if (checked) {
      dispatch(addSubCategory(category));
      dispatch(setSubSearch(null));
    } else {
      dispatch(clearSubCategory(category));
    }
  
    setFormData(prevFormData => ({
      ...prevFormData,
      checkboxes: {
        ...prevFormData.checkboxes,
        [category]: checked
      }
    }));
  
    // If you need to handle setSubSearch, you can do it here
  //   if (!checked && !selectedCategories.includes(category)) {
  //     dispatch(setSubSearch(null));
  //   }
  };

  const filteredCategories = allCategories
    ? allCategories.filter((category: any) => {
        const regex = new RegExp(formData.search, "i");
        return regex.test(category.name);
      })
    : [];

  const handleMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const isChecked = (category: string) => {
    return selectedCategories.includes(category);
  };

  const categoriesToDisplay = filteredCategories.slice(0, displayCount);

  return (
    <div>
      <div className="flex flex-col gap-[0.6rem]">
        <div className="flex flex-col gap-[0.6rem] mt-[16px]">
          <div className="font-[700] text-[14px] leading-[16px]">Category</div>

          <div className="flex border items-center bg-[#F8FAFC] mr-3 py-1 rounded-[7px]">
            <IoSearch size={18} className="text-[#9CA3AF] pl-[4px]" />
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={formData.search}
              onChange={handleInputChange}
              className="font-inter leading-7 text-[#9CA3AF] ml-1 font-Inter text-md bg-[#F8FAFC] border-[#D1D5DB] focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
              style={{ outline: "none", boxShadow: "none" }}
            />
          </div>
          <div className="pl-[12px] flex flex-col gap-[4px]">
            {categoriesToDisplay?.length > 0 ? (
              categoriesToDisplay.map((category: any, index: number) => (
                <div
                  key={index}
                  className="font-[500] text-[14px] leading-[16px]"
                >
                  <input
                    type="checkbox"
                    id={`myCheckbox${index}`}
                    name={`myCheckbox${index}`}
                    checked={isChecked(category.id)}
                    onChange={(e) => handleCheckboxChange(e, category.id)}
                    className="mr-2"
                  />
                  {category.name}
                </div>
              ))
            ) : (
              <div>No categories available</div>
            )}
          </div>

          <div
            className="flex items-center gap-[3px] cursor-pointer"
            onClick={handleMoreClick}
          >
            <IoIosArrowDown />
            <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
              See more
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
