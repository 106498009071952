import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


const OrderTabs = () => {
    const [activeTab, setActiveTab] = useState<boolean>(true);
    
    const navigate = useNavigate();
    return (

        <div
            className={`flex items-center gap-2 h-[45px]  bg-white text-black text-[14px] rounded-xl mb-3 w-fit p-[4px] font-medium`}
            style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
            <div
                onClick={() => {
                    setActiveTab(true);
                    navigate("request");
                }}
                className={`rounded-xl cursor-pointer h-full flex items-center justify-center  px-3 py-1 ${activeTab
                    ? "border-[#334155] bg-[#334155] text-white"
                    : ""
                    }`}>
                Order request
            </div>
            <div
                onClick={() => {
                    setActiveTab(false);
                    navigate("complete");
                }}
                className={`rounded-xl cursor-pointer h-full flex items-center justify-center  px-3 py-1 ${!activeTab
                    ? "border-[#334155] bg-[#334155] text-white"
                    : ""
                    }`}>
                {" "}
                Complete Orders
            </div>
        </div>

    )
}

export default OrderTabs