import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";


export const QuotationQuery = api.injectEndpoints({
	endpoints: (builder) => ({
		getProductQuotation: builder.query<any, { status?: string; page?: number; limit?: number }>(
			{
			  query: ({ status, page, limit }) => {
				const params = new URLSearchParams();
		  
				if (status) params.append("status", status);
				if (page) params.append("page", page.toString());
				if (limit) params.append("limit", limit.toString());
		  
				return {
				  url: `${lhwpath}/global/product/query/v2?${params.toString()}`,
				  method: "GET",
				};
			  },
			}
		  ),
		  
	}),
});

export const { useGetProductQuotationQuery } = QuotationQuery;
