import React, { useEffect, useState, useCallback } from "react";
import { BsTriangleFill } from "react-icons/bs";
import { useCategoryPriorityMutation } from "../../../../Redux/Api/Category";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { count } from "console";

type ManagePriorityProps = {
  id?: string | null;
  priority?: number;
  refetch: () => void;
  total: number,
  type: string
};


const ManagePriority = ({ priority = 0, id, refetch, total, type }: ManagePriorityProps) => {

  const [priorityCount, setPriorityCount] = useState<number>(priority);
  const [updatePriority, { isLoading }] = useCategoryPriorityMutation();
  const MAX_PRIORITY = total
  const MIN_PRIORITY = 0;
  const debouncedUpdatePriority = useCallback(
    debounce(async (newPriority: number) => {
      if (id) {
        if(newPriority < 0) return 
        try {
          await updatePriority({ count: newPriority, _id: id, type }).unwrap();
          toast.success("Priority updated successfully");
          refetch();
        } catch (error) {
          toast.error("Failed to update priority");
        }
      }
    }, 700),
    [id, updatePriority, refetch]
  );

  const handlePriorityChange = (newPriority: number) => {
    setPriorityCount(newPriority);
    debouncedUpdatePriority(newPriority);
  };

  const handleIncrement = () => {
    handlePriorityChange(Math.min(priorityCount + 1, MAX_PRIORITY));
  };

  const handleDecrement = () => {
    handlePriorityChange(Math.max(priorityCount - 1, MIN_PRIORITY));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (numericValue <= MAX_PRIORITY) {
        handlePriorityChange(numericValue);
      }
    }
  };

  useEffect(() => {
    return () => {
      debouncedUpdatePriority.cancel();
    };
  }, [debouncedUpdatePriority]);

  return (
    <div className="relative flex items-center justify-between max-w-[6rem] mx-auto">
      <button
        onClick={handleDecrement}
        disabled={isLoading || priorityCount === 0}
        aria-label="Decrement priority"
        className={`flex items-center justify-center ${isLoading || priorityCount == 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        <BsTriangleFill className="w-4 h-4 text-gray-500 hover:text-black rotate-[-90deg]" />
      </button>
      <input
        type="text"
        value={priorityCount}
        onChange={handleInputChange}
        className="w-[3rem] text-center bg-gray-50 border border-gray-300 rounded-md h-8 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg text-black font-medium"
        aria-label="Priority count"
        disabled={isLoading}
      />
      <button
        onClick={handleIncrement}
        disabled={
          isLoading ||
          MAX_PRIORITY == null ||
          priorityCount >= MAX_PRIORITY
        }
        aria-label="Increment priority"
        className={`flex items-center justify-center ${isLoading || MAX_PRIORITY == null || priorityCount >= MAX_PRIORITY
          ? "opacity-50 cursor-not-allowed"
          : ""
          }`}
      >
        <BsTriangleFill className="w-4 h-4 text-gray-500 hover:text-black rotate-[90deg]" />
      </button>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70">
          <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default ManagePriority;


