import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";


function Pagination({ total, current, onPageChange }: any) {
    const pages = Array.from({ length: total }, (_, index) => index + 1);
    

    return (
        <div className="flex justify-center items-center gap-4 w-[22.9rem] m-auto h-[3rem]">
            <FaAngleLeft className="h-5 w-5" />
            <button
                className="w-[3.4rem] h-[1rem] flex justify-center items-center font-bold text-sm text-[#64748B] hover:text-black"
                disabled={current === 1}
                onClick={() => onPageChange(current - 1)}
            >
                Previous
            </button>
            {pages?.map(pageNumber => {
                if (
                    pageNumber === 1 ||
                    pageNumber === total ||
                    pageNumber === current ||
                    pageNumber === current - 1 ||
                    pageNumber === current + 1
                ) {
                    return (
                        <button
                            key={pageNumber}
                            className={`px-2 py-1 rounded-md ${pageNumber === current ? "bg-[#5097A4] text-white" : "bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-700"}`}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    );
                }
                else if (pageNumber === 2 || pageNumber === total - 1) {
                    return <span key={pageNumber} className="px-2 py-1">...</span>;
                }
                return null;
            })}

            <button
                className="w-[3.4rem] h-[1rem] flex justify-center items-center font-bold text-sm text-[#64748B] hover:text-black"
                disabled={current === total}
                onClick={() => onPageChange(current + 1)}
            >
                Next
            </button>
            <FaAngleRight className="h-5 w-5" />
        </div>
    );
}

export default Pagination;
