import warning from "../../assets/Warning-icon.-The-attention-icon.png"

const DeleteAccount = () => {
    return (
        
            
            <div className="mx-auto container max-w-6xl md:border py-6 px-6 sm:p-10 sm:my-5 rounded-lg bg-white">
                <div className="w-full flex justify-center  ">
                    <img src={warning} alt="" className="w-20 sm:w-28 " />
                    {/* <img src={warning} alt="" className="w-28 hidden md:block" /> */}
                </div>
                {/* Header Section */}
                <div className="space-y-2 mb-3">
                    <h1 className="text-lg sm:text-3xl font-bold text-center mb-8">Delete Account</h1>
                    {/* Brief Information about Account Deletion */}
                    <div className="space-y-2">
                        <p className="font-semibold">Account Deletion Request Process</p>
                        <p className="text-justify text-gray-500">
                            If you wish to delete your account and all associated data, please follow the steps below. Your account will be permanently removed from our system within 24 hours of receiving your request.
                        </p>
                    </div>
                </div>

                {/* Instructions Section */}
                <div className="space-y-2">
                    <div className="font-semibold text-start">How to Request Account Deletion:</div>
                    <ol className="px-2 space-y-2 list-decimal list-inside">
                        <li>
                            Send an email to  <a href="mailto:business.simprabh@gmail.com" className="text-blue-500 underline">business.simprabh@gmail.com</a> with the subject line: <strong>"Account Deletion Request"</strong>.
                        </li>
                        <li>
                            In the body of the email, please include:
                            <ul className="list-disc list-inside ml-5">
                                <li>Your username (as registered on the app)</li>
                                <li>Your phone number (associated with the account)</li>
                            </ul>
                        </li>
                    </ol>
                </div>

                {/* Account Deletion Process */}
                <div className="space-y-2 mt-5">
                    <p className="text-gray-500">
                        Once we receive your request, our team will review and verify the details. Your account and associated data will be deleted within 24 hours, and you will receive a confirmation email when the process is complete.
                    </p>
                </div>

                {/* Data That Will Be Deleted Section */}
                <div className="space-y-2 mt-5">
                    <div className="font-semibold text-start">Data That Will Be Deleted:</div>
                    <ul className="list-disc list-inside ml-5">
                        <li>User profile information (name, username, email, phone number)</li>
                        <li>Preferences and settings</li>
                        <li>Any rewards or points balance</li>
                    </ul>
                </div>

                {/* Data That May Be Retained Section */}
                <div className="space-y-2 mt-5">
                    <div className="font-semibold text-start">Data That May Be Retained:</div>
                    <p className="text-gray-500">
                        In compliance with legal, financial, or regulatory obligations, certain data may be retained after account deletion, including:
                    </p>
                    <ul className="list-disc list-inside ml-5">
                        <li>Transaction data for tax or legal purposes</li>
                        <li>Records required by law for a specific retention period</li>
                    </ul>
                </div>

                {/* Contact Information Section */}
                <div className="space-y-2 mt-5">
                    <p className="text-gray-500">
                        If you have any further questions or concerns about this process, feel free to contact our support team at <a href="mailto:business.simprabh@gmail.com" className="text-blue-500 underline">business.simprabh@gmail.com</a>.
                    </p>
                </div>
            </div>

    

    )
}

export default DeleteAccount