import React from 'react';
import { Chart } from 'react-google-charts';

const options = {
  chartArea: { width: '70%', top: 25, height: '80%' }, // Adjust chart height
  bars: 'horizontal',
  bar: { groupWidth: '40%' },
  legend: { position: 'none' },
  vAxis: {
    textPosition: 'out',
    textStyle: {
      fontSize: 11, // Smaller font size for labels
    },
    spacing: 20, // Increase spacing between labels
  },
};

// Predefined color palette
const colorPalette = ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#AB47BC', '#FFB300'];

interface DataItem {
  name: string;
  value: number;
  group: string; // Add a group property
}

interface HorizontalBarChartProps {
  data: DataItem[];
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ data }) => {
  // Prepare the data for the chart
  const chartData = [
    ['Name', 'Website Visits', { role: 'style' }],
    ...data.map((item, index) => [
      item.name.split(' ')[0], // Get only the first word of the name
      item.value - 2, // Subtract 2 from each visit count
      colorPalette[index % colorPalette.length]
    ])
  ];
  

  return (
    <div>
      <Chart
        chartType="BarChart"
        width="100%"
        height="600px"
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default HorizontalBarChart;
