import { useEffect, useRef } from "react";
import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
import { IComapny } from "./types";
import { Link, useNavigate } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { toast } from "react-toastify";

interface TopCompanyScrollProps {
  data: any
  isLoading: boolean
}
const TopCompanyScroll = ({ data, isLoading }:TopCompanyScrollProps) => {
  const navigate = useNavigate()
  const handleItemClick = (_id?: string, name?: string | null) => {
    if (_id) {
      navigate(`/items/${_id}/company/${name}/`, { state: { company: _id } });
    } else {
      toast.warning("Something went wrong! Please try again.");
    }
  };


  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  }, []);
  return (
    <div ref={containerRef} className="w-[95%] mt-6 gap-[5px] h-[106px] overflow-x-auto flex items-center overflow-y-hidden no-scrollbar mx-auto"

    >
      {isLoading
        ?
        Array.from({ length: 8 }).map((_, index) => (
          <div
            key={index}
            className="h-[106px] p-[1px] w-[92.28px] flex-shrink-0 overflow-hidden bg-gray-200 animate-pulse rounded-sm shadow-sm"
          >
            <div className="w-full h-[80px] bg-gray-300 rounded-sm"></div>
            <div className="w-full h-[26px] bg-gray-300 mt-1"></div>
          </div>
        ))
        :
        data?.data?.slice(0, 16)?.map((company: IComapny) => (
          <div
            key={company.id}
            className="h-[106px] p-[1px] w-[92.28px] flex-shrink-0 overflow-hidden bg-white rounded-sm shadow-sm border cursor-pointer"
            onClick={() => handleItemClick(company?.id, company?.name)}
          >
            <img
              src={company?.image ? company?.image : ""}
              alt="company"
              className="w-full object-contain h-[75.87px] rounded-sm"
            />
            <div className="w-full h-[30.33px] flex items-center justify-center">
              <span className="text-[11px] font-medium text-black text-center  leading-[13px]">
                {company.name}
              </span>
            </div>
          </div>
        ))}

      <Link to={"/company"} className="h-[106px] p-[1px] w-[92.28px] flex-shrink-0 overflow-hidden bg-white rounded-sm shadow-sm border border-[#B1D4D8] flex justify-center items-center flex-col gap-1">
        <MdArrowForwardIos className="text-white bg-[#B1D4D8] text-[14px] rounded-full p-[2px] h-[28px] w-[28px]" />
        <span className="font-poppins text-[8px] text-[#2E2E2E] font-medium">SEE MORE...</span>
      </Link>
    </div>
  );
};

export default TopCompanyScroll;
