import { FaHistory, FaIdCard, FaUserEdit, FaUserFriends, FaUserPlus } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { NavLink, NavigateFunction, useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import { useEffect } from "react";
import { MdOutlineQueryBuilder } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { IoMdListBox } from "react-icons/io";

interface BottomProfileMenuProps {
    isOpen: boolean;
    isClose: () => void;
}

interface IMenuItems {
    title: string;
    icon: React.ReactNode;
    path: string;
}

const menuItems: IMenuItems[] = [
    // {
    //     title: "Rewards Cards",
    //     icon: <FaIdCard />,
    //     path: "/profile/rewards"
    // },
    {
        title: "Customer Entry",
        icon: <FaUserFriends />,
        path: "/profile/entry"
    },
    // {
    //     title: "History",
    //     icon: <FaHistory />,
    //     path: "/profile/history"
    // },
    {
        title: "Quotation Tracker",
        icon: <IoMdListBox />,
        path: "/profile/quotation-tracker"
    },
    {
        title: "Query",
        icon: <MdOutlineQueryBuilder />,
        path: "/profile/query"
    },
    // {
    //     title: "Edit Profile",
    //     icon: <FaUserEdit />,
    //     path: "/profile/edit"
    // },
];

const BottomProfileMenu = ({ isOpen, isClose }: BottomProfileMenuProps) => {
    const { handleLogout } = useLogout()
    const navigate: NavigateFunction = useNavigate();
    const handleMenuClick = (path: string) => {
        navigate(path);
        isClose();
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640 && isOpen) {
                isClose();
            }
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isOpen, isClose]);
    return (
        <>
            {isOpen && (
                <div onClick={isClose} className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 font-poppins"></div>
            )}
            <div
            // h-[350px]
                className={`h-[220px] w-full overflow-hidden bg-white rounded-t-3xl z-50 fixed bottom-0 transition-transform duration-300 ${isOpen ? "translate-y-0" : "translate-y-[calc(100%)]"
                    }`}
            >
                <div className="w-full h-full relative">
                    <div className="absolute top-2 left-1/2 transform -translate-x-1/2 h-[5px] w-[80px] bg-gray-300 rounded-full"></div>
                    <ul className="w-full h-full flex flex-col items-start justify-center px-4">
                        {menuItems.map((item: IMenuItems, index: number) => (
                            <NavLink
                                to={item.path}
                                key={index}
                                className={({ isActive }) => `w-full h-10 flex items-center justify-start pl-4 cursor-pointer ${isActive ? "bg-gray-200 rounded-xl" : ""}`}
                                onClick={() => handleMenuClick(item.path)}
                            >
                                <div className="w-6 h-6 flex justify-center items-center bg-[#F0F0F0] rounded-full">
                                    {item.icon}
                                </div>
                                <span className="ml-3 text-sm font-medium">{item.title}</span>
                            </NavLink>
                        ))}
                        <li onClick={handleLogout} className="w-full h-10 flex items-center justify-start pl-4 cursor-pointer">
                            <div className="w-6 h-6 flex justify-center items-center bg-[#F0F0F0] rounded-full">
                                <FiLogOut />
                            </div>
                            <span className="ml-3 text-sm font-medium">Logout</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default BottomProfileMenu;
