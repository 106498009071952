import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import chawlaLogo from "../assets/welcome logo.jpg";
import { useClickOutside } from "../hooks/useClickOutside";
import { BsRocketTakeoffFill } from "react-icons/bs";
interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children?: React.ReactNode;
	width?: string;
}

const CustomModal = ({
	isOpen,
	onClose,
	title,
	width,
	children,
}: ModalProps) => {
	const modalRaf = useRef<HTMLDivElement | null>(null);

	const handleOutsideClick = (event: MouseEvent): void => {
		if (
			modalRaf.current &&
			!modalRaf.current.contains(event.target as Node)
		) {
			onClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleOutsideClick);
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
			document.body.classList.remove("overflow-hidden");
		};
	}, [isOpen]);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					id="progress-modal"
					tabIndex={-1} 
					className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.3, ease: "easeInOut" }}>
					<motion.div
						className={`relative w-[90%] md:w-full max-w-sm h-auto max-h-[95%] overflow-auto ${
							width || "md:max-w-lg"
						}`}
						initial={{ rotateY: 90, opacity: 0 }}
						animate={{ rotateY: 0, opacity: 1 }}
						exit={{ rotateY: -90, opacity: 0 }}
						transition={{
							duration: 0.6,
							ease: [0.42, 0, 0.58, 1],
						}}
						ref={modalRaf}
						style={{ transformOrigin: "left center" }}>
						<div className="bg-white rounded-lg shadow dark:bg-gray-700">
							{/* Modal Close Button */}
							<button
								type="button"
								className="absolute top-2 right-2 text-gray-400 hover:bg-[#5097A4] hover:text-white rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white transition-all duration-300 ease-in-out"
								onClick={onClose}>
								<RxCross2 className="h-5 w-5" />
							</button>

							{/* Modal Content */}
							<div className="p-5 flex flex-col h-full max-h-[100%] overflow-y-auto">
								<h3 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
									{title}
								</h3>
								<div className="flex-grow">{children}</div>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
export default CustomModal;

interface IProfileProps {
	imageUrl: any;
	altText: string;
	isOpen: boolean;
	setIsOpen: (val: boolean) => void;
}

export const ZoomInImage = ({
	isOpen,
	setIsOpen,
	imageUrl,
	altText,
}: IProfileProps) => {
	const zoomInRef = useRef<HTMLDivElement | null>(null);

	const handleOutsideClick = (event: MouseEvent): void => {
		if (
			zoomInRef.current &&
			!zoomInRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleOutsideClick);
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
			document.body.classList.remove("overflow-hidden");
		};
	}, [isOpen]);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					id="progress-modal"
					tabIndex={-1}
					className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2, ease: "easeOut" }}>
					<motion.div
						className={`relative w-[80%] md:w-full max-w-md md:max-w-lg h-auto max-h-[95vh] overflow-hidden`} // Set max height for modal
						initial={{ scale: 0.95 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0.95 }}
						transition={{ duration: 0.2, ease: "easeOut" }}
						ref={zoomInRef}>
						<div className="rounded-lg shadow dark:bg-gray-700">
							{/* Modal Close Button */}
							<button
								type="button"
								className="absolute top-1 right-1 text-white hover:text-[#5097A4] rounded-lg text-sm w-7 h-7 flex justify-center items-center transition-all duration-300 ease-in-out"
								onClick={() => setIsOpen(false)}>
								<svg
									className="w-3 h-3"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 14 14">
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M1 1l6 6m0 0l6 6M7 7L1 13M7 7l6-6"
									/>
								</svg>
								<span className="sr-only">Close modal</span>
							</button>

							{/* Modal Content */}
							<div className="p-6 flex flex-col h-full max-h-[90vh] overflow-hidden">
								<div className="rounded-2xl w-full max-h-full overflow-auto  scrollbar-customXy">
									<img
										src={imageUrl}
										alt={altText}
										className="object-contain object-center cursor-pointer rounded-2xl w-full h-full"
										onClick={() => setIsOpen(false)}
									/>
								</div>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

interface IConfirmProps {
	isOpen: boolean;
	handleConfirm: () => void;
	handleClose: () => void;
	text: string;
	buttonText?: string;
	heading?: string;
	buttonBgColor?: string;
	isLoading?: boolean;
}

export const ConfirmModal = ({
	isOpen,
	handleConfirm,
	text,
	handleClose,
	buttonText,
	heading,
	buttonBgColor,
	isLoading,
}: IConfirmProps) => {
	const zoomInRef = useRef<HTMLDivElement | null>(null);

	const handleOutsideClick = (event: MouseEvent): void => {
		if (
			zoomInRef.current &&
			!zoomInRef.current.contains(event.target as Node)
		) {
			handleClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleOutsideClick);
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
			document.body.classList.remove("overflow-hidden");
		};
	}, [isOpen]);
	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					id="progress-modal"
					tabIndex={-1}
					className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2, ease: "easeOut" }}>
					<motion.div
						className={`relative w-[80%] md:w-full max-w-md md:max-w-sm h-auto max-h-[95vh] overflow-hidden`} // Set max height for modal
						initial={{ scale: 0.95 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0.95 }}
						transition={{ duration: 0.2, ease: "easeOut" }}
						ref={zoomInRef}>
						<div className="relative px-4 py-10 text-center bg-white rounded-lg shadow dark:bg-gray-800 ">
							<h3 className="text-lg font-semibold mb-4">
								{heading}
							</h3>
							<p className="mb-4 text-gray-500 dark:text-gray-300 text-lg text-wrap w-[90%] mx-auto">
								{text}
							</p>
							<div className="flex justify-center items-center space-x-4">
								<button
									onClick={handleClose}
									data-modal-toggle="deleteModal"
									type="button"
									className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 ">
									cancel
								</button>
								<button
									onClick={handleConfirm}
									disabled={isLoading}
									className={`py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:outline-none min-w-[70px] ${
										buttonBgColor
											? buttonBgColor
											: "bg-[#5097A4] hover:bg-[#4d8e9a]"
									}`}>
									{isLoading
										? "Wait..."
										: buttonText || "Yes, I'm sure"}
								</button>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

interface CustomPopupModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children?: React.ReactNode;
	width?: string;
}

export const CustomPopupModal = ({
	isOpen,
	onClose,
	title,
	width,
	children,
}: CustomPopupModalProps) => {
	const modalRaf = useRef<HTMLDivElement | null>(null);

	const handleOutsideClick = (event: MouseEvent): void => {
		if (
			modalRaf.current &&
			!modalRaf.current.contains(event.target as Node)
		) {
			onClose();
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleOutsideClick);
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
			document.body.classList.remove("overflow-hidden");
		};
	}, [isOpen]);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					id="progress-modal"
					tabIndex={-1}
					className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2, ease: "easeOut" }}>
					<motion.div
						className={`relative w-[95%] md:w-full max-w-md h-auto max-h-[95%] overflow-auto ${
							width || "md:max-w-lg"
						}`}
						initial={{ scale: 0.95 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0.95 }}
						transition={{ duration: 0.2, ease: "easeOut" }}>
						<div className="bg-white rounded-lg shadow ">
							{/* Modal Close Button */}
							<button
								type="button"
								className="absolute top-2 right-2 text-gray-400 hover:bg-[#5097A4] hover:text-white rounded-lg text-sm w-5 h-5 flex justify-center items-center  transition-all duration-300 ease-in-out"
								onClick={onClose}>
								<RxCross2 className="h-4 w-4" />
							</button>

							{/* Modal Content */}
							<div className="p-5 flex flex-col h-full max-h-[100%] overflow-y-auto font-inter">
								<h3 className="mb-3 text-lg font-bold text-gray-900  text-center ">
									{title}
								</h3>
								<div className="flex-grow">{children}</div>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

interface IWelcomeSignUp {
	isOpen: boolean;
	setIsOpen: () => void;
}

export const WelcomeSignUp = ({ isOpen, setIsOpen }: IWelcomeSignUp) => {

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					id="welcome-signup-modal"
					tabIndex={-1}
					className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.3, ease: "easeOut" }}>
					<motion.div
						className="relative w-[90%] max-w-lg bg-white rounded-3xl shadow-xl overflow-hidden"
						initial={{ scale: 0.95 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0.95 }}
						transition={{ duration: 0.3, ease: "easeOut" }}
						>
						{/* Header Section with Logo */}
						<div className="bg-gradient-to-r text-white text-center px-4 sm:px-6 pt-4 pb-2 rounded-t-3xl shadow-xl">
							<div className="relative w-36 h-36 mx-auto ">
								<img
									src={chawlaLogo}
									alt="Company Logo"
									className="absolute inset-0 w-full h-full object-contain  border-4 border-white  "
								/>
							</div>
							<h2 className="text-2xl font-bold  tracking-wide uppercase text-yellow-400">
								Welcome to{" "}
                                 <br />
								<span className="text-yellow-500 text-2xl">
									Chawla Ispat Private Limited
								</span>
							</h2>
							<p className="mt-2 text-sm text-gray-600">
								We're so happy to have you with us!
							</p>
						</div>

						{/* Success Message */}
						<div className="px-4 sm:px-6 pb-8 pt-2 bg-white rounded-b-3xl shadow-xl relative overflow-hidden">
							<h3 className=" text-xl sm:text-3xl font-bold text-gray-800 text-center ">
								Successfully Joined
							</h3>

							<p className="mt-1 sm:mt-4 text-gray-600 text-center text-xs sm:text-lg leading-relaxed">
								Thanks for joining us at{" "}
								<span className="text-teal-500 font-semibold">
									Chawla Ispat .
								</span>
                                <br />
								Let’s get started on this exciting journey
								together!
							</p>
							<div className="flex justify-center mt-8">
								<button
									className="px-5 py-2 sm:px-8 sm:py-3 text-sm sm:text-lg font-semibold text-white bg-gradient-to-r from-teal-400 to-teal-600 rounded-full shadow-lg transform transition-all duration-300 ease-out hover:scale-105 flex gap-2 items-center"
									onClick={() => setIsOpen()}>
									Let's Go! <BsRocketTakeoffFill />
								</button>
							</div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>

		// <AnimatePresence>
		//     {isOpen && (
		//         <motion.div
		//             id="welcome-signup-modal"
		//             tabIndex={-1}
		//             className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
		//             initial={{ opacity: 0 }}
		//             animate={{ opacity: 1 }}
		//             exit={{ opacity: 0 }}
		//             transition={{ duration: 0.2, ease: "easeOut" }}>
		//             <motion.div
		//                 className="relative w-[90%] max-w-lg bg-white rounded-lg shadow-lg overflow-hidden"
		//                 initial={{ scale: 0.95 }}
		//                 animate={{ scale: 1 }}
		//                 exit={{ scale: 0.95 }}
		//                 transition={{ duration: 0.2, ease: "easeOut" }}
		//                 ref={zoomInRef}>
		//                 {/* Header Section with Logo */}
		//                 <div className="bg-gradient-to-r text-white text-center p-6 rounded-t-lg shadow-lg">
		//                     <div className="relative w-20 h-20 mx-auto">
		//                         <img
		//                             src={chawlaLogo}
		//                             alt="Company Logo"
		//                             className="absolute inset-0 w-full h-full object-contain rounded-full border-4 border-white shadow-md"
		//                         />
		//                     </div>
		//                     <h2 className="text-2xl font-extrabold mt-4 tracking-wide uppercase">
		//                         Welcome to{" "}
		//                         <span className="text-yellow-300">
		//                             Chawla Ispat Private Limited
		//                         </span>
		//                     </h2>
		//                     <p className="mt-2 text-sm font-light text-blue-200">
		//                         Empowering you to achieve more with us.
		//                     </p>
		//                 </div>

		//                 {/* Success Message */}
		//                 <div className="p-8 bg-white rounded-b-lg shadow-lg relative overflow-hidden">

		//                     <h3 className="text-[20px] sm:text-3xl font-bold text-gray-800 text-center mt-3 sm:mt-6">
		//                         Signup Successfully 🎉
		//                     </h3>
		//                     <p className="mt-4 text-gray-600 text-center text-sm sm:text-lg leading-relaxed">
		//                         We're excited to have you join us. Your journey
		//                         with{" "}
		//                         <span className="text-turkishBlue-default text-sm sm:text-lg font-semibold">
		//                             Chawla Ispat Private Limited
		//                         </span>{" "}
		//                         starts now!
		//                     </p>
		//                     <div className="flex justify-center mt-8">
		//                         <button
		//                             className="px-5 sm:px-8 py-2 sm:py-3 sm:text-lg font-semibold text-white bg-gradient-to-r from-turkishBlue-default via-[#498d9a] to-[#438591] rounded-full shadow-lg  transform transition-all duration-300 ease-out"
		//                         onClick={() => setIsOpen()}
		//                         >
		//                             Get Started
		//                         </button>
		//                     </div>

		//                 </div>
		//             </motion.div>
		//         </motion.div>
		//     )}
		// </AnimatePresence>
	);
};
