import { useEffect, useState } from "react";
import { useGetDeleteAdminContractorQuery, useGetUnblockAdminContractorMutation } from "../../../Redux/Api/Order";

const ContractorHistory = () => {
	const [openIndex, setOpenIndex] = useState<number | null>(null);
	const { data: history, refetch } = useGetDeleteAdminContractorQuery();

	const [unblockContractor] = useGetUnblockAdminContractorMutation();

	useEffect(() => {
		refetch();
	}, [refetch]);

	const handleUnblockContractor = async (id: string) => {
		try {
			await unblockContractor(id).unwrap();
			refetch();
		} catch (error) {
			console.error("Failed to unblock contractor: ", error);
		}
	};

	const handleToggle = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (

		<div className="w-full min-h-screen px-3 mt-3 pb-3 ">
			<div className="w-full  mb-4 pt-1">
				{history?.data?.length > 0 &&
					history?.data?.map((item: any, index: number) => (
						<div className="mb-2 rounded-2xl">
							<div
								onClick={() => handleToggle(index)}
								className="w-full flex justify-between text-black transition-all duration-500 ease-in-out transform items-center cursor-pointer    py-2 px-3   text-lg font-semibold rounded-2xl border border-gray-200 bg-white hover:bg-[#334155] hover:text-white ">
								<div className="h-full w-full flex items-center justify-between"

								>
									<div className="flex items-center h-full">
										<div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white border">
											<span className="text-blue-500 text-2xl font-bold">
												{item?.name
													.charAt(0)
													.toUpperCase()}
											</span>
										</div>
										<span className="text-sm font-medium  ml-2">
											{item?.name}
										</span>
									</div>

									<div className="text-gray-500  font-normal leading-4 py-1 flex flex-col box-border gap-1   ">
										<button
											onClick={() =>
												handleUnblockContractor(
													item?.contractorId
												)
											}
											className="font-semibold  text-[14px] bg-blue-500 p-1 text-white  rounded-md">
											Unblock{" "}
										</button>
										<span className="text-[10px]">{`${item?.deleteAt?.split("T")[0]
											} ${item?.deleteAt
												?.split("T")[1]
												?.split(":")[0]
											}:${item?.deleteAt
												?.split("T")[1]
												?.split(":")[1]
											}`}</span>
									</div>
								</div>
							</div>

							<div className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto  items-center ${openIndex === index
								? "scale-100 opacity-100 max-h-screen"
								: "scale-95 opacity-0 max-h-0"
								}`}
								style={{ transformOrigin: "top" }}>
								<div className="p-4 bg-gray-100 w-[95%] rounded-b-xl h-full">
									<div className="flex items-start gap-2.5 w-full">
										<div className="flex flex-col gap-1 w-full">
											<div className="flex flex-col w-full  leading-1.5 p-4 border-gray-200 bg-white rounded-e-xl rounded-es-xl dark:bg-gray-700">
												<div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
													<span className="text-sm font-semibold text-gray-900 dark:text-white">
														<span className="font-semibold">
															Name :{" "}
														</span>{" "}
														<span>
															{item?.name}
														</span>
													</span>
												</div>

												<div className="text-sm">
													Phone Number :{" "}
													<div className=" justify-start items-center gap-3 inline-flex w-fit max-w-full">
														{item?.phoneNumber}
													</div>
												</div>
												<div className="text-sm mt-1">
													Remark :{" "}
													<div className="px-3.5 py-2 bg-gray-100 rounded justify-start items-center gap-3 inline-flex w-fit max-w-full">
														<h5 className="text-gray-900 text-sm font-normal leading-snug break-words">
															{item?.remark}
														</h5>
													</div>
												</div>

												<div className="justify-end items-start mt-1 mb-2.5 flex flex-col">
													<h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
														<span className="font-semibold">
															Date :{" "}
														</span>

														{`${item?.deleteAt?.split(
															"T"
														)[0]
															} ${item?.deleteAt
																?.split(
																	"T"
																)[1]
																?.split(
																	":"
																)[0]
															}:${item?.deleteAt
																?.split(
																	"T"
																)[1]
																?.split(
																	":"
																)[1]
															}`}
													</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ContractorHistory;
