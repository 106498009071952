import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

const PublicRoute: React.FC = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
  const verified = useSelector((state: RootState) => state.Login.ContractorData?.isVerified);



  if (userDetails && verified) {
    // If user is logged in, redirect to home
    if (userDetails?.role === "admin"){
      return <Navigate to="/admin" replace />;
    }
    else{
      return <Navigate to="/" replace />;
    }
   
  }

  return <Outlet />;
};

export default PublicRoute;
