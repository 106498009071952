import { ChangeEvent, useEffect, useState } from 'react'
import { useGetReferralOrdersMutation } from '../../../Redux/Api/Order';
import { Link, useNavigate } from 'react-router-dom';
import { RiFileExcel2Fill } from 'react-icons/ri';
import TableLoading from '../../../common/admin-common/TableLoading';
import TableNoData from '../../../common/admin-common/TableNoData';
import { exportToExcel } from "../../../utils/exportToExcel"
import useDebounce from '../../../common/hooks/useDebounce';

interface Order {
  _id: string;
  createdAt: string;
  orderNumber: string;
  createdBy: string;
  godowns: string[];
  billNumber: string;
  status: string;
  contractorName: string;
  contractorId: string;
}
const CompleteOrders = () => {
  const navigate = useNavigate();
  const [salesPersonName, setSalesPersonName] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [mutation, { data: referralOrders, isLoading }] = useGetReferralOrdersMutation();

  const debounceOrderNumber = useDebounce(orderNumber, 500);
  const debounceSalesPersonName = useDebounce(salesPersonName, 500);

  useEffect(() => {
    const body: { referralOrder: boolean; orderNumber?: string; salesPerson?: string } = { referralOrder: true };
    if (debounceOrderNumber) {
      body.orderNumber = debounceOrderNumber;
    }
    if (debounceSalesPersonName) {
      body.salesPerson = debounceSalesPersonName;
    }
    mutation(body);
  }, [mutation, debounceOrderNumber, debounceSalesPersonName]);

  useEffect(() => {
    mutation({ referralOrder: true });
  }, [mutation]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => setter(e.target.value);

  const resetFilters = (): void => {
    setSalesPersonName("");
    setOrderNumber("");
  };



  const exportDataToExcel = (): void => {
    const filteredData = (referralOrders?.orders || []).map((order: Order) => ({
      Date: order.createdAt.split("T")[0] || "-",
      "Order Number": order.orderNumber || "-",
      "Sales Person Name": order.createdBy || "-",
      "Loading Point (Godown)": order.godowns.join(", ") || "-",
      "Bill No": order.billNumber || "-",
      "Order Status": order.status || "-",
      "Referral By (Contractor)": order.contractorName || "-",
    }));

    exportToExcel(filteredData, "CompleteOrdersData");
  };



  return (
    <>

      <div className="flex justify-between py-2 px-4">
        <div className="flex items-center gap-2">
          <input
            type="text"
            onChange={handleInputChange(setSalesPersonName)}
            value={salesPersonName}
            placeholder="Sales Person Name.."
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-3"
            style={{ boxShadow: "inset 0px 0px 7.6px #00000040" }}
          />
          <input
            type="text"
            onChange={handleInputChange(setOrderNumber)}
            value={orderNumber}
            placeholder="Order Number.."
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-3"
            style={{ boxShadow: "inset 0px 0px 7.6px #00000040" }}
          />
        </div>
        <div className="flex gap-2">
          <button
            onClick={resetFilters}
            className="flex items-center gap-1 text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white hover:bg-red-500 duration-150"
          >
            Reset
          </button>
          <button
            onClick={exportDataToExcel}
            className="flex items-center gap-1 text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white hover:bg-green-500 duration-150"
          >
            Excel <RiFileExcel2Fill className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="w-full overflow-x-auto pt-2">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr className="border-t">
              {["Date", "Order Number", "Sales Person Name", "Loading Point (Godown)", "Bill No", "Order Status", "Referral By (Contractor)"].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white">
            {isLoading ? (
              <TableLoading colCount={7} />
            ) : referralOrders?.orders?.length ? (
              referralOrders?.orders?.map((order: Order, index: number) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-sm text-[#6E7079]">{order.createdAt.split("T")[0]}</td>
                  <td className="px-6 py-4 text-sm text-[#0078FB] cursor-pointer">
                    <Link to={order._id}>{order.orderNumber || "-"}</Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-[#6E7079]">
                    <Link to="pending">{order.createdBy || "-"}</Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-[#6E7079]">
                    {order.godowns.join(", ") || "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-[#6E7079]">{order.billNumber || "-"}</td>
                  <td className="px-6 py-4 text-sm text-[#6E7079]">{order.status || "-"}</td>
                  <td
                    className="px-6 py-4 text-sm text-[#0078FB] cursor-pointer"
                    onClick={() => navigate(`/admin/contractors/profile/${order.contractorId}`)}
                  >
                    {order.contractorName || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <TableNoData colCount={7} text="Data Not Available" />
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CompleteOrders