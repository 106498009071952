

const OrderDetailsComponent = ({ salesOrderData, decodedName, lengthItem }: any) => {


    return (


        <div className="flex flex-col gap-5 mb-4 ">
            {/* Sales Order Section */}
            <div className=" flex flex-col gap-10 rounded-lg p-5 md:p-10 md:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] border ">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col md:flex-row justify-between">
                        <div className="flex flex-col gap-3 items-start">
                            <div className="font-bold text-lg md:text-2xl text-gray-800 ">
                                Order Number:{" "}
                                <span className="">
                                    {decodedName}
                                </span>
                            </div>
                        </div>

                        <div className="text-lg md:text-2xl mt-4 md:mt-0">
                            Total Items:{" "}
                            <span className="font-bold">
                                {lengthItem || 0}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Customer Details Section */}
                <div className="flex flex-col border-2 rounded-lg p-4 gap-5 bg-white">
                    <h2 className="text-lg md:text-2xl font-semibold">Shipping Address  </h2>
                    <div className="flex flex-col md:flex-row justify-between">
                        {/* Left Column */}
                        <div className="flex flex-col md:gap-3">
                            <div className="flex gap-3">
                                <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px] text-[14px]">
                                    Name:
                                </div>
                                <p className="text-[14px]">{salesOrderData?.customer?.customerName || "-"}</p>
                            </div>



                            {/* <div className="flex gap-5">
                                    <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">
                                        Phone Number:
                                    </div>
                                    <p className="text-[14px]">{salesOrderData?.phoneNumber || "-"}</p>
                                </div> */}

                            <div className="flex gap-5">
                                <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">
                                    State:
                                </div>
                                <p className="text-[14px]">{salesOrderData?.shippingAddress?.state || "-"}</p>
                            </div>

                            <div className="flex gap-5">
                                <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">
                                    City:
                                </div>
                                <p className="text-[14px]">{salesOrderData?.shippingAddress?.city || "-"}</p>
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="flex flex-col gap-1 mt-4 md:mt-0">
                            {/* <div className="flex gap-5">
                                    <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">PAN:</div>
                                    <p className="text-[14px]">{orderTrackingData?.salesOrderData?.customer?.panCode || "-"}</p>
                                </div> */}

                            <div className="flex gap-2  md:gap-4">
                                <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">Phone Number:</div>
                                <p className="text-[14px]">{salesOrderData?.customer?.phoneNumber || "-"}</p>
                            </div>

                            <div className="flex gap-2  md:gap-4">
                                <div className="font-semibold text-[#005D7F] w-[8rem] md:w-[10rem] text-[14px]">
                                    Postal Code:
                                </div>
                                <p className="text-[14px]">{salesOrderData?.shippingAddress
                                    ?.pinCode}</p>
                            </div>
                        </div>
                    </div>
                    {/* 
                        <hr className="w-full h-[2px] bg-gray-300" />

                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col md:flex-row gap-2  md:gap-4">
                                <h1 className="font-semibold text-lg w-[10rem] md:w-[13rem] text-[#005D7F]">
                                    Address:
                                </h1>
                                <p>{orderTrackingData?.salesOrderData?.customer?.address1}</p>
                            </div>

                            <div className="flex flex-col md:flex-row gap-2  md:gap-4">
                                <h1 className="font-semibold text-lg w-[10rem] md:w-[13rem] text-[#005D7F]">
                                    Permanent Address:
                                </h1>
                                <p>{orderTrackingData?.salesOrderData?.customer?.address2}</p>
                            </div>
                        </div> */}
                </div>

            </div>
        </div>


    )
}

export default OrderDetailsComponent