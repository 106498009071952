
import { RxCross2 } from "react-icons/rx";
import { BsThreeDotsVertical } from "react-icons/bs";

const ItemStatus = ({
  i,
  index,
  openPopupIndex,
  handleStatusChange,
  handleTogglePopup,
}: any) => {
  return (
    <td className="w-[114px] relative h-[23px] whitespace-nowrap font-inter text-sm  rounded-lg text-[#FFFFFF]">
      <div
        className={`text-black  relative flex justify-between p-2 items-center border rounded-xl ${i.publish
          ? "bg-green-100 text-green-600 border-green-600 border-1 hover:bg-green-600 hover:text-white"
          : "bg-red-100 text-red-600 border-red-600 border-1 hover:bg-red-600 hover:text-white"
          }`}>
        <span>{i.publish ? "Published" : "Unpublished"}</span>
        <span className="" onClick={() => handleTogglePopup(index)}>
          <BsThreeDotsVertical className="cursor-pointer" />
        </span>
      </div>

      {openPopupIndex === index && (
        <div className=" absolute top-5 -left-8 z-50 items-center justify-end text-black rounded-md opacity-1 transition-opacity duration-500">
          <div className=" flex flex-col gap-[30px] w-[50px] h-[150px] p-6 rounded-lg ">
            <div className="absolute  left-2 top-5  z-10 border-2  mt-2 w-32  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div
                onClick={() => {
                  handleTogglePopup(index);
                }}
                className="  absolute  top-2 right-2 rounded-full border-black z-50 text-sm ">
                <RxCross2 className=" cursor-pointer text-red-500" />
              </div>
              <div className="py-4 px-3 w-full text-center">
                <button
                  onClick={() =>
                    handleStatusChange(
                      "publish",
                      i._id,
                      index
                    )
                  }
                  className="block px-4 py-1 text-sm w-full  text-green-500 font-medium">
                  Publish
                </button>
                <div className="w-full h-[1px] border-b-[1px] border-gray-400"></div>
                <button
                  onClick={() =>
                    handleStatusChange(
                      "unPublish",
                      i._id,
                      index
                    )
                  }
                  className="block px-4 py-1 text-sm w-full  text-red-500 font-medium">
                  Unpublish
                </button>
              </div>

            </div>
          </div>
        </div>
      )}
    </td>
  );
};

export default ItemStatus;
