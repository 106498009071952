import React, { useState, useEffect } from 'react';
import Mysvg from "../assets/loading.svg";
import lohawalla from "../assets/logo.svg"

export const Loading = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 600); // Change the delay (in milliseconds) as needed

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="h-screen w-full bg-[#5097a4] flex flex-row justify-center items-center">
      <div
        className={`transition-transform duration-500 ${
          showText ? 'translate-x-[-50px]' : ''
        }`}
      >
        <img src={lohawalla} alt="Loading..." className='w-[4.23rem] h-[4.3rem] md:w-[13.73rem] md:h-[13.7rem] ' />
      </div>
      <div
        className={`font-[600] text-[43px] md:text-[61px] leading-[61px] md:leading-[61px] text-[#D6D7D6] opacity-0 transition-opacity duration-500 ${
          showText ? 'opacity-100' : ''
        }`}
      >
        Chawla Ispat
      </div>
    </div>
  );
};