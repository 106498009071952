import { Accordion } from "./Components/Accordian";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";

export const Faq = () => {
	const title = "FAQ | Building Material Supplier | Chawla Ispat";
	const description =
		"Find answers to frequently asked questions about Chawla Ispat's products, services, and policies on our FAQ page.";
	const canonicalUrl = chawlaispat;

	usePageMeta(title, description, canonicalUrl);
	return (
		<div className="flex flex-col my-5 md:my-10 px-4 md:px-8  ">
			<div className="py-[24px] px-[24px] lg:py-12 lg:px-12 flex flex-col lg:flex-row items-center lg:items-start gap-6 lg:gap-0 lg:justify-between bg-[#F2F2F2] rounded-[12px]  transition-all duration-500 ease-in-out transform ">
				<div className="text-center lg:text-left font-extrabold text-[28px] lg:text-[36px] leading-[30px] md:leading-[48px] text-gray-800">
					Looking For Help?{" "}
					<span className="block mt-2 text-[#005F6A] text-[18px] sm:text-[22px]">
						Here are our most frequently asked questions.
					</span>
				</div>
				<Accordion />
			</div>
		</div>
	);
};
// import { Accordion } from './Components/Accordian'
// import { chawlaispat } from '../../Redux/apiRoutes'
// import usePageMeta from '../../common/hooks/usePageMeta';

// export const Faq = () => {
//   const title = "FAQ | Building Material Supplier | Chawla Ispat";
//   const description = "Find answers to frequently asked questions about Chawla Ispat's products, services, and policies on our FAQ page.";
//   const canonicalUrl = chawlaispat;

//   usePageMeta(title, description, canonicalUrl);
//   return (
//     <div className='flex flex-col my-2 md:my-5 px-4 md:px-8 '>

//      <div className="py-[24px] px-[24px] md:py-8 md:px-8 flex flex-col sm:flex-row gap-6 sm:gap-0 sm:justify-between bg-[#F2F2F2] rounded-[12px]">
//           <div className="font-[700] text-[24px] md:text-[32px] leading-[35px] md:leading-[50px] ">
//             Looking For help? Here are Our most Frequently asked Questions.
//           </div>
//         </div>

//         <Accordion />

//     </div>
//   )
// }
