import React, { useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { FiMapPin } from "react-icons/fi";
import { motion } from "framer-motion";
interface FormState {
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber: string;
	subject?: string;
	message?: string;
}

interface FormErrors {
	firstName?: string;
	phoneNumber?: string;
	message?: string;
}

const ContactUs = () => {
	const [formData, setFormData] = useState<FormState>({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		subject: "",
		message: "",
	});

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [loading, setLoading] = useState(true);

	// Handle form field changes with validation
	const handleInputChange = (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));

		// Real-time validation
		setFormErrors((prevErrors) => {
			const newErrors = { ...prevErrors };

			// Validate first name
			if (name === "firstName") {
				if (!value) {
					newErrors.firstName = "First name is required.";
				} else {
					delete newErrors.firstName;
				}
			}

			// Validate phone number
			if (name === "phoneNumber") {
				if (!value) {
					newErrors.phoneNumber = "Phone number is required.";
				} else if (!/^\d{10}$/.test(value)) {
					newErrors.phoneNumber = "Phone number must be 10 digits.";
				} else {
					delete newErrors.phoneNumber;
				}
			}

			// Validate message
			if (name === "message") {
				if (!value) {
					newErrors.message = "Message is required.";
				} else {
					delete newErrors.message;
				}
			}

			return newErrors;
		});
	};

	// Validate entire form on submit
	const validateForm = (): boolean => {
		const errors: FormErrors = {};
		if (!formData.firstName) errors.firstName = "First name is required.";
		if (!formData.phoneNumber)
			errors.phoneNumber = "Phone number is required.";
		else if (!/^\d{10}$/.test(formData.phoneNumber))
			errors.phoneNumber = "Phone number must be 10 digits.";
		if (!formData.message) errors.message = "Message is required.";

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	// Handle form submission
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		console.log(formData);
		if (validateForm()) {
			const { firstName, phoneNumber, message } = formData;
			const whatsappNumber = "919258294692";
			const encodedMessage = encodeURIComponent(
				`Name: ${firstName}\nPhone Number: ${phoneNumber}\nMessage: ${message}`
			);
			const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

			window.open(whatsappURL, "_blank");

			// Reset form and errors after successful submission
			setFormData({
				firstName: "",
				lastName: "",
				email: "",
				phoneNumber: "",
				subject: "",
				message: "",
			});
			setFormErrors({});
		}

		setIsSubmitting(false);
	};

	const title = "Contact Us | Building Material Supplier | Chawla Ispat";
	const description =
		"Get in touch with Chawla Ispat for any inquiries or support. We're here to help!";
	const canonicalUrl = chawlaispat;

	usePageMeta(title, description, canonicalUrl);

	const getBorderColor = (error: any) =>
		error ? "border-red-500" : "border-[#000000]";
	const headerVariants = {
		hidden: { opacity: 0, y: -50 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	const breadcrumbVariants = {
		hidden: { opacity: 0, x: -50 },
		visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.3 } },
	};
	return (
		<>
			{/* <motion.div
				initial="hidden"
				animate="visible"
				className="bg-gradient-to-r from-[#5097A4] to-[#74c0c9] py-4"
			>
				<div className="container mx-auto px-4">
					<nav className="flex justify-between items-center">
						<motion.h2
							variants={headerVariants}
							className="text-xl font-semibold text-white"
						>
							Contact Us
						</motion.h2>
						<motion.ol
							variants={breadcrumbVariants}
							className="list-reset flex text-white"
						>
							<li>
								<a href="/" className="text-white hover:underline">
									Home
								</a>
							</li>
							<li>
								<span className="mx-2">/</span>
							</li>
							<li>Contact Us</li>
						</motion.ol>
					</nav>
				</div>
			</motion.div> */}
			<div className=" font-poppins sm:font-inter bg-white py-3">
				<div className="w-full max-w-[1536px] mx-auto sm:my-4 text-[#000000] relative">
					{/* Header Start */}
					<div className="flex flex-col items-start sm:items-center sm:gap-3 px-4 sm:px-0">
						<h1 className=" text-lg sm:text-3xl md:text-4xl font-bold sm:leading-[48.41px]">
							Contact Us
						</h1>
						<p className="text-[#646464] font-medium text-[10px] sm:text-lg text-wrap text-center mt-1 sm:mt-0 sm:px-2">
							Any question or remarks? Just write us a message!
						</p>
					</div>
					{/* Header End */}

					{/* Content Start */}
					<div className="relative px-1 sm:px-0 flex flex-col-reverse md:flex-row my-4 sm:my-6 bg-white h-auto md:h-full md:min-h-[431px] w-[95%] mx-auto gap-4">
						<div className="relative md:w-2/5 w-full shrink-0 overflow-hidden border-white  border-[3px] sm:border-[10px] rounded-md sm:rounded-2xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] h-[350px] md:h-auto">
							{loading && (
								<div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-md sm:rounded-xl">
									<FiMapPin className="text-5xl text-gray-600 animate-pulse-scale" />
								</div>
							)}
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55842.052412769466!2d79.36254392857471!3d28.983569628882858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a07fd9dbe0d7c7%3A0x3e982a8cc989e74a!2sChawla%20auto%20componant%20sidcul%20pantnagar%20rudrapur!5e0!3m2!1sen!2sin!4v1713431639918!5m2!1sen!2sin"
								width="100%"
								height="100%"
								className="border-0 rounded-md sm:rounded-2xl"
								loading="lazy"
								onLoad={() => setLoading(false)}></iframe>
						</div>
						{/* Mobile Button */}

						<form className="w-full  flex flex-col sm:flex-row   sm:items-stretch" onSubmit={handleSubmit}>
							<div className="flex w-full flex-col rounded-md sm:rounded-2xl border-white border-[3px] sm:border-[10px] shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] ">
								<div

									className="w-full h-full rounded-md sm:rounded-2xl py-4 px-3 sm:px-5 flex flex-col gap-3 text-sm bg-gradient-to-b from-[#5097A4] to-[rgb(192,235,231)]">
									<div className="flex flex-row w-full gap-3 sm:gap-5">
										<div className="w-1/2 flex flex-col">
											<label
												htmlFor="firstName"
												className="font-medium text-[10px] sm:text-sm">
												Enter Your Name
											</label>
											<input
												type="text"
												id="firstName"
												name="firstName"
												value={formData.firstName || ""}
												onChange={handleInputChange}
												className={`w-full py-0.5 px-2 sm:px-3 sm:py-2 border-[2px] border-white rounded-md shadow-inner  mt-1   focus:outline-none  text-[10px] sm:text-sm `}
											/>
											{formErrors.firstName && (
												<p className="text-red-500 text-[10px] sm:text-xs mt-1">
													{formErrors.firstName}
												</p>
											)}
										</div>

										<div className="w-1/2 flex flex-col">
											<label
												htmlFor="phoneNumber"
												className="font-medium text-[10px] sm:text-sm">
												Phone Number
											</label>
											<input
												type="text"
												id="phoneNumber"
												name="phoneNumber"
												value={formData.phoneNumber || ""}
												onChange={handleInputChange}
												className={`w-full py-0.5 px-2 sm:px-3 sm:py-2 border-[2px] border-white rounded-md shadow-inner  mt-1   focus:outline-none focus:border-b-[1.5px] text-[10px] sm:text-sm`}
											/>
											{formErrors.phoneNumber && (
												<p className="text-red-500 text-[10px] sm:text-xs mt-1">
													{formErrors.phoneNumber}
												</p>
											)}
										</div>
									</div>

									<div className="flex flex-col gap-1 sm:gap-[14px] justify-between w-full">
										<label
											htmlFor="subject"
											className="font-medium text-[10px] sm:text-sm">
											Select Subject
										</label>
										<div className="flex flex-row justify-around sm:justify-start items-center gap-2 sm:gap-4 flex-wrap">
											<div className="flex items-center sm:flex-col md:flex-row gap-1">
												<input
													type="radio"
													id="general"
													name="subject"
													value="General Enquiry"
													checked={
														formData.subject ===
														"General Enquiry"
													}
													onChange={handleInputChange}
													className=" font-medium text-[10px] sm:text-sm"
												/>
												<label
													htmlFor="general"
													className=" sm:leading-[20px] font-medium text-[9px] sm:text-sm">
													General Enquiry
												</label>
											</div>
											<div className="flex items-center sm:flex-col md:flex-row gap-1">
												<input
													type="radio"
													id="personal"
													name="subject"
													value="Personal Enquiry"
													checked={
														formData.subject ===
														"Personal Enquiry"
													}
													onChange={handleInputChange}
													className=" font-medium text-[10px] sm:text-sm"
												/>
												<label
													htmlFor="personal"
													className="font-medium text-[9px] sm:text-sm sm:leading-[20px]">
													Personal Enquiry
												</label>
											</div>

											<div className="flex items-center sm:flex-col md:flex-row gap-1">
												<input
													type="radio"
													id="company"
													name="subject"
													value="Company Enquiry"
													checked={
														formData.subject ===
														"Company Enquiry"
													}
													onChange={handleInputChange}
													className=" font-medium text-[10px] sm:text-sm flex items-center"
												/>
												<label
													htmlFor="company"
													className=" sm:leading-[20px] font-medium text-[9px] sm:text-sm">
													Company Enquiry
												</label>
											</div>
										</div>
									</div>

									<div className="w-full flex flex-col rounded-2xl sm:mt-3 shadow-sm">
										<label
											htmlFor="message"
											className="font-medium mb-1 text-[10px] sm:text-sm">
											Message
										</label>
										<textarea
											value={formData.message}
											onChange={handleInputChange}
											id="message"
											name="message"
											placeholder="Write your message...."
											rows={4}
											className={`w-full py-2 px-3  border-[2px] border-white rounded-md shadow-inner focus:outline-none focus:border-b-[1.5px]  bg-white text-[10px] sm:text-sm`}
										/>
										{formErrors.message && (
											<p className="text-red-500 text-xs mt-1">
												{formErrors.message}
											</p>
										)}
									</div>

									<div className=" md:mt-[1rem] hidden sm:flex justify-center sm:justify-end md:px-0">
										<button
											type="submit"
											className="bg-white w-full md:w-[9.8rem] shadow-lg mt-1 px-4 py-3 rounded-md font-[500] text-[14px] leading-[20px] flex justify-center items-center gap-[7px]">
											<FaTelegramPlane className="text-[#5097A4]" />
											Send Message
										</button>
									</div>
								</div>
							</div>
							<button
								type="submit"
								className="text-xs py-2 px-4 font-medium rounded-md border-2 border-white mx-auto bg-[#51489A] text-white  mt-4 shadow-md sm:hidden flex items-center justify-center gap-2"
							>
								<FaTelegramPlane className="text-white" />
								Send Message
							</button>
							{/* Form Section */}
						</form>


					</div>

				</div>
			</div>
		</>
	);
};

export default ContactUs;
