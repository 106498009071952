
interface NoDataProps {
    colCount: number,
    text: string
}
const TableNoData = ({ colCount, text }: NoDataProps) => {
    return (
        <tr>
            <td colSpan={colCount} className="text-center">
                <div className="w-full flex justify-center h-[100px] items-center text-sm font-medium text-red-500">
                    {text}
                </div>
            </td>
        </tr>
    )
}

export default TableNoData