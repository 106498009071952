import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useGetOrderDetailsMutation,
  usePostOrderHistoryMutation,
} from "../../../Redux/Api/Order";
import { useNavigate, useParams } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";

import { MdSettingsBackupRestore } from "react-icons/md";
import { exportToExcel } from "../../../utils/exportToExcel";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";

interface OrderDetails {
  _id: string;
  orderNumber: string;
  createdAt: string;
  status: string;
  contractorDetail: string;
  contractorId: string;
  itemData: ItemData[];
  createdAs: string;
  createdBy: string;
}

interface ItemData {
  _id: string;
  godown: string;
  itemCode: string;
  itemName: string;
  categoryCoins: number;
  companyProductCoins: number;
  coins?: number;
  quantity: number;
  unit: string;
  dispatchQuantity: number;
  status: { value: string; date: string; _id: string }[];
  taxableValue: number;
  margin: number;
  remark: string;
}

const ReferralOrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const [editStates, setEditStates] = useState<boolean[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [totalFinalCoins, setTotalFinalCoins] = useState<number>(0);
  const [itemeName, setItemName] = useState<string>("");

  const [coins, setCoins] = useState([]);
  const [mutation, { data: orderData, isLoading }] =
    useGetOrderDetailsMutation();

  const [historyMutation] = usePostOrderHistoryMutation();
  const { id } = useParams();

  useEffect(() => {
    mutation(id);
  }, [mutation, id]);

  useEffect(() => {
    if (orderData && orderData.order) {
      const initialEditStates = new Array(
        orderData.order[0].itemData.length
      ).fill(false);
      setEditStates(initialEditStates);
      setOrderDetails(orderData.order[0]);
      calculateTotalFinalCoins(orderData.order[0].itemData);
    }
  }, [orderData]);

  const calculateTotalFinalCoins = (items: ItemData[]) => {
    let totalCoins = 0;

    let coinsArrys: any = [];

    coinsArrys = items.map((item: any) => {
      if (item.coins === undefined) {
        return (
          item.categoryCoins *
          item.companyProductCoins *
          item.dispatchQuantity
        );
      } else {
        return item.coins;
      }
    });

    setCoins(coinsArrys);

    console.log(items, coinsArrys);

    items.forEach((item) => {
      totalCoins +=
        item.coins ||
        item.categoryCoins *
        item.companyProductCoins *
        item.dispatchQuantity;
    });
    setTotalFinalCoins(totalCoins);
  };

  const handleApprove = async () => {
    const postdata = {
      order: orderData.order[0]._id,
      contractor: orderData.order[0].contractorId,
      coinsData: coins,
    };
    await historyMutation(postdata);

    navigate("/admin/orders");
  };

  const toggleEdit = (index: number) => {
    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);
  };

  const handleEditSave = (index: number) => {
    const updatedItem = { ...orderDetails!.itemData[index] };
    const newEditStates = [...editStates];
    newEditStates[index] = false;
    setEditStates(newEditStates);
    // You can handle the save logic here, like sending updatedItem to backend API
    calculateTotalFinalCoins(orderDetails!.itemData);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedItem = { ...orderDetails!.itemData[index] };
    updatedItem.coins = +e.target.value; // Assuming input field updates `coins` directly
    const updatedItems = [...orderDetails!.itemData];
    updatedItems[index] = updatedItem;
    setOrderDetails({ ...orderDetails!, itemData: updatedItems });
    calculateTotalFinalCoins(updatedItems);
  };

  const handleRestoreOriginal = (index: number) => {
    if (!orderDetails?.itemData) {
      return; // Handle the case where itemData is undefined
    }

    const updatedItems: ItemData[] = orderDetails.itemData.map((item) => ({
      ...item,
      coins:
        item.categoryCoins *
        item.companyProductCoins *
        item.dispatchQuantity,
    }));

    setOrderDetails((prevOrderDetails) => ({
      ...prevOrderDetails!,
      itemData: updatedItems,
    }));

    calculateTotalFinalCoins(updatedItems);
  };

  const onItemNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setItemName(e.target.value.toLowerCase());
  };

  const filteredData = orderDetails?.itemData.filter((item: any) =>
    item?.itemName.toLowerCase().includes(itemeName)
  );

  const resetAll = (): void => {
    setItemName("");
  };
  const exportData = () => {
    const newFilteredData = (filteredData || []).map((item) => ({
      "Item Name": item.itemName || "-",
      Quantity: item.quantity || "-",
      Coins: item.categoryCoins * item.companyProductCoins || "-",
      "Disp Qty": item.dispatchQuantity || "-",
      "Final Coins":
        item.coins ||
        item.categoryCoins *
        item.companyProductCoins *
        item.dispatchQuantity ||
        "-",
      "Taxable Value": item.taxableValue.toFixed(2) || "-",
      Margin: item.margin.toFixed(2) || "-",
      Godown: item.godown || "-",
      Status: item.status[item.status.length - 1]?.value || "-",
      Remark: item.remark || "-",
    }));

    exportToExcel(newFilteredData, "orderRequestData");
  };

  return (
    <>
      <div
        className="p-4 bg-white rounded-xl mb-3  w-full"
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
        <ul className=" w-full font-inter font-medium space-y-1 ">
          <li className="flex justify-between  relative">
            <div className="flex ">
              <span className=" text-[16px] leading-[19px] flex text-[#8B8D97]">
                Order Number :
              </span>
              <span className="text-[#C1507F] mx-2">
                {orderDetails?.orderNumber}
              </span>
            </div>

            <div className="text-[16px] font-medium font-inter flex justify-end items-center gap-2 absolute right-0">
              <span className=" text-[#000000]">
                Total Final Coins :{" "}
              </span>
              <span className=" bg-[#FDE047] text-[14px] text-black border-none rounded-xl py-2 px-3">
                {totalFinalCoins}
              </span>
              <button
                onClick={handleApprove}
                className=" bg-[#BFDBFE] text-[14px] text-black border-none rounded-xl py-2 px-3  hover:bg-[#aaccf7]">
                Approve
              </button>
            </div>
          </li>
          <li className="flex">
            <span className=" text-[16px] leading-[19px] flex text-[#8B8D97]">
              Order Status :
            </span>
            <span className="text-[#C1507F] mx-2">
              {orderDetails?.status}
            </span>
          </li>
          <li className="flex">
            <span className=" text-[16px] leading-[19px] flex text-[#8B8D97]">
              Sales Person :
            </span>
            <span className="text-[#C1507F] mx-2">
              {orderDetails?.createdBy}
            </span>
          </li>
          <li className="flex justify-between">
            <div className="flex items-center  ">
              <span className=" text-[16px] leading-[19px] flex text-[#8B8D97]">
                Referral Contractor
              </span>
              <span
                className="text-[#4f63cb] mx-2 cursor-pointer"
                onClick={() =>
                  navigate(
                    `/admin/contractors/profile/${orderDetails?.contractorId}`
                  )
                }>
                {orderDetails?.contractorDetail}
              </span>
            </div>
            <div className="flex  text-[#8B8D97] text-[11px] items-center gap-1">
              <span className="font-medium  leading-[19px] ">
                Date :
              </span>
              <span className="">
                {orderDetails?.createdAt.split("T")[0]}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div className="w-full h-auto  mt-3 pb-3 pt-2  rounded-t-xl bg-white">
        <div className="flex justify-between py-2 px-4">
          <div className="flex items-center gap-2">
            <input
              type="text"
              onChange={onItemNameChange}
              value={itemeName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
              placeholder="Item Name..."
              style={{
                boxShadow: "0px 0px 7.6px 0px #00000040 inset",
              }}
            />
          </div>
          <div className="flex gap-2">
            <button
              onClick={resetAll}
              className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
              Reset
            </button>
            <button
              onClick={exportData}
              className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
              Excel{" "}
              <RiFileExcel2Fill className="w-[20px] h-[20px]" />
            </button>
          </div>
        </div>

        <div className="w-full overflow-x-auto pt-2">
          <table className="table-auto min-w-full divide-y divide-gray-200 relative">
            <thead className="bg-white divide-y divide-gray-200">
              <tr className="border-t">
                <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Item Name
                </th>
                <th className="px-2 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Quantity
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Coins
                </th>
                <th className="px-3 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Disp Qty
                </th>
                <th className=" py-6 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Final Coins (Coins*Dispatch)
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  taxableValue
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  margin
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Godown
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Status
                </th>
                <th className="px-5 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                  Remark
                </th>
              </tr>
            </thead>
            <tbody className="bg-white ">
              {
                isLoading ? <TableLoading colCount={10} /> : !isLoading && filteredData && filteredData.length > 0 ? filteredData?.map((item: any, index) => (
                  <tr key={item._id}>
                    <td className=" px-5 text-nowrap  font-inter text-sm text-[#6E7079]">
                      {item.itemName}
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {item.quantity}
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm font-semibold text-amber-500">
                      {item.categoryCoins *
                        item.companyProductCoins}
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {item.dispatchQuantity}
                    </td>
                    <td className="px-6 f py-4 whitespace-nowrap font-inter text-sm text-amber-500 flex items-center  justify-between ">
                      {editStates[index] ? (
                        <>
                          <input
                            type="number"
                            className=" w-1/2 bg-gray-50 border border-gray-300 text-amber-500 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block  py-1 px-3"
                            value={item.coins}
                            style={{
                              boxShadow:
                                "0px 0px 7.6px 0px #00000040 inset",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index
                              )
                            }
                          />
                        </>
                      ) : (
                        <span>
                          {item.coins ||
                            item.categoryCoins *
                            item.companyProductCoins *
                            item.dispatchQuantity}
                        </span>
                      )}

                      <span className="flex items-center ">
                        <button
                          className={`border  rounded-xl py-1 font-inter font-medium  duration-200 px-3 text-black ${editStates[index]
                            ? "bg-blue-300"
                            : "bg-yellow-300"
                            } ml-1`}
                          onClick={() =>
                            editStates[index]
                              ? handleEditSave(index)
                              : toggleEdit(index)
                          }>
                          {editStates[index]
                            ? "Save"
                            : "Edit"}
                        </button>
                        <button>
                          {editStates[index] ? (
                            ""
                          ) : (
                            <button
                              className=" rounded-xl p-2  text-black bg-yellow-300 ml-2"
                              onClick={() =>
                                handleRestoreOriginal(
                                  index
                                )
                              }>
                              <MdSettingsBackupRestore />
                            </button>
                          )}
                        </button>
                      </span>
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {item.taxableValue.toFixed(2)}
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {item.margin.toFixed(2)}
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
                      {item.godown}
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm">
                      {
                        item.status[item.status.length - 1]
                          .value
                      }
                    </td>
                    <td className="px-5 py-4 whitespace-nowrap font-inter text-sm">
                      {item.remark}
                    </td>
                  </tr>
                )) : <TableNoData colCount={10} text={"Data Not Availabe !"} />
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReferralOrderDetails;
