import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import { useForgetPasswordMutation } from '../../../Redux/Api/Signup';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { phoneNumber, otp } = location?.state || {};
    const [mutation, { data, isLoading, isSuccess, isError, error }] = useForgetPasswordMutation()
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!newPassword || !confirmPassword) {
            toast.warning("Both fields are required.");
            return;
        }

        if (newPassword !== confirmPassword) {
            toast.warning("Passwords do not match.");
            return;
        }

        if (newPassword.length < 6) {
            toast.warning('Password must be at least 6 characters long.');
            return;
        }

        mutation({ phoneNumber: Number(phoneNumber), otp, password: newPassword })
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(data?.message || "Password reset successfully");

            const currentTime = Date.now();
            if (!localStorage.getItem("otpTimerStart")) {
                localStorage.setItem("otpTimerStart", currentTime.toString());
            }
            navigate("/signup");
        }
        else if (isError) {
            toast.error(error?.data?.message || "An error occurred");
        }
    }, [isSuccess, isError]);

    return (
        <div className="relative  font-poppins  sm:font-inter   flex flex-col items-center   ] overflow-hidden px-4 py-20 h-screen justify-center " 
        style={{
            background: "linear-gradient(0.32deg, #6DACBD 0.3%, #FFFFFF 99.74%)",
        }}
        >
            <main
                id="content"
                role="main"
                className="w-full  max-w-md px-6 py-10 bg-white rounded-2xl sm:shadow-lg"
            >
                <div className="text-center">
                    <h1 className="text-[27px] sm:text-3xl font-bold text-gray-800 ">
                        Reset Password
                    </h1>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                        Remember your password?{' '}
                        <Link
                            to={"/signup"}
                            className="text-blue-600 font-medium hover:underline dark:text-blue-400"
                        >
                            Login here
                        </Link>
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="mt-6">
                    <div className="mb-4 relative">
                        <label
                            htmlFor="newPassword"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            New Password
                        </label>
                        <div className="flex relative items-center border rounded-lg shadow-sm bg-gray-50">
                            <input
                                type={showNewPassword ? 'text' : 'password'}
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="flex-1 px-4 py-3 text-sm focus:outline-none rounded-l-lg bg-transparent"
                                placeholder="Enter new password"
                            />
                            <button
                                type="button"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                className="px-4"
                            >
                                {showNewPassword ? (
                                    <FaEyeSlash className="text-gray-500" />
                                ) : (
                                    <FaEye className="text-gray-500" />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="mb-4 relative">
                        <label
                            htmlFor="confirmPassword"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Confirm Password
                        </label>
                        <div className="flex relative items-center border rounded-lg shadow-sm bg-gray-50">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="flex-1 px-4 py-3 text-sm focus:outline-none rounded-l-lg bg-transparent"
                                placeholder="Confirm new password"
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="px-4"
                            >
                                {showConfirmPassword ? (
                                    <FaEyeSlash className="text-gray-500" />
                                ) : (
                                    <FaEye className="text-gray-500" />
                                )}
                            </button>
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full inline-flex justify-center items-center rounded-lg bg-gradient-to-r from-[#5097A4] to-[#76B4C5] px-4 py-3 text-sm md:text-base font-semibold text-white shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5097A4] transition-all duration-200`}
                    >
                        {isLoading ? (
                            <span className="flex items-center justify-center gap-2">
                                <FaSpinner className="animate-spin" />
                                Updating Password...
                            </span>
                        ) : (
                            'Update Password'
                        )}
                    </button>
                </form>
            </main>
        </div>
    );
};

export default ForgotPassword;
