import { useState } from 'react';
import { useEditRewardMutation } from '../../../../../Redux/Api/Admin/Reward';
import { toast } from 'react-toastify';

export const RewardChange = ({ handleClose, id, grade }: any) => {
  const [reward, setReward] = useState<number | ''>('');
  const [editReward, { data: Rewards, isLoading, isError }] = useEditRewardMutation();

  const handleSave = async () => {
    let updatedCouponData: { [key: string]: number | boolean } = { status: true };

    if (grade === 'a') {
      updatedCouponData.rewardA = reward === '' ? 0 : reward;
    } else if (grade === 'b') {
      updatedCouponData.rewardB = reward === '' ? 0 : reward;
    } else if (grade === 'c') {
      updatedCouponData.rewardC = reward === '' ? 0 : reward;
    }

    try {
      await editReward({ id, body: updatedCouponData }).unwrap();
      toast.success("Reward updated successfully!", { position: "top-center" });
      handleClose();
    } catch (error) {
      toast.error("Error updating reward:", { position: "top-center" });
      console.error('Mutation failed', error);
    }
  };

  return (
    <div className='w-[215px] h-[142px] border flex flex-col bg-white px-[15px] py-3 gap-[16px] rounded-xl ' style={{ zIndex: 900 }}>
      <div className='w-[190px] gap-[12px]'>
        <span>
          Set Reward Points
        </span>
        <div className='h-[34px] px-[6px] py-[3px] border rounded-md'>
          <input
            type='number'
            value={reward}
            onChange={(e) => setReward(e.target.value === '' ? '' : Number(e.target.value))}
            className='border-none outline-none'
          />
        </div>
      </div>

      <div className='flex justify-between gap-2'>
        <div className='px-[10px] w-1/2 py-[4px] text-center bg-white border border-[#334155] rounded-md cursor-pointer text-[#334155] font-[500] text-[13px] leading-[22px]'
          onClick={handleClose}
        >
          CANCEL
        </div>

        <div className='px-[10px] w-1/2 py-[4px] text-center border rounded-md text-white bg-[#334155] cursor-pointer font-[500] text-[13px] leading-[22px]'
          onClick={handleSave}
        >
          SAVE
        </div>
      </div>
    </div>
  );
};
