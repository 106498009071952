import { useEffect, useState, useRef } from "react";
import {
	useCategoryRewardQuery,
	useGetCategoryRewardMutation,
} from "../../../../Redux/Api/Category";
import { CombinedState } from "../../../../types/api-types";
import { useSearchFiltersMutation } from "../../../../Redux/Api/ProductAPI";
import file1 from "../../../../assets/file.png";

interface Reward {
	rewardA: number;
	rewardB: number;
	rewardC: number;
}

interface RewardComponentProps {
	id: string | null;
	name: string | null;
}

const RewardComponent = ({ id, name }: RewardComponentProps) => {

	const [combinedState, setCombinedState] = useState<CombinedState>({
		search: "",
		company: [],
		category: [],
		limit: 12,
		page: 1,
	});
	const [reward, setReward] = useState<Reward>({
		rewardA: 0,
		rewardB: 0,
		rewardC: 0,
	});

	const [searchFilters, { data: SearchProductsResponse }] =
		useSearchFiltersMutation();
	const { data: categoryResponse, refetch } = useCategoryRewardQuery(id, { skip: !id });

	const [changeReward, { data: gradeResponse }] =
		useGetCategoryRewardMutation();

	const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		if (categoryResponse) {
			setReward({
				rewardA: categoryResponse.data.rewardA,
				rewardB: categoryResponse.data.rewardB,
				rewardC: categoryResponse.data.rewardC,
			});
		}
	}, [categoryResponse]);

	useEffect(() => {
		if (gradeResponse) {
			setReward({
				rewardA: gradeResponse.data.rewardA,
				rewardB: gradeResponse.data.rewardB,
				rewardC: gradeResponse.data.rewardC,
			});
			refetch();
		}
	}, [gradeResponse, refetch]);

	const handleRewardChange = (type: keyof Reward, value: string) => {
		const updatedReward = { ...reward, [type]: parseFloat(value) };
		setReward(updatedReward);

		if (debounceTimer.current) {
			clearTimeout(debounceTimer.current);
		}

		debounceTimer.current = setTimeout(() => {
			if (id) {
				changeReward({ id, body: updatedReward }).then(() => refetch());
			}
		}, 1000);
	};

	useEffect(() => { }, [SearchProductsResponse]);
	useEffect(() => {
		searchFilters({ combinedState });
	}, [combinedState, searchFilters]);

	return (
		<>
			<div
				className="mx-3 px-4 py-5 rounded-xl bg-white flex justify-between items-start"
				style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
				<div className=" flex flex-col justify-center items-start ml-3">
					<div className="bg-[#E2E8F0] p-1 rounded-lg mb-1">
						<img src={file1} alt="file" />
					</div>
					<h1 className="font-inter text-[13px] text-[#6B6B6B]">
						All Products
					</h1>
					<p className="font-inter text-[35px] font-medium text-[#6B6B6B]">
						{SearchProductsResponse?.total}
					</p>
				</div>
				<div
					style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
					className="p-8 w-[70%] text-white bg-[#334155] mx-auto bg-gradient-to-r  rounded-xl shadow-xl space-y-8 transition duration-500 border-4 border-[#233144]">
					<h2 className=" font-bold text-2xl text-center mb-6">
						Rewards for {name}
					</h2>
					<div className=" font-semibold space-y-6">
						<div className="flex items-center gap-8">
							<label className="w-1/4 text-xl">Reward A</label>
							<input
								className="w-3/5 h-2  rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
								type="range"
								min="0"
								max="10.0"
								step="0.1"
								value={reward.rewardA}
								onChange={(e) =>
									handleRewardChange(
										"rewardA",
										e.target.value
									)
								}
							/>
							<p className="w-16 text-center bg-white text-black p-1 rounded-md shadow-md">
								{reward.rewardA}
							</p>
						</div>
						<div className="flex items-center gap-8">
							<label className="w-1/4 text-xl">Reward B</label>
							<input
								className="w-3/5 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
								type="range"
								min="0"
								max="10.0"
								step="0.1"
								value={reward.rewardB}
								onChange={(e) =>
									handleRewardChange(
										"rewardB",
										e.target.value
									)
								}
							/>
							<p className="w-16 text-center bg-white text-black p-1 rounded-md shadow-md">
								{reward.rewardB}
							</p>
						</div>
						<div className="flex items-center gap-8">
							<label className="w-1/4 text-xl">Reward C</label>
							<input
								className="w-3/5 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
								type="range"
								min="0"
								max="10.0"
								step="0.1"
								value={reward.rewardC}
								onChange={(e) =>
									handleRewardChange(
										"rewardC",
										e.target.value
									)
								}
							/>
							<p className="w-16 text-center bg-white text-black p-1 rounded-md shadow-md">
								{reward.rewardC}
							</p>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default RewardComponent;



